import React from "react";

import "./ReportItem.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { ReportStatus, ReportCauses } from "../../constants";
import { convertDateTime, dateTimeFormat } from "../../utils/dateFormatUtils";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";
import imageupload from "../../assets/images/imageupload.png";

const ReportUser = (props) => {
    const { data } = props;

    return (
        <>
            <div className="report__wrapper align-items-center d-flex">
                <div className="report__data">
                    <p>
                        {convertDateTime({
                            date: data?.Date,
                            customFormat: dateTimeFormat.appDateFormat
                        })}
                    </p>
                </div>
                <div className="report__user align-items-center justify-content-start d-flex pl-5">
                    <img src={data?.ReportedUser?.MediaUrl || imageupload} />
                    <p className="pl-3 text-truncate">{data?.ReportedUser?.Name || "-"}</p>
                </div>
                <div className="report__cause text-center">
                    <p className="text-truncate">{ReportCauses.getDisplayTextKey(data?.Cause)}</p>
                </div>
                <div className="report__user align-items-center justify-content-start d-flex pl-5">
                    <img src={data?.ReportedBy?.MediaUrl || imageupload} />
                    <p className="pl-3 text-truncate">{data?.ReportedBy?.Name || "-"}</p>
                </div>
                <div className="report__status d-flex align-items-center justify-content-center">
                    <FontAwesomeIcon
                        className="status-icon new-report"
                        icon={faCircle}
                        style={{ color: ReportStatus.getLabelClass(data?.Status) }}
                    />
                    <p className="pl-3">{ReportStatus.getDisplayTextKey(data?.Status)}</p>
                </div>
                <div className="report__action d-flex justify-content-end">
                    <Link
                        to={getRoute(APP_ROUTES.REPORT_DETAIL, { id: data?.ReportId })}
                        className="btn btn-primary"
                    >
                        See More
                    </Link>
                </div>
            </div>
        </>
    );
};
export default ReportUser;
