import React, { Component } from 'react';
const UserIcon = (props) => {
    return (
        <svg className="icon-change" width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.9577 27.125V24.5417C21.9577 23.1714 21.4133 21.8572 20.4444 20.8883C19.4755 19.9193 18.1613 19.375 16.791 19.375H6.45768C5.0874 19.375 3.77324 19.9193 2.8043 20.8883C1.83536 21.8572 1.29102 23.1714 1.29102 24.5417V27.125" stroke="#E45858" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M11.6257 14.2083C14.4791 14.2083 16.7923 11.8951 16.7923 9.04167C16.7923 6.1882 14.4791 3.875 11.6257 3.875C8.77218 3.875 6.45898 6.1882 6.45898 9.04167C6.45898 11.8951 8.77218 14.2083 11.6257 14.2083Z" stroke="#E45858" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M29.709 27.1251V24.5417C29.7081 23.397 29.3271 22.2849 28.6257 21.3801C27.9244 20.4754 26.9424 19.8292 25.834 19.543" stroke="#E45858" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M20.666 4.04297C21.7774 4.32752 22.7624 4.97387 23.4659 5.88012C24.1693 6.78637 24.5511 7.90096 24.5511 9.04818C24.5511 10.1954 24.1693 11.31 23.4659 12.2162C22.7624 13.1225 21.7774 13.7688 20.666 14.0534" stroke="#E45858" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>


    );
}
export default UserIcon