import React, { useState, useEffect, useRef } from "react";
import ReactCodeInput from "react-verification-code-input";
import { Modal } from "react-responsive-modal";
import { useDispatch } from "react-redux";

import "react-responsive-modal/styles.css";
import "./ChangeAccount.scss";
import { Button } from "../../../components/Button";
import done from "../../../assets/images/done.png";
import shield from "../../../assets/images/shield.png";
import ShieldApproval from "../../../assets/images/ShieldApproval.png";
import Shielderror from "../../../assets/images/Shielderror.png";
import { AccountSettingChangeTypes } from "../../../constants";
import {
    changePasswordRequest,
    changePhoneNumberRequest,
    checkPasswordVerificationCode,
    checkPhoneVerificationCode
} from "../../../store/actions";
import { Spinner } from "../../../components/Loader";
import { toaster } from "../../../utils/toasterUtil";

import ShieldApprovalSvg from "../../../components/SVGICON/sheild-approval-icon"
import ShieldSvg from "../../../components/SVGICON/sheild-icon"

const ChangeAccount = (props) => {
    const { type, showChangeConfirmation, setShowChangeConfirmation, onCancel, onSuccess } = props;
    const [verificationSend, setVerificationSend] = useState(false);
    const [verificationCode, setVerificationCode] = useState(false);
    const [successful, setSuccessful] = useState(false);
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const verificationToken = useRef();
    const dispatch = useDispatch();

    const changeSettingRequest = (showVerification = true) => {
        const requestAction =
            type === AccountSettingChangeTypes.PASSWORD
                ? changePasswordRequest
                : changePhoneNumberRequest;
        setIsLoading(true);
        dispatch(requestAction())
            .then((res) => {
                verificationToken.current = res.TokenUUID;
                if (showVerification) {
                    openVerification();
                } else {
                    toaster({ title: "Verification code was resent successfully" });
                }
            })
            .catch((err) => {})
            .finally(() => {
                setIsLoading(false);
            });
    };

    const openVerification = () => {
        setShowChangeConfirmation(false);
        setVerificationSend(true);
    };
    const openVerificationCode = () => {
        setVerificationSend(false);
        setVerificationCode(true);
    };
    const closeVerification = () => {
        setVerificationSend(false);
        handleClose();
    };
    const closeVerificationCode = () => {
        setVerificationCode(false);
        handleClose();
    };

    const closeChangeConfirmation = () => {
        setShowChangeConfirmation(false);
        handleClose();
    };

    const handleClose = () => {
        onCancel();
        setTimeout(() => {
            setSuccessful(false);
            setError(false);
        }, 200);
    };

    const handleVerificationCodeSubmit = (code) => {
        const requestAction =
            type === AccountSettingChangeTypes.PASSWORD
                ? checkPasswordVerificationCode
                : checkPhoneVerificationCode;
        setIsLoading(true);

        const bodyData = {
            Code: code,
            TokenUUID: verificationToken.current
        };
        dispatch(requestAction(bodyData))
            .then((res) => {
                setSuccessful(true);
            })
            .catch((err) => {
                setError(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <>
            <Modal
                classNames={{ modal: "ChangeCredientals" }}
                open={showChangeConfirmation}
                onClose={closeChangeConfirmation}
                showCloseIcon={false}
                center
            >
                <div className="credientals-modal">
                    <p>
                        {`If you want to change your ${
                            type === AccountSettingChangeTypes.PASSWORD
                                ? "password"
                                : "phone number"
                        }, we will send you a verification code by phone.`}
                    </p>
                    <div className="d-flex justify-content-center">
                        <Button
                            onClick={changeSettingRequest}
                            title="Change"
                            isLoading={isLoading}
                        />
                        <Button className="ml-3" onClick={closeChangeConfirmation} title="Cancel" />
                    </div>
                </div>
            </Modal>
            <Modal
                classNames={{ modal: "verificationSend" }}
                open={verificationSend}
                onClose={closeVerification}
                showCloseIcon={false}
                center
            >
                <div className="verification-send d-flex flex-column h-100 justify-content-center align-items-center">
                    <img src={done} alt="Done" />
                    <p class="py-3">The Verification code has been sent </p>
                    <Button onClick={openVerificationCode} title="OK" />
                </div>
            </Modal>
            <Modal
                classNames={{ modal: "verificationCode" }}
                open={verificationCode}
                onClose={closeVerificationCode}
                showCloseIcon={true}
                center
            >
                {successful ? (
                    <div className="verification-code d-flex flex-column align-items-center justify-content-center">
                        {/* <img src={ShieldApproval} alt="shield" /> */}
                        <ShieldApprovalSvg />
                        <p class="py-5 my-2">Verification successful!</p>
                        <Button
                            onClick={() => {
                                setVerificationCode(false);
                                setError(false);
                                onSuccess();
                                setTimeout(() => {
                                    setSuccessful(false);
                                }, 200);
                            }}
                            title="Continue"
                        />
                    </div>
                ) : (
                    <div className="verification-code d-flex flex-column align-items-center justify-content-center">
                        {error ? (
                            <img src={Shielderror} alt="shield" />
                        ) : (
                            /* <img src={shield} alt="shield" /> */
                            <ShieldSvg />
                        )}
                        <p class="py-5 my-2">Enter your Verification Code</p>
                        <ReactCodeInput
                            className={error ? "error-input" : ""}
                            fields={4}
                            onComplete={handleVerificationCodeSubmit}
                        />
                        {!isLoading ? (
                            <a className="resend-link" onClick={() => changeSettingRequest(false)}>
                                Resend verification code
                            </a>
                        ) : (
                            <Spinner size="sm" classes="mt-4" />
                        )}
                    </div>
                )}
            </Modal>
        </>
    );
};
export default ChangeAccount;
