import { injectBaseConstantMethods } from "./BaseConstants";

const MaritialStatus = {
    SINGLE: 1,
    SEPERATED: 2,
    DIVORCED: 3,
    WIDOWED: 4
};

const displayTextKeys = {
    [MaritialStatus.SINGLE]: "Single",
    [MaritialStatus.SEPERATED]: "Seperated",
    [MaritialStatus.DIVORCED]: "Divorced",
    [MaritialStatus.WIDOWED]: "Widowed"
};

const labelClass = {
    [MaritialStatus.SINGLE]: "",
    [MaritialStatus.SEPERATED]: "",
    [MaritialStatus.DIVORCED]: "",
    [MaritialStatus.WIDOWED]: ""
};

export default injectBaseConstantMethods(MaritialStatus, displayTextKeys, labelClass);
