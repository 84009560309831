import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, NavLink } from "react-router-dom";
import "./NavBlock.scss";
import ChatIcon from "../SVGICON/chat-icon";

const NavBlock = (props) => {
    return (
        <NavLink
            {...props}
            activeClassName="active-navlink"
            to={{ pathname: props.Link ? props.Link : null }}
            onClick={props.onClick}
            className={props.className}
        >
            <div className="nav-block">
                <div>
                    <FontAwesomeIcon icon={props.icon} className={`nav-icon ${props.tourclass}`} />
                    {props.children}
                </div>
                <div className="overflow-hidden position-relative">
                    <p className="nav-link m-0">{props.name}</p>
                </div>
            </div>
            <div className="masking"></div>
        </NavLink>
    );
};

export default NavBlock;
