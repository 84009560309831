
import React, { Component } from 'react';
const ReferalIcon = (props) => {
    return (
        <svg className={props.className} width="39" height="40" viewBox="0 0 39 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.5 31.9551C9.5 31.9551 15.0417 29.5801 16.625 27.9967C18.2083 26.4134 13.4583 26.4134 13.4583 18.4967C13.4583 10.5801 19.7917 10.5801 19.7917 10.5801C19.7917 10.5801 26.125 10.5801 26.125 18.4967C26.125 26.4134 21.375 26.4134 22.9583 27.9967C24.5417 29.5801 30.0833 31.9551 30.0833 31.9551" stroke="#E45858" stroke-width="2" stroke-linecap="round" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.7694 36.3728C28.9392 36.3728 36.3728 28.9392 36.3728 19.7694C36.3728 10.5996 28.9392 3.16602 19.7694 3.16602C10.5996 3.16602 3.16602 10.5996 3.16602 19.7694C3.16602 28.9392 10.5996 36.3728 19.7694 36.3728Z" stroke="#E45858" stroke-width="2" />
            <path d="M31.5 26V39" stroke="#6246EA" stroke-width="2" stroke-linecap="round" />
            <path d="M38 32.5L25 32.5" stroke="#6246EA" stroke-width="2" stroke-linecap="round" />
        </svg>
    );
}
export default ReferalIcon