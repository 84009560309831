import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

import error from "../../assets/images/error-img.png";

class Pages500 extends Component {
    render() {
        const {
            reason = "Internal Server Error",
            statusFirstLetter = "5",
            statusLastLetter = "0",
            showReloadBtn = true
        } = this.props;
        return (
            <div className="account-pages my-5 pt-5">
                <Container>
                    <Row>
                        <Col lg="12">
                            <div className="text-center mb-5">
                                <h1 className="display-2 font-weight-medium">
                                    {statusFirstLetter}
                                    {statusLastLetter}
                                    {statusFirstLetter}
                                </h1>
                                <h4 className="text-uppercase">{reason}</h4>
                                {showReloadBtn && (
                                    <div className="mt-5 text-center">
                                        <button
                                            className="btn btn-primary waves-effect waves-light"
                                            onClick={() => window.location.reload()}
                                        >
                                            Reload
                                        </button>
                                    </div>
                                )}
                                <div className="mt-5">
                                    <Link to="/dashboard">Go Back To Dashboard</Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col md="8" xl="6">
                            <div>
                                <img src={error} alt="" className="img-fluid" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default Pages500;
