import React, { useEffect, useState } from "react";

import "./UserSummary.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import matches from "../../assets/images/matches.png";
import referal from "../../assets/images/referal.png";
import chats from "../../assets/images/chats.png";
import { Link } from "react-router-dom";
import MatchCard from "../MatchCard/MatchCard";
import { getMediaThumbPath } from "../../utils/mediaUtils";
import { getRoute, APP_ROUTES } from "../../helpers/routeHelpers";
import imageUpload from "../../assets/images/imageupload.png";
import { convertInches } from "../../utils/commonUtil";
import $ from "jquery";
import MatchIcon from "../SVGICON/match-icon";
import ReferalIcon from "../SVGICON/referal-icon";
import CurrentChat from "../SVGICON/current-chat";

const MAX_VIEW_LENGTH = 6;
const width = window.innerWidth;


const UserSummary = (props) => {
    const { currentUser, handleClose = () => { } } = props;

    const [mobileview, setMobileview] = useState(width < 1100);


    const renderMatches = () => {
        let data = [...currentUser?.Matches];

        const remaining = MAX_VIEW_LENGTH - data.length;

        for (let i = 0; i < remaining; i++) {
            data.push({});
        }
        return data.map((item, index) => {
            return <MatchCard data={item} key={index} userId={currentUser?.User?.UserId} />;
        });
    };

    useEffect(() => {
        console.log("Running")
        // $(window).scroll(function () {
        //     if ($(this).scrollTop() > 450) {
        //         $('#content').addClass("content_fixed");
        //     }
        //     else {
        //         $('#content').removeClass("content_fixed");
        //     }
        // });

    }, []);
    useEffect(() => {
        $(window).resize(() => {
            const width = window.innerWidth;
            const mobileview = width < 1100;
            setMobileview(mobileview);
        });
        $(".sidebar").css({ display: "block" });

    }, []);



    return (
        <div id={mobileview ? "" : "content"} className="user-summary__wrapper fancy-scroll ">
            <div className="d-flex justify-content-end mr-3 mt-2">
                <FontAwesomeIcon className="exit-icon" onClick={handleClose} icon={faTimes} />
            </div>
            <div className="d-flex flex-column flex-md-row justify-content-center align-items-center user-summary__info">
                <div className="d-flex">
                    <Link to={getRoute(APP_ROUTES.USER_DETAIL, { id: currentUser?.User?.UserId })}>
                        <img
                            src={getMediaThumbPath(currentUser?.User?.Media) || imageUpload}
                            alt="profile"
                            className={`profile-avatar`}
                        />
                    </Link>
                    <div className="pl-4">
                        <h3>{currentUser?.User?.FullName || "-"}</h3>
                        <div className="d-flex">
                            <p>{currentUser?.User?.Age || "-"} , </p>
                            <p>{currentUser?.User?.Country || "-"}</p>
                        </div>
                        <p className="py-1">{currentUser?.User?.Nationality || "-"}</p>
                        <p>{convertInches(currentUser?.User?.Height)}</p>
                    </div>
                </div>
                {/* <Link className="btn btn-primary mt-3 mt-md-0">Message</Link> */}
            </div>
            <div className="user-summary__detail d-flex justify-content-around pt-3 pt-md-5 pb-2">
                <div className="d-flex flex-column align-items-center">
                    <MatchIcon className="user-detail-icon" />
                    <p>{currentUser?.TotalMatches} Current Matches </p>
                </div>
                <div className="d-flex flex-column align-items-center">
                    <ReferalIcon className="user-detail-icon" />
                    <p>{currentUser?.ReferralsNeeded} Referrals Needed </p>
                </div>
                <div className="d-flex flex-column align-items-center">
                    <CurrentChat className="user-detail-icon active-chat-icon" />
                    <p>{currentUser?.TotalConnections} Active Chats</p>
                </div>
            </div>
            <div className="user-summary__match ">
                <h4 className="user-summary__title pb-3">Current Matches</h4>
                <div className="row">{renderMatches()}</div>
            </div>
            <div className="user-summary__chats">
                <h4 className="user-summary__title pb-3">Current Chats</h4>
                <div className="row">
                    {currentUser?.Connections && Boolean(currentUser?.Connections.length) ? (
                        currentUser?.Connections.map((obj, index) => (
                            <MatchCard data={obj} key={index} userId={currentUser?.User?.UserId} />
                        ))
                    ) : (
                            <div className="pl-3">
                                <h6>No Current Chats</h6>
                            </div>
                        )}
                </div>
            </div>
        </div>
    );
};
export default UserSummary;
