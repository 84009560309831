import { fetchUtil } from "../../utils/fetchUtil";
import { appendQueryParams } from "../../utils/urlUtil";
import Config from "../../Config";
import { getUserDetailsList } from "./userActions";
import { toaster } from "../../utils/toasterUtil";

export const getQuickMatches = (params = {}) => (dispatch, getState) => {
    const token = getState().auth.user.Token;

    return fetchUtil({
        url: appendQueryParams("/match/auto-match", { Limit: Config.LIMIT, ...params }),
        token
    })
        .then((res) => {
            if (!Boolean(res.Data.Matches.length)) {
                toaster({ title: "No Matches Found", type: "danger" });
                return Promise.reject({ Message: "No Matches Found" });
            }

            if (res && res.Data && !!res.Data.Matches.length) {
                return dispatch(getUserDetailsList(res.Data));
            }
            return Promise.reject(false);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const getManualMatches = (payload) => (dispatch, getState) => {
    const token = getState().auth.user.Token;

    return fetchUtil({
        url: "/match/matches",
        method: "POST",
        body: JSON.stringify({
            Pagination: { Page: payload.Page, Limit: Config.LIMIT },
            ...payload
        }),
        token
    })
        .then((res) => {
            if (!Boolean(res.Data.Matches.length)) {
                toaster({ title: "No Matches Found", type: "danger" });
                return Promise.reject({ Message: "No Matches Found" });
            }

            if (res && res.Data && !!res.Data.Matches) {
                return dispatch(getUserDetailsList(res.Data));
            }
            return Promise.reject(false);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const sendReferral = (payload) => (dispatch, getState) => {
    const token = getState().auth.user.Token;

    return fetchUtil({
        body: JSON.stringify(payload),
        method: "POST",
        url: "/match/send-referral",
        token
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};
