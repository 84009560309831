import React, { useState } from "react";
import moment from "moment";
import Viewer from "react-viewer";

import "./Receiver.scss";
import men1 from "../../assets/images/men1.png";
import { ChatMessageTypes } from "../../constants";

const Receiver = (props) => {
    const { type, userImage = men1, data, fromReport = false } = props;
    const [imagePreview, setImagePreview] = useState(null);
    return (
        <>
            <div className="d-flex align-items-end receiver-chat">
                <img src={userImage} alt="receiver" />
                {type === ChatMessageTypes.USER_MESSAGE ? (
                    <div className="receiver-chat__content">
                        <h2>{props.name}</h2>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: props.message.replace(/[↵\n]/g, "<br/>")
                            }}
                        ></p>
                        <p className="caption-date">{moment(data.SendDate).format("LT")}</p>
                    </div>
                ) : (
                    <div className={`receiver-chat__image ${fromReport && "chat-img-container"}`}>
                        <figure>
                            <img
                                onClick={() => setImagePreview(data?.Attachments[0]?.Path)}
                                src={props.picture}
                                className={`${fromReport && "report-img"}`}
                            />
                            <figcaption className="caption">
                                {props.caption ? props.caption : null}
                                <p className="caption-date">
                                    {moment(data?.SendDate).format("LT")}
                                </p>
                            </figcaption>
                        </figure>
                    </div>
                )}
            </div>
            {Boolean(imagePreview) && (
                <Viewer
                    visible={Boolean(imagePreview)}
                    onClose={() => setImagePreview(null)}
                    images={[{ src: imagePreview, alt: "uploaded-attachment" }]}
                    rotatable={false}
                    changeable={false}
                    drag={false}
                    noToolbar
                    noFooter
                    noNavbar
                    zIndex={99999}
                />
            )}
        </>
    );
};
export default Receiver;
