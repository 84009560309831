import { getRoute, APP_ROUTES } from "../../helpers/routeHelpers";
import { Faq, UserAccount, Notification, AboutUs, ContactUs, TermsCondition } from "../";
import { SettingRouteTypes } from "../../constants";

const routes = [
    {
        path: getRoute(APP_ROUTES.SETTINGS, { type: SettingRouteTypes.USER_ACCOUNT }),
        name: "UserAccount",
        exact: true,
        component: UserAccount
    },
    {
        path: getRoute(APP_ROUTES.SETTINGS, { type: SettingRouteTypes.FAQ }),
        name: "faq",
        exact: true,
        component: Faq
    },
    {
        path: getRoute(APP_ROUTES.SETTINGS, { type: SettingRouteTypes.ABOUT_US }),
        name: "about-us",
        exact: true,
        component: AboutUs
    },
    {
        path: getRoute(APP_ROUTES.SETTINGS, { type: SettingRouteTypes.CONTACT_US }),
        name: "contact-us",
        exact: true,
        component: ContactUs
    },
    {
        path: getRoute(APP_ROUTES.SETTINGS, { type: SettingRouteTypes.TERMS_CONDITION }),
        name: "terms-and-conditiion",
        exact: true,
        component: TermsCondition
    },
    {
        path: getRoute(APP_ROUTES.SETTINGS, { type: SettingRouteTypes.NOTIFICATION }),
        name: "notification",
        exact: true,
        component: Notification
    }
];

export default routes;
