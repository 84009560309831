import React from "react";
import { connect } from "react-redux";

import "./ChatList.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faCircle } from "@fortawesome/free-solid-svg-icons";
import { getNearestTimeStampStr } from "../../utils/dateTimeUtil";
import { getUserImagesPayload, getUserNames } from "../../utils/commonUtil";
import { ChatMessageTypes } from "../../constants";

const ChatListItem = (props) => {
    const { currentChat, onClick = undefined, chatId } = props;

    const getLastMsgTime = () => {
        return currentChat.LastMessageDate
            ? getNearestTimeStampStr(currentChat.LastMessageDate)
            : getNearestTimeStampStr(currentChat.CreatedAt);
    };

    const getLastMessage = () => {
        if (currentChat?.LastChatMessage?.Type === ChatMessageTypes.USER_MESSAGE) {
            return currentChat?.LastChatMessage?.Content || "...";
        }
        return currentChat?.LastChatMessage?.Attachments[0]?.Meta?.Name || "...";
    };

    return (
        <>
            <div
                onClick={() => onClick(chatId)}
                className="chat-list__wrapper d-flex align-items-center"
            >
                <div className="d-flex justify-content-between align-items-center w-100">
                    <div className="d-flex align-items-center">
                        <div className="d-flex chat-list__images">
                            {getUserImagesPayload(currentChat.Users)?.map((user, index) => {
                                return (
                                    <div key={index}>
                                        <img
                                            className="w-100 h-100"
                                            style={{
                                                borderRadius: 40
                                            }}
                                            src={user?.Media?.Path}
                                            key={index}
                                        />
                                    </div>
                                );
                            })}
                        </div>

                        <div className="chat-list__content pl-4">
                            <div className="d-flex chat-list__content-name">
                                <h3>{getUserNames(currentChat.Users)}</h3>
                            </div>
                            <p className="chat-list__content-description">{getLastMessage()}</p>
                        </div>
                    </div>
                    <div className="chat-list__action d-flex align-items-center">
                        {!currentChat.LastMessageSeen ? (
                            <FontAwesomeIcon className="icon-1" icon={faCircle} />
                        ) : null}
                        <p className="mb-0 mx-2">{getLastMsgTime()}</p>
                        <FontAwesomeIcon className="icon-2" icon={faAngleRight} />
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state, ownProps) => {
    const currentChat = state.entities.chats[ownProps.chatId];
    return {
        currentChat
    };
};

export default connect(mapStateToProps)(ChatListItem);
