import React, { useEffect, useRef } from "react";
import { useDispatch, connect } from "react-redux";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { faUser, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DebounceInput } from "react-debounce-input";

import SearchBox from "../../components/SearchBox/SearchBox";
import ChatList from "../../components/ChatList/ChatListItem";
import { Spinner } from "../../components/Loader";
import ChatInfo from "../../components/ChatInfo/ChatInfo";
import {
    getChatListing,
    updateChatListData,
    getChatUnReadCount,
    updateChatMessageData,
    moveChatToTop,
    addMessage
} from "../../store/actions";
import { ChatListType, ChatMessageTypes, ChatTabs, ChatType } from "../../constants";
import { getRandomNumber } from "../../utils/commonUtil";
import { Socket, SocketTypes } from "../../utils/socketUtils";
import ChatSingle from "../../components/SVGICON/chat-single";
import ChatGroup from "../../components/SVGICON/chat-group";

const ChatUserList = (props) => {
    const { mobileview, detailHandler, chatList, userChat } = props;
    const params = useParams();
    const location = useLocation();
    const history = useHistory();
    const chatListAbortController = useRef(null);
    const scrollDiv = useRef(null);
    const dispatch = useDispatch();
    const currentTab = params?.tabname;
    const id = params?.id;

    const isPreviousChatRoute = () => {
        return location.pathname.includes("previous-chats");
    };

    const checkScrollPositionAndLoadMoreData = () => {
        let container = scrollDiv.current;
        if (container) {
            const isScrolable = container.scrollHeight > container.clientHeight;
            if (!isScrolable) {
                handleScroll(null, true);
            }
        }
    };

    const getUserChats = (page, searchText) => {
        dispatch(
            getChatListing(
                {
                    Page: page,
                    ChatType: currentTab === "group" ? ChatType.GROUP : ChatType.INDIVIDUAL,
                    ChatListType: isPreviousChatRoute()
                        ? ChatListType.ENDED
                        : ChatListType.CONNECTED,
                    ...(searchText && { SearchText: searchText })
                },
                currentTab
            )
        );
    };

    useEffect(() => {
        function handleGuardianAddedOrRemoved(data, type) {
            if (data.ChatId === parseInt(id)) {
                let addMsgData = {
                    ChatId: data.ChatId,
                    Id: getRandomNumber(),
                    Type: type,
                    SenderId: data.UserId
                };
                dispatch(addMessage(addMsgData));
            }
        }

        function handleGuardianAdded(data) {
            handleGuardianAddedOrRemoved(data, ChatMessageTypes.GUARDIAN_ADDED);
        }

        function handleGuardianRemoved(data) {
            handleGuardianAddedOrRemoved(data, ChatMessageTypes.GUARDIAN_REMOVED);
        }

        async function handleMessageReceived(data) {
            const selectedChatId = parseInt(id);

            if (data && data.ChatId && data.ChatId === selectedChatId && data.ChatEvent) {
                dispatch(addMessage(data.ChatEvent));
                Socket.readMessage(data.ChatEvent.ChatId);
            } else {
                dispatch(getChatUnReadCount());
            }

            await dispatch(
                updateChatMessageData(data.ChatId, {
                    ...data.ChatEvent,
                    LastMessageSeen: data.ChatId === selectedChatId
                })
            );
            dispatch(
                moveChatToTop(
                    data.ChatId,
                    data.ChatType === ChatType.INDIVIDUAL ? ChatTabs.SINGLE : ChatTabs.GROUP
                )
            );
        }

        Socket.onMessageRecieved(handleMessageReceived);
        Socket.onGuardianAdded(handleGuardianAdded);
        Socket.onGuardianRemoved(handleGuardianRemoved);

        return () => {
            Socket.remove(SocketTypes.MESSAGE, handleMessageReceived);
            Socket.remove(SocketTypes.GUARDIAN_ADDED, handleGuardianAdded);
            Socket.remove(SocketTypes.GUARDIAN_REMOVED, handleGuardianRemoved);
        };
    }, [id, dispatch]);

    useEffect(() => {
        chatListAbortController.current = new AbortController();
        if (!chatList.initialFetch && !chatList.fetching) {
            dispatch(
                getChatListing(
                    {
                        Page: chatList.page,
                        ChatType:
                            currentTab === ChatTabs.GROUP ? ChatType.GROUP : ChatType.INDIVIDUAL,
                        ChatListType: isPreviousChatRoute()
                            ? ChatListType.ENDED
                            : ChatListType.CONNECTED
                    },
                    currentTab,
                    chatListAbortController.current.signal
                )
            )
                .then((res) => {
                    if (!!res.length) {
                        let firstChatId = res[0];
                        if (!id) {
                            history.replace(
                                `/dashboard/${
                                    isPreviousChatRoute() ? "previous-chats" : "chat"
                                }/${currentTab}/${firstChatId}`
                            );
                        }
                        checkScrollPositionAndLoadMoreData();
                    }
                })
                .catch((err) => {});
        } else {
            const { data } = chatList;
            if (!!data.length && !id) {
                history.replace(
                    `/dashboard/${
                        isPreviousChatRoute() ? "previous-chats" : "chat"
                    }/${currentTab}/${data[0]}`
                );
            }
        }
        return () => {
            if (chatListAbortController.current) {
                chatListAbortController.current.abort();
                chatListAbortController.current = null;
            }
        };
    }, [currentTab, dispatch, location.pathname]);

    const handleScroll = (e, noScroll = false) => {
        const bottom =
            noScroll ||
            (e && e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 50);

        if (bottom && chatList.dataAvailable && !chatList.fetching) {
            let page = chatList.page + 1;
            getUserChats(page);
            dispatch(updateChatListData("page", page, currentTab));
        }
    };

    const isGroupChatActive = () => {
        return currentTab === ChatTabs.GROUP;
    };

    const handleChange = (evt) => {
        let page = 1;
        getUserChats(page, evt.target.value);
        dispatch(updateChatListData("page", page, currentTab));
        dispatch(updateChatListData("searchText", evt.target.value, currentTab));
    };

    const handleChatItemClick = (chatId) => {
        mobileview && detailHandler();
        history.push(
            `/dashboard/${
                isPreviousChatRoute() ? "previous-chats" : "chat"
            }/${currentTab}/${chatId}`
        );
    };

    return (
        <div className="active-chat__info">
            <div className="active-chat__header right-border d-flex align-items-center pl-5">
                <DebounceInput
                    element={SearchBox}
                    debounceTimeout={500}
                    placeholder="Search"
                    value={chatList.searchText}
                    onChange={handleChange}
                />
            </div>
            <div
                ref={scrollDiv}
                onScroll={handleScroll}
                className={
                    isPreviousChatRoute()
                        ? "previous-chat__body fancy-scroll right-border"
                        : "active-chat__body fancy-scroll right-border"
                }
            >
                {!!chatList.data.length &&
                    chatList.data.map((chatId, index) => (
                        <ChatList
                            key={index}
                            group={isGroupChatActive()}
                            onClick={handleChatItemClick}
                            chatId={chatId}
                        />
                    ))}
                {!chatList.data.length && !chatList.fetching && (
                    <div className="d-flex justify-content-center align-items-center h-100">
                        <ChatInfo info="No chats to show" />
                    </div>
                )}
                {chatList.fetching && (
                    <Spinner
                        classes={!chatList.data.length ? "h-100" : ""}
                        {...(chatList.data.length && { size: "sm" })}
                    />
                )}
            </div>
            {!isPreviousChatRoute() && (
                <div className=" d-flex align-items-center justify-content-center active-chat__footer right-border">
                    <div className="d-flex flex-column align-items-center">
                        <ChatGroup
                            onClick={() => {
                                history.push("/dashboard/chat/group");
                            }}
                            className={isGroupChatActive() ? "activate" : "non-activate"}
                        />
                        <p className={`${isGroupChatActive() ? "activate" : ""} position-relative`}>
                            Group Chats
                            {!!userChat.group.unReadCount > 0 && (
                                <span className="badge badge-2">{userChat.group.unReadCount}</span>
                            )}
                        </p>
                    </div>
                    <div className="d-flex flex-column align-items-center ml-5">
                        <ChatSingle
                            onClick={() => {
                                history.push("/dashboard/chat/single");
                            }}
                            className={!isGroupChatActive() ? "activate" : "non-activate"}
                        />
                        <p
                            className={`${
                                !isGroupChatActive() ? "activate" : ""
                            } position-relative`}
                        >
                            Single Chats{" "}
                            {!!userChat.single.unReadCount > 0 && (
                                <span className="badge">{userChat.single.unReadCount}</span>
                            )}
                        </p>
                    </div>
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state, ownProps) => {
    let tabname = ownProps.match.params.tabname;
    return {
        chatList: state.chat.chatList[tabname],
        userChat: state.chat.chatList
    };
};

export default connect(mapStateToProps)(ChatUserList);
