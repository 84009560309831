import { USER_LISTING_REQUEST, USER_LISTING_SUCCESS, USER_LISTING_FAILURE } from "../actions";

const initialState = {
    fetching: false,
    totalUsers: 0,
    list: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case USER_LISTING_REQUEST:
            return {
                ...state,
                fetching: true,
                list: (() => {
                    if (!action.Page || action.Page == 1) {
                        return [];
                    }
                    return state.list;
                })()
            };
        case USER_LISTING_SUCCESS:
            return {
                ...state,
                fetching: false,
                totalUsers: action.count,
                list: [...state.list, ...action.payload]
            };
        case USER_LISTING_FAILURE:
            return {
                ...state,
                fetching: false
            };
        default:
            return state;
    }
}
