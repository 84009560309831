import React from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel
} from "react-accessible-accordion";

import "react-accessible-accordion/dist/fancy-example.css";
import "./Accordian.scss";

import { Button } from "../../components/Button";
import { convertInches } from "../../utils/commonUtil";
import {
    HalalPreference,
    LivingSituation,
    MaritialStatus,
    PrayerRoutine,
    RelocationTypes,
    UserSect
} from "../../constants";

const Accordian = (props) => {
    const {
        PersonalDetails,
        Preferences,
        ProfessionalDetials,
        ReligiousDetails,
        Traits = []
    } = props?.user;

    const getHeightRange = () => {
        if (Preferences?.Personal?.HeightStart && Preferences?.Personal?.HeightEnd) {
            let heightStart = convertInches(Preferences.Personal.HeightStart);
            let heightEnd = convertInches(Preferences.Personal.HeightEnd);

            return `${heightStart} - ${heightEnd}`;
        }

        return "N/A";
    };

    const getUserCountry = (details) => {
        if (details.Location?.City && PersonalDetails?.Location?.Country) {
            return `${PersonalDetails?.Location?.City} ,
            ${PersonalDetails?.Location?.Country}`;
        } else if (details.Location?.City) {
            return `${PersonalDetails?.Location?.City}`;
        } else if (PersonalDetails?.Location?.Country) {
            return `${PersonalDetails?.Location?.Country}`;
        }
        return "N/A";
    };

    const getAgeRange = () => {
        if (Preferences?.Personal?.AgeStart && Preferences?.Personal?.AgeEnd)
            return `${Preferences?.Personal?.AgeStart} -
        ${Preferences?.Personal?.AgeEnd}`;
        return "N/A";
    };

    const renderChildrenStatus = () => {
        if (PersonalDetails?.Children === 0) {
            return "No Kids";
        } else if (PersonalDetails?.Children === 1) {
            return PersonalDetails?.Children + " Kid";
        } else if (PersonalDetails?.Children > 1) {
            return PersonalDetails?.Children + " Kids";
        }

        return "-";
    };

    return (
        <>
            <div className="accordian">
                <Accordion allowZeroExpanded>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                <h2>Personal</h2>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <div className="d-flex flex-column fllex-md-row justify-content-between">
                                <div className="personal__wrapper-1">
                                    <div className="accordian-inner-content d-flex">
                                        <h6>Nationality : </h6>
                                        <p>
                                            {" "}
                                            {props?.user?.Nationality
                                                ? props?.user?.Nationality
                                                : "N/A"}
                                        </p>
                                    </div>
                                    <div className="accordian-inner-content d-flex">
                                        <h6>Age: </h6>
                                        <p>{props?.user?.Age ? props?.user?.Age : "N/A"}</p>
                                    </div>
                                    <div className="accordian-inner-content d-flex">
                                        <h6>Height: </h6>
                                        <p>
                                            {PersonalDetails?.Height
                                                ? convertInches(PersonalDetails?.Height)
                                                : "N/A"}
                                        </p>
                                    </div>
                                    <div className="accordian-inner-content d-flex">
                                        <h6>City of residence:</h6>
                                        <p>
                                            {getUserCountry(PersonalDetails)}
                                            {/* {PersonalDetails?.Location?.City} ,{" "}
                                            {PersonalDetails?.Location?.Country} */}
                                        </p>
                                    </div>
                                    <div className="accordian-inner-content d-flex">
                                        <h6>New Muslim:</h6>
                                        <p>{PersonalDetails?.MuslimConvert ? "Yes" : "No"}</p>
                                    </div>
                                </div>
                                <div className="personal__wrapper-2">
                                    <div className="accordian-inner-content d-flex">
                                        <h6>Languages: </h6>
                                        <p>
                                            {PersonalDetails?.Languages?.length
                                                ? PersonalDetails?.Languages?.toString()
                                                : "N/A"}
                                        </p>
                                    </div>
                                    <div className="accordian-inner-content d-flex">
                                        <h6>Marital Status: </h6>
                                        <p>
                                            {PersonalDetails?.MaritalStatus
                                                ? MaritialStatus.getDisplayTextKey(
                                                      PersonalDetails?.MaritalStatus
                                                  )
                                                : "N/A"}
                                        </p>
                                    </div>
                                    <div className="accordian-inner-content d-flex">
                                        <h6>Children</h6>
                                        <p>
                                            {PersonalDetails.hasOwnProperty("Children")
                                                ? PersonalDetails?.Children
                                                : "N/A"}
                                        </p>
                                    </div>
                                    <div className="accordian-inner-content d-flex">
                                        <h6>Current living situation: </h6>
                                        <p>
                                            {PersonalDetails.hasOwnProperty(
                                                "CurrentLivingSituation"
                                            )
                                                ? LivingSituation.getDisplayTextKey(
                                                      PersonalDetails?.CurrentLivingSituation
                                                  )
                                                : "N/A"}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                <h2>Professional</h2>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <div className="d-flex flex-column fllex-md-row justify-content-between">
                                <div className="personal__wrapper-1">
                                    <div className="accordian-inner-content d-flex">
                                        <h6>Occupation: </h6>
                                        <p>
                                            {" "}
                                            {ProfessionalDetials.hasOwnProperty("Occupation")
                                                ? ProfessionalDetials?.Occupation
                                                : "N/A"}
                                        </p>
                                    </div>
                                    <div className="accordian-inner-content d-flex">
                                        <h6>Level of Education:</h6>
                                        <p>
                                            {ProfessionalDetials.hasOwnProperty("EducationLevel")
                                                ? ProfessionalDetials?.EducationLevel
                                                : "N/A"}
                                        </p>
                                    </div>
                                    <div className="accordian-inner-content d-flex">
                                        <h6>Working Situation: </h6>
                                        <p>
                                            {ProfessionalDetials.hasOwnProperty("WorkingSituation")
                                                ? ProfessionalDetials?.WorkingSituation
                                                : "N/A"}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                <h2>Religious Traits</h2>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <div className="d-flex flex-column fllex-md-row justify-content-between">
                                <div className="personal__wrapper-1">
                                    <div className="accordian-inner-content d-flex">
                                        <h6>Sect:</h6>
                                        <p>
                                            {ReligiousDetails?.Sect
                                                ? UserSect.getDisplayTextKey(ReligiousDetails?.Sect)
                                                : "N/A"}
                                        </p>
                                    </div>
                                    <div className="accordian-inner-content d-flex">
                                        <h6>Prayer Routine:</h6>
                                        <p>
                                            {ReligiousDetails?.PrayerRoutine
                                                ? PrayerRoutine.getDisplayTextKey(
                                                      ReligiousDetails?.PrayerRoutine
                                                  )
                                                : "N/A"}
                                        </p>
                                    </div>
                                    <div className="accordian-inner-content d-flex">
                                        <h6>Islamic Outlook:</h6>
                                        <p>
                                            {ReligiousDetails?.HalalPreference
                                                ? HalalPreference.getDisplayTextKey(
                                                      ReligiousDetails?.HalalPreference
                                                  )
                                                : "N/A"}
                                        </p>
                                    </div>
                                </div>
                                <div className="personal__wrapper-2">
                                    <div className="accordian-inner-content d-flex">
                                        <h6>Alcohol Consumption: </h6>
                                        <p>
                                            {ReligiousDetails.hasOwnProperty("AlcoholConsumption")
                                                ? ReligiousDetails?.AlcoholConsumption
                                                    ? "Yes"
                                                    : "No"
                                                : "N/A"}
                                        </p>
                                    </div>
                                    <div className="accordian-inner-content d-flex">
                                        <h6>Smoking: </h6>
                                        <p>
                                            {ReligiousDetails.hasOwnProperty("Smoking")
                                                ? ReligiousDetails?.Smoking
                                                    ? "Yes"
                                                    : "No"
                                                : "N/A"}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                <h2>Personality Traits</h2>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <div className="row d-flex filter-bt-wrapper">
                                {Traits?.length ? (
                                    Traits.map((item, index) => {
                                        return (
                                            <Button
                                                className="ml-2 mb-2"
                                                title={item}
                                                key={index}
                                            />
                                        );
                                    })
                                ) : (
                                    <div className="accordian-inner-content d-flex w-100 justify-content-center align-items-center">
                                        <p>No traits available</p>
                                    </div>
                                )}
                            </div>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                <h2>Preferences</h2>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p className="filter-sub-heading">Personal</p>
                            <div className="d-flex flex-column fllex-md-row justify-content-between">
                                <div className="personal__wrapper-1">
                                    <div className="accordian-inner-content d-flex">
                                        <h6>Age Range:</h6>
                                        <p>{getAgeRange()}</p>
                                    </div>
                                    <div className="accordian-inner-content d-flex">
                                        <h6>Height Range:</h6>
                                        <p> {getHeightRange()}</p>
                                    </div>
                                    <div className="accordian-inner-content d-flex">
                                        <h6>Previous Marital Status: </h6>
                                        <p>
                                            {Preferences?.Personal.hasOwnProperty(
                                                "PreviousMaritalStatus"
                                            )
                                                ? Preferences?.Personal?.PreviousMaritalStatus
                                                    ? `Married (${renderChildrenStatus()})`
                                                    : `Not Married (${renderChildrenStatus()})`
                                                : "N/A"}
                                        </p>
                                    </div>
                                </div>
                                <div className="personal__wrapper-2">
                                    <div className="accordian-inner-content d-flex">
                                        <h6>Would like to have children: </h6>
                                        <p>
                                            {Preferences?.Personal.hasOwnProperty("FamilyPlans")
                                                ? Preferences?.Personal?.FamilyPlans
                                                    ? "Open to Kids"
                                                    : "No Plans"
                                                : "N/A"}
                                        </p>
                                    </div>
                                </div>
                                <div className="personal__wrapper-2">
                                    <div className="accordian-inner-content d-flex">
                                        <h6>Would Relocate: </h6>
                                        <p>
                                            {Preferences?.Personal.hasOwnProperty("Relocate")
                                                ? RelocationTypes.getDisplayTextKey(
                                                      Preferences?.Personal?.Relocate
                                                  )
                                                : "N/A"}
                                        </p>
                                    </div>
                                </div>
                                <div className="personal__wrapper-2">
                                    <div className="accordian-inner-content d-flex">
                                        <h6>Divorcee Ok: </h6>
                                        <p>
                                            {Preferences?.Personal.hasOwnProperty("IsDivorced")
                                                ? Preferences?.Personal?.IsDivorced
                                                    ? "Yes"
                                                    : "No"
                                                : "N/A"}
                                        </p>
                                    </div>
                                </div>
                                <div className="personal__wrapper-2">
                                    <div className="accordian-inner-content d-flex">
                                        <h6>Working Partner Ok: </h6>
                                        <p>
                                            {Preferences?.Professional.hasOwnProperty("Working")
                                                ? Preferences?.Professional?.Working
                                                    ? "Yes"
                                                    : "No"
                                                : "N/A"}
                                        </p>
                                    </div>
                                </div>
                                <div className="personal__wrapper-2">
                                    <div className="accordian-inner-content d-flex">
                                        <h6>Other Sect Ok: </h6>
                                        <p>
                                            {Preferences?.Religious.hasOwnProperty("Sect")
                                                ? !Preferences?.Religious?.Sect
                                                    ? "Yes"
                                                    : "No"
                                                : "N/A"}
                                        </p>
                                    </div>
                                </div>
                                <div className="personal__wrapper-2">
                                    <div className="accordian-inner-content d-flex">
                                        <h6>Prefer Muslim Convert:</h6>
                                        <p>{Preferences?.Religious?.MuslimConvert ? "Yes" : "No"}</p>
                                    </div>
                                </div>
                            </div>
                            <p className="filter-sub-heading">Personality</p>
                            <h5 className="accordian-sub-heading">Values:</h5>
                            <div className="row d-flex filter-bt-wrapper">
                                {Preferences?.Personality?.Values?.length ? (
                                    Preferences?.Personality?.Values.map((item, index) => {
                                        return (
                                            <Button
                                                className="ml-2 mb-2"
                                                title={item}
                                                key={index}
                                            />
                                        );
                                    })
                                ) : (
                                    <div className="accordian-inner-content d-flex w-100 justify-content-center align-items-center">
                                        <p>No personality values available</p>
                                    </div>
                                )}
                            </div>
                            <h5 className="accordian-sub-heading">Traits:</h5>

                            <div className="row d-flex filter-bt-wrapper">
                                {Preferences?.Personality?.Traits.length ? (
                                    Preferences?.Personality?.Traits.map((item, index) => {
                                        return (
                                            <Button
                                                className="ml-2 mb-2"
                                                title={item}
                                                key={index}
                                            />
                                        );
                                    })
                                ) : (
                                    <div className="accordian-inner-content d-flex w-100 justify-content-center align-items-center">
                                        <p>No personality traits available</p>
                                    </div>
                                )}
                            </div>
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
            </div>
        </>
    );
};
export default Accordian;
