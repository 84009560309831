import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Modal } from "react-responsive-modal";
import { Spinner } from "reactstrap";
import { SRLWrapper } from "simple-react-lightbox";

import "./Verification.scss";
import "react-responsive-modal/styles.css";

import PageTitle from "../../components/PageTitle/PageTitle";
import ListItem from "./ListItem";
import Button from "../../components/Button/Button";
import {
    getPendingUsersListing,
    updateVerificationStatus,
    addNewUserVerification
} from "../../store/actions";
import { getMediaThumbPath } from "../../utils/mediaService";
import { MediaTypes, UserVerificationStatus } from "../../constants";
import { Socket, SocketTypes } from "../../utils/socketUtils";

import VideoPlayIcon from "../../components/SVGICON/video-play-icon";

const lightBoxOptions = {
    progressBar: {
        backgroundColor: "#f2f2f2",
        fillColor: "#000000",
        height: "3px",
        showProgressBar: true
    },
    buttons: {
        showDownloadButton: false
    }
};

const Verification = (props) => {
    const {
        getPendingUsersListing,
        fetching,
        data,
        updateVerificationStatus,
        totalUsers,
        addNewUserVerification
    } = props;

    const [page, setPage] = useState(1);
    const [verifyModal, setVerifyModal] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);
    const [isOpen, setOpen] = useState(false);
    const [selectedUser, setSelectdUser] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getPendingUsersListing({ Page: page }).then((res) => {
            handleLoadMore(res.Data.Users, res.Data.TotalUsers);
        });

        //es-lint-disable-next-line react-hooks/exhaustive-deps
    }, [page, getPendingUsersListing]);

    useEffect(() => {
        function handleNewUserJoined(data) {
            addNewUserVerification(data.User);
        }
        Socket.onNewUserJoined(handleNewUserJoined);

        return () => {
            Socket.remove(SocketTypes.NEW_USER_ID_VERIFICATION, handleNewUserJoined);
        };
    }, [addNewUserVerification]);

    useEffect(() => {
        function scrollListner(e) {
            const bottom = window.innerHeight + window.scrollY >= document.body.offsetHeight - 50;

            if (bottom && data.length < totalUsers && !fetching) {
                setPage(page + 1);
            }
        }
        window.addEventListener("scroll", scrollListner);

        return () => {
            window.removeEventListener("scroll", scrollListner);
        };
    }, [data]);

    useEffect(() => {
        function handleWindowResize() {
            handleLoadMore();
        }

        window.addEventListener("resize", handleWindowResize);

        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, [data]);

    const handleLoadMore = (newData = [], total = totalUsers) => {
        const isScrollable = document.body.scrollHeight > window.innerHeight;

        if (!isScrollable && !fetching) {
            if ([...data, ...newData].length < total) {
                setPage(page + 1);
            }
        }
    };

    const getVideoUrl = (array) => {
        if (array && Boolean(array.length)) {
            let videoUrl = array.find((item) => item.Type === MediaTypes.VIDEO);

            if (videoUrl) {
                return { thumb: getMediaThumbPath(videoUrl), url: videoUrl.Path };
            }
        }
        return null;
    };

    const handleUserSelect = (data) => {
        setSelectdUser(data);
        setVerifyModal(true);
    };

    const handleUserValidate = (status) => {
        setLoading(true);
        let payload = {
            UserId: selectedUser?.Id,
            Status: status
        };

        updateVerificationStatus(payload)
            .then((res) => {
                setVerifyModal(false);
            })
            .catch((err) => {})
            .finally(() => {
                setConfirmModal(false);
                setLoading(false);
            });
    };

    return (
        <>
            <PageTitle title="Verification" />

            <div className="row d-flex justify-content-start ml-0 ml-md-0">
                {Boolean(data.length) ? (
                    <>
                        {data.map((item, index) => {
                            return <ListItem onClick={handleUserSelect} key={index} data={item} />;
                        })}
                    </>
                ) : (
                    !fetching &&
                    !data.length && (
                        <div className="row d-flex justify-content-around m-auto w-80 no-pending-center-wrap">
                            <h4>Currently No Pending Users</h4>
                        </div>
                    )
                )}
            </div>
            {fetching && (
                <div className="d-flex flex-row justify-content-center loader-padding">
                    <p className="mr-2">Loading</p>
                    <Spinner size={"40"} color={"primary"} />
                </div>
            )}
            <Modal
                classNames={{ modal: "verificationModal" }}
                open={verifyModal}
                onClose={() => setVerifyModal(false)}
                showCloseIcon={true}
                center
                closeOnOverlayClick={false}
            >
                <div className="d-flex flex-column flex-lg-row align-items-center justify-content-between verifiy__modal ">
                    <div className="verifiy__modal-info text-center pb-4 pb-lg-0">
                        <img src={getMediaThumbPath(selectedUser?.Media)} alt="profile" />
                        <h3 className="pt-4 pb-1 text-truncate">{selectedUser?.FullName}</h3>
                        <div className="d-flex justify-content-center">
                            <p>{selectedUser?.UserProfile?.Age} </p>
                            <p>
                                {selectedUser?.UserProfile?.Country?.Nationality && ","} &nbsp;
                                {selectedUser?.UserProfile?.Country?.Nationality}
                            </p>
                        </div>
                        <p className="pt-2">{selectedUser?.UserProfile?.Country?.Name} </p>
                    </div>

                    <div className="play-btn-holder">
                        <img
                            className="mb-4 mb-lg-0 video-thumnail"
                            alt="video-thumnail"
                            onClick={() => setOpen(true)}
                            style={{ height: "200px" }}
                            src={getVideoUrl(selectedUser?.IdVerificationMedia)?.thumb}
                        />
                        <div className="play-btn">
                            <VideoPlayIcon className="pointer" onClick={() => setOpen(true)} />
                        </div>
                    </div>

                    <div className="verifiy__modal-images fancy-scroll">
                        <SRLWrapper options={lightBoxOptions}>
                            {Boolean(selectedUser?.IdVerificationMedia) &&
                                selectedUser.IdVerificationMedia.map((item, index) => {
                                    return (
                                        item.Type === MediaTypes.ID && (
                                            <img src={item?.Path} key={index} />
                                        )
                                    );
                                })}
                        </SRLWrapper>
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-center">
                    <Button
                        title="Accept"
                        isLoading={loading}
                        disabled={loading}
                        onClick={() => handleUserValidate(UserVerificationStatus.VERIFIED)}
                        className="btn-2 btn-primary-2"
                    />
                    <Button
                        onClick={() => setConfirmModal(true)}
                        class="ml-3"
                        disabled={loading}
                        title="Decline"
                        className="btn-2 btn-primary-2 ml-3"
                    />
                </div>
            </Modal>
            <Modal open={isOpen} onClose={() => setOpen(false)} showCloseIcon={true} center>
                <video
                    id="steam_video"
                    width="660"
                    height="440"
                    playsInline
                    controls="controls"
                    autoPlay={true}
                >
                    <source
                        src={getVideoUrl(selectedUser?.IdVerificationMedia)?.url}
                        type="video/mp4"
                    />
                </video>
            </Modal>
            <Modal
                classNames={{ modal: "ConfirmModal" }}
                open={confirmModal}
                onClose={() => setConfirmModal(false)}
                showCloseIcon={false}
                center
            >
                <div className="confirm-modal d-flex flex-column align-items-center justify-content-center text-center ">
                    <p>Are you sure?</p>
                    <p>
                        By declining this, {selectedUser?.FullName} will have to do the ID
                        verification process again
                    </p>
                    <div className="d-flex justify-content-center mt-3">
                        <Button
                            className="confrim-btn"
                            isLoading={loading}
                            disabled={loading}
                            onClick={() => handleUserValidate(UserVerificationStatus.DECLINED)}
                            title="Yes"
                        />
                        <Button
                            onClick={() => setConfirmModal(false)}
                            disabled={loading}
                            className="ml-3 confrim-btn"
                            title="No"
                        />
                    </div>
                </div>
            </Modal>
        </>
    );
};

const mapDispatchToProps = {
    getPendingUsersListing,
    updateVerificationStatus,
    addNewUserVerification
};

const mapStateToProps = (state) => {
    const { verification } = state;

    return {
        data: verification.list,
        fetching: verification.loading,
        totalUsers: verification.totalUsers
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Verification);
