import React, { useState, useEffect } from "react";
import SimpleBarReact from "simplebar-react";
import { connect } from "react-redux";

import "simplebar/dist/simplebar.min.css";
import "./Notifications.scss";

import NotificationItem from "./NotificationItem";
import Loader from "../../components/Loader/Spinner";
import { getNotificationListing } from "../../store/actions";

const Notifications = (props) => {
    const { getNotificationListing, data, fetching, totalNotifications } = props;

    const [page, setPage] = useState(1);

    useEffect(() => {
        getNotificationListing({ Page: page });

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    return (
        <SimpleBarReact>
            {Boolean(data.length) &&
                data.map((item, index) => {
                    return <NotificationItem key={index} data={item} />;
                })}

            {fetching ? (
                <div
                    className={`d-flex flex-column justify-content-center ${
                        !!data.length ? "mt-2 mb-2" : "vh-8"
                    }`}
                >
                    <Loader />
                </div>
            ) : !fetching && !data.length ? (
                <div className="flex-column d-flex justify-content-center vh-8">
                    <h5 className="align-self-center ">No Notifications Available</h5>
                </div>
            ) : (
                !fetching &&
                data.length < totalNotifications && (
                    <div className="notification__footer" onClick={() => setPage(page + 1)}>
                        <button className="see-all">See More</button>
                    </div>
                )
            )}
        </SimpleBarReact>
    );
};

const mapDispatchToProps = {
    getNotificationListing
};

const mapStateToProps = (state) => {
    const { notifications } = state;

    return {
        data: notifications.list,
        fetching: notifications.fetching,
        totalNotifications: notifications.totalNotifications
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
