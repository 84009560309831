import React, { useEffect } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { connect, useDispatch } from "react-redux";

import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./index.scss";
import "./App.css";
import Signup from "../src/screens/Signup/Signup";
import DefaultLayout from "../src/screens/DefaultLayout/DefaultLayout";
import { APP_ROUTES } from "./helpers/routeHelpers";
import { AccountSetup } from "./screens";
import Login from "./screens/Login/Login";
import ScrollTop from "./components/ScrollTop/ScrollTop";
import Tour from "./components/Tour/Tour";
import { Notificaion } from "./utils/notificationUtil";
import { updateFCMToken } from "./store/actions";

const App = (props) => {
    const { isAuthenticated } = props;
    const dispatch = useDispatch();

    useEffect(() => {
        let notification;
        if (isAuthenticated && "Notification" in window) {
            notification = new Notificaion();
            notification
                .getToken()
                .then((token) => {
                    dispatch(updateFCMToken({ FCMToken: token }));
                })
                .catch((err) => {});
        }

        return () => {
            if (notification) {
                notification.removeListeners();
            }
        };
    }, [isAuthenticated]);

    return (
        <div className="primary-background">
            {/* <Tour /> */}
            <ScrollTop />
            <Switch>
                <Route path={APP_ROUTES.SIGNUP} name="signup" component={Signup} />
                <Route path={APP_ROUTES.LOGIN} name="Login" component={Login} />
                <Route
                    path={APP_ROUTES.ACCOUNT_SETUP}
                    name="account-setup"
                    component={AccountSetup}
                />

                <Route path={APP_ROUTES.DASHBOARD} name="DefaultLayout" component={DefaultLayout} />
                <Redirect to={APP_ROUTES.LOGIN} />
            </Switch>
            <ToastContainer
                toastClassName="toast-container"
                position="bottom-right"
                autoClose={2500}
                hideProgressBar
                closeOnClick
                draggable
                pauseOnHover
                closeButton={false}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.isAuthenticated
    };
};

export default connect(mapStateToProps, null)(App);
