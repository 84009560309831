import React, { Component } from "react";
import "./CommentBox.scss";

const CommentBox = (props) => {
    return (
        <div className="comment__info-remarks fancy-scroll">
            <h2 className="pb-3">{props.title}</h2>
            <p>{props.description}</p>
        </div>
    );
};
export default CommentBox;
