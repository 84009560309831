import React from "react";

import "./Verification.scss";

import { getMediaThumbPath } from "../../utils/mediaService";
import { IdVerificationTypes } from "../../constants";

const ListItem = (props) => {
    const { data, onClick } = props;

    const country = data?.UserProfile?.Country?.Name;
    const age = data?.UserProfile?.Age;

    return (
        <div
            className=" d-flex align-items-center justify-content-between profile-card__wrapper ml-0 ml-md-3"
            onClick={() => onClick(data)}
        >
            <div className="d-flex align-items-center profile-card__profile">
                <img src={getMediaThumbPath(data.Media)} className="profile-avatar" />
                <p className="px-3 text-truncate">{data.FullName}</p>
            </div>
            <div className="profile-card__info">
                <div className="d-flex profile-card__info-detail">
                    <p>{age} , </p>
                    <p>&nbsp;{country}</p>
                </div>
                <div className="profile-card__info-detail">

                    <p className="text-truncate">
                        ID Verification:{" "}
                        {IdVerificationTypes.getDisplayTextKey(data.IdVerificationType)}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ListItem;
