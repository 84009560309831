import React from "react";
import { Spinner } from "reactstrap";

const CustomSpinner = (props) => {
    const {
        classes = "",
        size = "md",
        color = "primary",
        showVerifyText = false,
        text = "Verifying"
    } = props;
    return (
        <div
            className={`d-flex flex-column justify-content-center align-items-center w-100 ${classes}`}
        >
            <Spinner size={size} color={color} />
            {showVerifyText && <p>{text}</p>}
        </div>
    );
};

export default CustomSpinner;
