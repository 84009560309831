import React from "react";
import "./PageTitle.scss";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward, faAngleLeft } from "@fortawesome/free-solid-svg-icons";
const PageTitle = (props) => {
    return (
        <>
            <Link to={props.link} className="page-title">
                {props.icon ? <FontAwesomeIcon className="mr-3" icon={faAngleLeft} /> : null}
                {props.title}
            </Link>
        </>
    );
};
export default PageTitle;
