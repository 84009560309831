import { injectBaseConstantMethods } from "./BaseConstants";

const SETTING_ROUTE_TYPES = {
    USER_ACCOUNT: "user-account",
    FAQ: "faq",
    ABOUT_US: "about-us",
    CONTACT_US: "contact-us",
    TERMS_CONDITION: "terms-and-condition",
    NOTIFICATION: "notification"
};

const displayTextKeys = {
    [SETTING_ROUTE_TYPES.USER_ACCOUNT]: "Account Settings",
    [SETTING_ROUTE_TYPES.FAQ]: "FAQs",
    [SETTING_ROUTE_TYPES.CONTACT_US]: "Contact Us",
    [SETTING_ROUTE_TYPES.ABOUT_US]: "About Us",
    [SETTING_ROUTE_TYPES.TERMS_CONDITION]: "Terms & Conditions",
    [SETTING_ROUTE_TYPES.NOTIFICATION]: "Notification Settings"
};

const labelClass = {
    [SETTING_ROUTE_TYPES.USER_ACCOUNT]: "info",
    [SETTING_ROUTE_TYPES.FAQ]: "info",
    [SETTING_ROUTE_TYPES.ABOUT_US]: "info",
    [SETTING_ROUTE_TYPES.CONTACT_US]: "info",
    [SETTING_ROUTE_TYPES.TERMS_CONDITION]: "info",
    [SETTING_ROUTE_TYPES.NOTIFICATION]: "info"
};

export default injectBaseConstantMethods(SETTING_ROUTE_TYPES, displayTextKeys, labelClass);
