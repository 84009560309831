import React, { useState, useEffect } from "react";
import { AvForm } from "availity-reactstrap-validation";

import "./EditableText.scss";
import { TextInput } from "../TextInput";
import { Spinner } from "../Loader";

const EditableText = (props) => {
    const { content, placeholder = "Enter Name", isLoading, onSubmit } = props;
    const [isEditable, setIsEditable] = useState(false);
    const [contentText, setContentText] = useState(content);

    useEffect(() => {
        setContentText(content);
    }, [content]);

    const handleSubmit = () => {
        if (onSubmit && typeof onSubmit === "function") {
            onSubmit(contentText, () => setIsEditable(false));
        }
    };

    return isEditable ? (
        <AvForm className="align-self-center" onValidSubmit={handleSubmit}>
            <div className="d-flex align-items-center content-input-container mt-3 mb-3 mb-md-5 mt-md-5">
                <TextInput
                    name="editableContent"
                    placeholder={placeholder}
                    required={true}
                    type="text"
                    value={contentText}
                    onChange={(e) => setContentText(e.target.value)}
                    validate={{
                        required: {
                            value: true,
                            errorMessage: "Name is required."
                        },
                        pattern: {
                            value: "/^[a-zA-Z ]+$/",
                            errorMessage: "No numbers and special characters are allowed"
                        },
                        maxLength: {
                            value: 55,
                            errorMessage: "Max 55 characters are allowed"
                        },
                        minLength: {
                            value: 6,
                            errorMessage: "Min 6 characters should be entered"
                        }
                    }}
                />
                <div className="content-btn-container">
                    <button className="content-btn" type="submit" disabled={isLoading}>
                        {isLoading ? <Spinner size="sm" /> : <i className="fa fa-check"></i>}
                    </button>
                    <button
                        className="content-btn"
                        disabled={isLoading}
                        type="button"
                        onClick={() => {
                            setContentText(content);
                            setIsEditable(false);
                        }}
                    >
                        <i className="fa fa-close"></i>
                    </button>
                </div>
            </div>
        </AvForm>
    ) : (
        <div className="content-container mt-3 mb-3 mb-md-5 mt-md-5">
            <p className="text-center">{content}</p>
            <button className="content-btn" onClick={() => setIsEditable(true)}>
                <i class="fa fa-pencil"></i>
            </button>
        </div>
    );
};

export default EditableText;
