import React, { Component } from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import "./Accordian.scss";
import Button from "../Button/Button";
const Accordian = (props) => {
    return (
        <>
            <div className="accordian">
                <Accordion allowZeroExpanded>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                <h2>Personal</h2>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <div className="d-flex flex-column flex-md-row justify-content-between">
                                <div className="personal__wrapper-1">
                                    <div className="accordian-inner-content d-flex">
                                        <h6>Nationality : </h6>
                                        <p> Scottish Pakistani</p>
                                    </div>
                                    <div className="accordian-inner-content d-flex">
                                        <h6>Age: </h6>
                                        <p>21</p>
                                    </div>
                                    <div className="accordian-inner-content d-flex">
                                        <h6>Height: </h6>
                                        <p>5’ 8”</p>
                                    </div>
                                    <div className="accordian-inner-content d-flex">
                                        <h6>Current Location:</h6>
                                        <p>Glasgow, Scotlamd</p>
                                    </div>
                                </div>
                                <div className="personal__wrapper-2">
                                    <div className="accordian-inner-content d-flex">
                                        <h6>Languages: </h6>
                                        <p>Urdu, English, Hindi</p>
                                    </div>
                                    <div className="accordian-inner-content d-flex">
                                        <h6>Marital Status: </h6>
                                        <p>Single</p>
                                    </div>
                                    <div className="accordian-inner-content d-flex">
                                        <h6>Children</h6>
                                        <p>None</p>
                                    </div>
                                    <div className="accordian-inner-content d-flex">
                                        <h6>Current living situation: </h6>
                                        <p>With family</p>
                                    </div>
                                </div>
                            </div>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                <h2>Professional</h2>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <div className="d-flex flex-column flex-md-row justify-content-between">
                                <div className="personal__wrapper-1">
                                    <div className="accordian-inner-content d-flex">
                                        <h6>Occupation: </h6>
                                        <p> Student</p>
                                    </div>
                                    <div className="accordian-inner-content d-flex">
                                        <h6>Level of Education:</h6>
                                        <p>Postgraduate</p>
                                    </div>
                                    <div className="accordian-inner-content d-flex">
                                        <h6>Working Situation: </h6>
                                        <p>Unemployed</p>
                                    </div>
                                </div>
                            </div>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                <h2>Religious Traits</h2>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <div className="d-flex flex-column flex-md-row justify-content-between">
                                <div className="personal__wrapper-1">
                                    <div className="accordian-inner-content d-flex">
                                        <h6>Sect:</h6>
                                        <p>Sunni</p>
                                    </div>
                                    <div className="accordian-inner-content d-flex">
                                        <h6>Prayer Routine:</h6>
                                        <p>Regular</p>
                                    </div>
                                    <div className="accordian-inner-content d-flex">
                                        <h6>Islamic Outlook:</h6>
                                        <p>Strict</p>
                                    </div>
                                </div>
                                <div className="personal__wrapper-2">
                                    <div className="accordian-inner-content d-flex">
                                        <h6>Alcohol Consumption: </h6>
                                        <p>No</p>
                                    </div>
                                    <div className="accordian-inner-content d-flex">
                                        <h6>Smoking: </h6>
                                        <p>No</p>
                                    </div>
                                </div>
                            </div>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                <h2>Personality Traits</h2>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <div className="row d-flex filter-btn-wrapper">
                                <Button className="ml-2 mb-2" title="Affectionate" />
                                <Button className="ml-2 mb-2" title="Affectionate" />
                            </div>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                <h2>Preferences</h2>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p className="filter-sub-heading">Personal</p>
                            <div className="d-flex flex-column flex-md-row justify-content-between">
                                <div className="personal__wrapper-1">
                                    <div className="accordian-inner-content d-flex">
                                        <h6>Age Range:</h6>
                                        <p>18-35</p>
                                    </div>
                                    <div className="accordian-inner-content d-flex">
                                        <h6>Height Range:</h6>
                                        <p> 5’ - 5’8”</p>
                                    </div>
                                    <div className="accordian-inner-content d-flex">
                                        <h6>Previous Marital Status: </h6>
                                        <p>Not Married (No Kids)</p>
                                    </div>
                                </div>
                                <div className="personal__wrapper-2">
                                    <div className="accordian-inner-content d-flex">
                                        <h6>Family Plans: </h6>
                                        <p>Open to Kids</p>
                                    </div>
                                </div>
                            </div>
                            <p className="filter-sub-heading">Personality</p>
                            <h5 className="accordian-sub-heading">Values:</h5>
                            <div className="row d-flex filter-btn-wrapper">
                                <Button className="ml-2 mb-2" title="Honesty" />
                                <Button className="ml-2 mb-2" title="Respect" />
                            </div>
                            <h5 className="accordian-sub-heading">Traits:</h5>

                            <div className="row d-flex filter-btn-wrapper">
                                <Button className="ml-2 mb-2" title="Courageous" />
                                <Button className="ml-2 mb-2" title="Caring" />
                            </div>
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
            </div>
        </>
    );
};
export default Accordian;
