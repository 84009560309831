import { combineReducers } from "redux";
import { SIGN_OUT } from "../actions";

import authReducer from "./authReducer";
import root from "./rootReducer";
import userReducer from "./userReducer";
import deviceIdReducer from "./deviceIdReducer";
import reportReducer from "./reportReducer";
import verificationReducer from "./verificationReducer";
import notificationReducer from "./notificationReducer";
import entityReducer from "./entityReducer";
import chatReducer from "./chatReducer";

const appReducer = combineReducers({
    root: root,
    auth: authReducer,
    deviceId: deviceIdReducer,
    notifications: notificationReducer,
    verification: verificationReducer,
    entities: entityReducer,
    chat: chatReducer,
    reports: reportReducer,
    users: userReducer
});

const rootReducer = (state, action) => {
    if (action.type === SIGN_OUT) {
        const { deviceId } = state;
        state = { deviceId };
    }

    return appReducer(state, action);
};

export default rootReducer;
