
import React, { Component } from 'react';
const ReportIcon = (props) => {
    return (
        <svg className="icon-change" width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 15V19.5" stroke="#333333" stroke-width="2" stroke-linecap="round" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M18 25.5C18.8284 25.5 19.5 24.8284 19.5 24C19.5 23.1716 18.8284 22.5 18 22.5C17.1716 22.5 16.5 23.1716 16.5 24C16.5 24.8284 17.1716 25.5 18 25.5Z" fill="#333333" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.1381 5.96529C17.5247 5.30798 18.4753 5.30798 18.8619 5.96529L32.1135 28.493C32.5057 29.1596 32.025 30 31.2516 30H4.74842C3.975 30 3.49434 29.1596 3.88648 28.493L17.1381 5.96529Z" stroke="#333333" stroke-width="2" stroke-linejoin="round" />
        </svg>


    );
}
export default ReportIcon