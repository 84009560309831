import React from "react";
import { AvField, AvForm } from "availity-reactstrap-validation";
import "./CommentModal.scss";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import Button from "../Button/Button";

const CommentModal = (props) => {
    const {
        open = false,
        onModalClose = () => {},
        remarksValue = "",
        setRemarksValue = () => {},
        onNextPress = () => {},
        loading = false,
        title = "Comments"
    } = props;

    return (
        <Modal
            classNames={{ modal: "commentModal" }}
            open={open}
            onClose={onModalClose}
            showCloseIcon={false}
            center
            closeOnOverlayClick={false}
        >
            <FontAwesomeIcon className="back-icon" onClick={onModalClose} icon={faAngleLeft} />
            <div className="d-flex flex-column align-items-center justify-content-center comment__modal ">
                <h4 className="pb-3">{title}</h4>
                <AvForm className="text-center" onValidSubmit={onNextPress}>
                    <AvField
                        type="textarea"
                        placeholder="Share details here..."
                        required
                        name="remarksValue"
                        rows="12"
                        cols="60"
                        maxlength="300"
                        value={remarksValue}
                        onChange={(e) => setRemarksValue(e.target.value)}
                        validate={{
                            required: {
                                value: true,
                                errorMessage: "Details are required."
                            },
                            minLength: {
                                value: 1,
                                errorMessage: "Please enter a message"
                            }
                        }}
                        maxlength="300"
                        className="mt-4 mb-5"
                    />
                    {/* <textarea
                    className="mt-4 mb-5"
                    rows="12"
                    cols="60"
                    placeholder="Share details here..."
                    value={remarksValue}
                    onChange={(e) => setRemarksValue(e.target.value)}
                ></textarea> */}
                    <Button type="submit" title="Next" onClick={onNextPress} isLoading={loading} />
                </AvForm>
            </div>
        </Modal>
    );
};
export default CommentModal;
