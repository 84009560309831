import React from "react";
import { connect } from "react-redux";
import moment from "moment";

import ChatDateTime from "../../components/ChatDateTime/ChatDateTime";
import Receiver from "../../components/Receiver/Receiver";
import Sender from "../../components/Sender/Sender";
import { ChatMessageTypes, GenderType } from "../../constants";
import ChatInfo from "../../components/ChatInfo/ChatInfo";

const ChatMessageList = (props) => {
    const { messages, currentChat, currentUser } = props;

    const renderDate = (msg, index) => {
        let previousMessage = messages[index - 1];
        let date;

        if (!previousMessage) {
            date = moment(msg.SendDate).format("LLL");
        } else {
            let isPreviousDay = moment(previousMessage.SendDate).isBefore(msg.SendDate, "day");
            if (isPreviousDay) {
                date = moment(msg.SendDate).format("LL");
            }
        }

        return date ? <ChatDateTime date={date} /> : null;
    };

    const renderChatMessageList = (data) => {
        if (data.Type === ChatMessageTypes.CHAT_ACCEPTED) {
            return <ChatInfo info={`Chat Request has been accepted`} />;
        } else if (data.Type === ChatMessageTypes.CHAT_REJECTED) {
            return (
                <ChatInfo
                    info={`${data.SenderName} declined your chat. You can exit the conversation from chat settings`}
                />
            );
        } else if (data.Type === ChatMessageTypes.GUARDIAN_ADDED) {
            let userData = currentChat?.Users?.find((user) => user.UserId === data.SenderId);
            return (
                <ChatInfo
                    info={`${userData?.Name} added ${
                        userData?.Gender === GenderType.MALE ? "his " : "her "
                    } Guardian`}
                />
            );
        } else if (data.Type === ChatMessageTypes.GUARDIAN_REMOVED) {
            let userData = currentChat?.Users?.find((user) => user.UserId === data.SenderId);
            return (
                <ChatInfo
                    info={`${userData?.Name || "Participant"} removed ${
                        userData?.Gender === GenderType.MALE ? "his" : "her"
                    } Guardian`}
                />
            );
        } else if (data.Type === ChatMessageTypes.USER_LEFT) {
            return <ChatInfo info={`${data?.SenderName} left the conversation`} />;
        } else if (data.Type === ChatMessageTypes.GUARDIAN_LEFT) {
            return <ChatInfo info={`${data?.SenderName}'s guardian left the conversation`} />;
        } else if (
            data.SenderId === currentUser.Id &&
            data.Type === ChatMessageTypes.MEDIA_MESSAGE
        ) {
            return (
                <Sender
                    picture={data?.Attachments[0]?.ThumbPath}
                    userImage={data.SenderMediaUrl}
                    data={data}
                    caption={data.Content}
                />
            );
        } else if (data.Type === ChatMessageTypes.MEDIA_MESSAGE) {
            return (
                <Receiver
                    picture={data?.Attachments[0]?.ThumbPath}
                    userImage={data.SenderMediaUrl}
                    data={data}
                    caption={data.Content}
                />
            );
        } else if (data.SenderId === currentUser.Id) {
            return <Sender userImage={data.SenderMediaUrl} data={data} message={data.Content} />;
        }
        return (
            <Receiver
                name={data.SenderName}
                userImage={data.SenderMediaUrl}
                type={data.Type}
                data={data}
                message={data.Content}
            />
        );
    };

    return (
        <React.Fragment>
            {messages.map((message, index) => {
                return (
                    <div key={index}>
                        {renderDate(message, index)}
                        {renderChatMessageList(message, index)}
                    </div>
                );
            })}
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        currentUser: state.auth.user
    };
};
export default connect(mapStateToProps)(ChatMessageList);
