import React, { Component } from "react";
const MatchIcon = (props) => {
    return (
        <svg
            className={props.className}
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.5 21.8633C6.5 21.8633 10.2917 20.2383 11.375 19.1549C12.4583 18.0716 9.20833 18.0716 9.20833 12.6549C9.20833 7.23828 13.5417 7.23828 13.5417 7.23828C13.5417 7.23828 17.875 7.23828 17.875 12.6549C17.875 18.0716 14.625 18.0716 15.7083 19.1549C16.7917 20.2383 20.5833 21.8633 20.5833 21.8633"
                stroke="#6246EA"
                stroke-linecap="round"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M13.5262 24.8865C19.8003 24.8865 24.8865 19.8003 24.8865 13.5262C24.8865 7.25216 19.8003 2.16602 13.5262 2.16602C7.25216 2.16602 2.16602 7.25216 2.16602 13.5262C2.16602 19.8003 7.25216 24.8865 13.5262 24.8865Z"
                stroke="#6246EA"
            />
        </svg>
    );
};
export default MatchIcon;
