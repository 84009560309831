
import React, { Component } from 'react';
const LogoutIcon = (props) => {
    return (
        <svg className="icon-change" width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.375 3.875H24.5417C25.2268 3.875 25.8839 4.14717 26.3684 4.63164C26.8528 5.11611 27.125 5.77319 27.125 6.45833V24.5417C27.125 25.2268 26.8528 25.8839 26.3684 26.3684C25.8839 26.8528 25.2268 27.125 24.5417 27.125H19.375" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12.916 21.9577L19.3743 15.4993L12.916 9.04102" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M19.375 15.5H3.875" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>




    );
}
export default LogoutIcon