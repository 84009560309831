import React, { useState } from "react";
import { Modal } from "react-responsive-modal";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { AvForm } from "availity-reactstrap-validation";

import "./ConfirmRemoveModal.scss";
import "./CustomModal.scss";
import "./UsersModal.scss";
import "react-responsive-modal/styles.css";

import Button from "../Button/Button";
import { RadioBoxItem } from "../RadioBoxItem";
import { CommentModal } from "../CommentModal";
import { SuspensionModal } from "../SuspensionModal";
import { ConfirmSuspension } from "../ConfirmSuspension";
import { ReasonModal } from "../ReasonModal";
import UserItem from "./UserItem";
import ChatProfile from "../ChatProfile/ChatProfile";
import { ReportTypes } from "../../constants";
import { handleReportAction } from "../../store/actions";
import { TextInput } from "../../components/TextInput";

const WarningBox = (props) => {
    const {
        setAction = () => {},
        participants = [],
        type,
        reportId = null,
        reportedUserId = null,
        updateCurrentReport = undefined,
        handleReportAction,
        setDropDownValue = () => {}
    } = props;

    const [reasonModal, setReasonModal] = useState(false);
    const [selectedReason, setSelectedReason] = useState("");
    const [selectedUser, setSelectedUser] = useState(null);
    const [usersModal, setUsersModal] = useState(
        type === ReportTypes.SUSPEND || type === ReportTypes.REMOVE || type === ReportTypes.WARNING
            ? true
            : false
    );
    const [suspendModal, setSuspendModal] = useState(false);
    const [explainModal, setExplainModal] = useState(false);
    const [explainRemarks, setExplainRemarks] = useState("");
    const [confirmRemoveModal, setConfirmRemoveModal] = useState(false);
    const [confirmSuspensionModal, setConfirmSuspensionModal] = useState(false);
    const [commentModal, setCommentModal] = useState();
    const [suspensionDays, setSuspensionDays] = useState("");
    const [resolveModal, setResolveModal] = useState(false);
    const [unfoundModal, setUnfoundModal] = useState(type === ReportTypes.RESOLVE ? true : false);
    const [loading, setLoading] = useState(false);
    const [otherReason, setOtherReason] = useState(null);

    // -------------  Initiate  Actions ------------------

    const handleUserSelect = (value) => {
        if (
            type === ReportTypes.SUSPEND ||
            type === ReportTypes.REMOVE ||
            type === ReportTypes.WARNING
        ) {
            setSelectedUser(value);
            setReasonModal(true);
            setUsersModal(false);
        }
    };

    const handleReasonSelect = () => {
        if (selectedReason) {
            if (type === ReportTypes.SUSPEND) {
                setReasonModal(false);
                setSuspendModal(true);
            }
            if (type === ReportTypes.WARNING || type === ReportTypes.REMOVE) {
                setReasonModal(false);
                setExplainModal(true);
            }
        }
    };

    const handleDaysSubmit = () => {
        if (suspensionDays && explainRemarks?.trim()?.length) {
            if (type === ReportTypes.SUSPEND) {
                setSuspendModal(false);
                setConfirmSuspensionModal(true);
            }
        }
    };

    //warning action
    const handleExplainRemarksSubmit = () => {
        if (explainRemarks.trim().length) {
            if (type === ReportTypes.WARNING) {
                handleSubmitAction()
                    .then((res) => {
                        setExplainModal(false);
                        setResolveModal(true);
                    })
                    .catch((err) => {});
            }

            if (type === ReportTypes.REMOVE) {
                setExplainModal(false);
                setConfirmRemoveModal(true);
            }
        }
    };

    const handleUnfoundReport = () => {
        if (type === ReportTypes.RESOLVE) {
            setUnfoundModal(false);
            setCommentModal(true);
        }
    };

    //remove action
    const handleNextPressRemove = () => {
        if (type === ReportTypes.REMOVE) {
            if (otherReason === ReportTypes.WARNING) {
                setAction(ReportTypes.WARNING);
                setConfirmRemoveModal(false);
                setExplainRemarks("");
                setSelectedReason("");
                setReasonModal(true);
                return;
            } else if (otherReason === ReportTypes.SUSPEND) {
                setAction(ReportTypes.SUSPEND);
                setConfirmRemoveModal(false);
                setExplainRemarks("");
                setSelectedReason("");
                setReasonModal(true);
                return;
            }

            handleSubmitAction()
                .then((res) => {
                    setConfirmRemoveModal(false);
                    setResolveModal(true);
                })
                .catch((err) => {});
        }
    };

    //unfound action
    const handleCommentSubmit = () => {
        if (explainRemarks.trim().length) {
            if (type === ReportTypes.RESOLVE) {
                handleSubmitAction()
                    .then((res) => {
                        setCommentModal(false);
                        setResolveModal(true);
                    })
                    .catch((err) => {});
            }
        }
    };

    //suspendAction
    const handleSuspendUser = () => {
        if (explainRemarks.trim().length || suspensionDays) {
            if (type === ReportTypes.SUSPEND) {
                handleSubmitAction()
                    .then((res) => {
                        setConfirmSuspensionModal(false);
                        setResolveModal(true);
                    })
                    .catch((err) => {});
            }
        }
    };

    // -------------  Closing  Actions ------------------

    const handleUsersModalClose = () => {
        if (
            type === ReportTypes.SUSPEND ||
            type === ReportTypes.REMOVE ||
            type === ReportTypes.WARNING
        ) {
            setUsersModal(false);
            setAction(null);
            setDropDownValue("Action");
        }
    };

    const handleReasonModalClose = () => {
        if (
            type === ReportTypes.SUSPEND ||
            type === ReportTypes.REMOVE ||
            type === ReportTypes.WARNING
        ) {
            setOtherReason(null);
            setSelectedReason("");
            setReasonModal(false);
            if (otherReason) {
                setAction(null);
                setDropDownValue("Actions");
                return;
            }
            setUsersModal(true);
        }
    };

    const handleSuspendDaysModalClose = () => {
        if (type === ReportTypes.SUSPEND) {
            setSuspensionDays("");
            setExplainRemarks("");
            setSuspendModal(false);
            setReasonModal(true);
        }
    };

    const handleConfirmSuspensionModalClose = () => {
        if (loading) {
            return;
        }
        if (type === ReportTypes.SUSPEND) {
            setConfirmSuspensionModal(false);
            setSuspendModal(true);
        }
    };

    const handleExplainModalClose = () => {
        if (loading) {
            return null;
        }
        if (type === ReportTypes.WARNING || type === ReportTypes.REMOVE) {
            setExplainRemarks("");
            setExplainModal(false);
            setReasonModal(true);
        }
    };

    const handleUnfoundModalClose = () => {
        if (type === ReportTypes.RESOLVE) {
            setUnfoundModal(false);
            setAction(null);
            setDropDownValue("Actions");
        }
    };

    const handleCommentModalClose = () => {
        if (type === ReportTypes.RESOLVE) {
            setExplainRemarks("");
            setCommentModal(false);
            setUnfoundModal(true);
        }
    };

    const handleConfirmRemoveClose = () => {
        if (loading) {
            return null;
        }
        if (type === ReportTypes.REMOVE) {
            setConfirmRemoveModal(false);
            setExplainModal(true);
            setOtherReason(null);
        }
    };

    const handleResolveModalClose = () => {
        setReasonModal(false);
        setAction(null);
        setDropDownValue("Actions");
    };

    const handleOkayPress = () => {
        setResolveModal(false);
        setAction(null);
        setDropDownValue("Actions");
    };

    const handleSubmitAction = async () => {
        setLoading(true);

        let payload = {
            ...(selectedReason && { CauseId: selectedReason }),
            ReportId: reportId,
            ReportedId: selectedUser?.UserId || reportedUserId,
            ReportedName: selectedUser?.Name,
            ...(suspensionDays && { SuspendedDays: suspensionDays }),
            ...(explainRemarks && { Remarks: explainRemarks }),
            Type: type
        };

        try {
            const res = await handleReportAction(payload);
            if (updateCurrentReport) {
                updateCurrentReport(res.Data);
            }
            setLoading(false);
            return Promise.resolve(res);
        } catch (err) {
            setLoading(false);
            return Promise.reject(err);
        }
    };

    return (
        <>
            <Modal
                classNames={{ modal: "previousModal" }}
                open={usersModal}
                onClose={handleUsersModalClose}
                showCloseIcon={false}
                center
                closeOnOverlayClick={false}
            >
                <FontAwesomeIcon
                    className="back-icon"
                    onClick={handleUsersModalClose}
                    icon={faAngleLeft}
                />
                <div className="d-flex flex-column align-items-center justify-content-center user__modal ">
                    <h4 className="pb-3">
                        Which user do you want to{" "}
                        {type === ReportTypes.SUSPEND
                            ? "Suspend"
                            : type === ReportTypes.WARNING
                            ? "warn"
                            : type === ReportTypes.REMOVE && "Remove"}
                        ?
                    </h4>

                    <div className="d-flex flex-column align-items-center justify-content-center perivious__modal ">
                        {/* <h4 className="pb-3">chat info</h4> */}
                        <div className="d-flex pt-4">
                            {participants.map((item) => {
                                return (
                                    <ChatProfile
                                        exit={true}
                                        picture={item.MediaPath}
                                        data={item}
                                        onUserClick={() => handleUserSelect(item)}
                                        fromReport={true}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </div>
            </Modal>

            <ReasonModal
                open={reasonModal}
                onModalClose={handleReasonModalClose}
                selectedReason={selectedReason}
                onReasonSelect={setSelectedReason}
                onNextPress={handleReasonSelect}
            />

            <SuspensionModal
                open={suspendModal}
                onModalClose={handleSuspendDaysModalClose}
                onNextPress={handleDaysSubmit}
                remarksValue={explainRemarks}
                setRemarksValue={setExplainRemarks}
                suspensionDays={suspensionDays}
                setSuspensionDays={setSuspensionDays}
            />

            <Modal
                classNames={{ modal: "removeModal" }}
                open={confirmRemoveModal}
                onClose={handleConfirmRemoveClose}
                showCloseIcon={false}
                center
                closeOnOverlayClick={false}
            >
                <FontAwesomeIcon
                    className="back-icon"
                    onClick={handleConfirmRemoveClose}
                    icon={faAngleLeft}
                />
                <div className="d-flex flex-column align-items-center justify-content-center remove__modal">
                    <h4 className="pb-3">
                        Are you sure you want to remove {selectedUser?.Name} from the platform?
                    </h4>
                    <p className="py-2">You can choose from other actions as well: </p>

                    <ul class="reason-checkox">
                        <RadioBoxItem
                            value={ReportTypes.WARNING}
                            label={ReportTypes.getDisplayTextKey(ReportTypes.WARNING)}
                            checked={otherReason === ReportTypes.WARNING}
                            onClick={() => setOtherReason(ReportTypes.WARNING)}
                        />
                        <RadioBoxItem
                            value={ReportTypes.SUSPEND}
                            label={ReportTypes.getDisplayTextKey(ReportTypes.SUSPEND)}
                            checked={otherReason === ReportTypes.SUSPEND}
                            onClick={() => setOtherReason(ReportTypes.SUSPEND)}
                        />
                    </ul>
                    <Button
                        classNme="mt-3"
                        title={otherReason ? "Next" : "Remove"}
                        onClick={handleNextPressRemove}
                        isLoading={loading}
                    />
                </div>
            </Modal>

            <CommentModal
                open={commentModal}
                onModalClose={handleCommentModalClose}
                remarksValue={explainRemarks}
                setRemarksValue={setExplainRemarks}
                loading={loading}
                onNextPress={handleCommentSubmit}
            />

            <Modal
                classNames={{ modal: "commentModal" }}
                open={explainModal}
                onClose={handleExplainModalClose}
                showCloseIcon={false}
                center
                closeOnOverlayClick={false}
            >
                <FontAwesomeIcon
                    className="back-icon"
                    onClick={handleExplainModalClose}
                    icon={faAngleLeft}
                />
                <div className="d-flex flex-column align-items-center justify-content-center comment__modal ">
                    <h4 className="pb-3">Please tell explain us why</h4>
                    <AvForm className="text-center" onValidSubmit={handleExplainRemarksSubmit}>
                        {/* <textarea
                            className="mt-4 mb-5"
                            rows="12"
                            cols="60"
                            placeholder="Please tell us your remarks..."
                            value={explainRemarks}
                            onChange={(e) => setExplainRemarks(e.target.value)}
                        ></textarea> */}
                        <TextInput
                            type="textarea"
                            placeholder="Please tell us your remarks..."
                            required
                            name="explainRemarks"
                            rows="12"
                            cols="60"
                            value={explainRemarks}
                            onChange={(e) => setExplainRemarks(e.target.value)}
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: "Description is required."
                                },
                                minLength: {
                                    value: 1,
                                    errorMessage: "Please enter a message"
                                }
                            }}
                            maxlength="300"
                            className="mt-4 mb-5"
                        />
                        <Button
                            title="Next"
                            onClick={handleExplainRemarksSubmit}
                            isLoading={loading}
                            disabled={loading}
                        />
                    </AvForm>
                </div>
            </Modal>

            <ConfirmSuspension
                open={confirmSuspensionModal}
                onModalClose={handleConfirmSuspensionModalClose}
                selectedUser={selectedUser}
                suspensionDays={suspensionDays}
                loading={loading}
                onYesPress={handleSuspendUser}
            />

            <Modal
                classNames={{ modal: "CustomModal" }}
                open={resolveModal}
                onClose={handleResolveModalClose}
                showCloseIcon={false}
                center
            >
                <div className="custom-modal d-flex flex-column align-items-center justify-content-center h-100">
                    <p>
                        {type === ReportTypes.WARNING
                            ? `${selectedUser?.Name} has been warned`
                            : type === ReportTypes.SUSPEND
                            ? `${selectedUser?.Name} has been suspended`
                            : type === ReportTypes.REMOVE
                            ? `${selectedUser?.Name} has been removed`
                            : "The report has been resolved"}
                    </p>
                    <button className="custom-btn" onClick={handleOkayPress}>
                        Ok
                    </button>
                </div>
            </Modal>

            <Modal
                classNames={{ modal: "unfoundmodal" }}
                open={unfoundModal}
                onClose={handleUnfoundModalClose}
                showCloseIcon={false}
                center
                closeOnOverlayClick={false}
            >
                <div className="d-flex flex-column justify-content-space-between align-items-center confirmsuspend h-50">
                    <p className="mt-4">Are you sure this report is unfounded?</p>

                    <div className="d-flex mt-5">
                        <Button title="Yes" onClick={handleUnfoundReport} />
                        <Button title="No" className="ml-5" onClick={handleUnfoundModalClose} />
                    </div>
                </div>
            </Modal>
        </>
    );
};

const mapDispatchToProps = {
    handleReportAction
};

export default connect(null, mapDispatchToProps)(WarningBox);
