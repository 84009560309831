import { UserStatus } from "../../constants";
import { fetchUtil } from "../../utils/fetchUtil";
import { updateDeviceId } from "./";
import { persistor } from "../../store";

export const VERIFICATION_FAILED = "VERIFICATION_FAILED";
export const ME_SUCCESS = "ME_SUCCESS";
export const ME_ERROR = "ME_ERROR";
export const ME_REQUEST = "ME_REQUEST";
export const IS_USER_VERIFIED = "IS_USER_VERIFIED";
export const UPDATE_USER = "UPDATE_USER";
export const SIGN_OUT = "SIGN_OUT";

export const SET_TOKEN = "SET_TOKEN";

export const SETUP_ACCOUNT_SUCCESS = "SETUP_ACCOUNT_SUCCESS";

export const login = (bodyData) => (dispatch, getState) => {
    let deviceUUID = getState().deviceId.deviceUUID;
    const body = JSON.stringify({ ...bodyData, ...(deviceUUID && { DeviceUUID: deviceUUID }) });

    return fetchUtil({
        url: "/user/login",
        method: "POST",
        body
    })
        .then(async (res) => {
            dispatch({ type: SET_TOKEN, token: res.Data.Token });
            await dispatch(getMe(res.Data.Token));
            dispatch(updateDeviceId(res.Data.DeviceUUID));
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const verifySignupToken = (payload) => (dispatch, getState) => {
    let body = JSON.stringify(payload);

    return fetchUtil({
        url: "/user/matchmaker/invite-verification",
        method: "POST",
        body
    })
        .then(async (res) => {
            dispatch({ type: ME_SUCCESS, payload: res.Data });
            dispatch({ type: SET_TOKEN, token: res.Data.Token });
            dispatch(updateDeviceId(res.Data.DeviceUUID));
            return Promise.resolve(res);
        })
        .catch((err) => {
            dispatch({ type: VERIFICATION_FAILED });
            return Promise.reject(err);
        });
};

export const getMe = (token) => (dispatch, getState) => {
    dispatch({ type: ME_REQUEST });
    return fetchUtil({
        url: "/user/me",
        token: token || getState().auth.user.Token
    })
        .then((res) => {
            localStorage.isAuthenticated = true;
            dispatch({ type: ME_SUCCESS, payload: res.Data });
            return Promise.resolve(res.Data);
        })
        .catch((err) => {
            dispatch({ type: ME_ERROR });
            return Promise.reject(err);
        });
};

export const setupAccount = (payload) => (dispatch, getState) => {
    let token = getState().auth.user.Token;
    let body = JSON.stringify(payload);

    return fetchUtil({
        url: "/user/matchmaker/setup-account",
        method: "POST",
        body,
        token
    })
        .then(async (res) => {
            dispatch({ type: SETUP_ACCOUNT_SUCCESS, status: UserStatus.COMPLETING_PROFILE });
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const createProfile = (payload) => (dispatch, getState) => {
    let token = getState().auth.user.Token;
    let body = JSON.stringify(payload);

    return fetchUtil({
        url: "/user/matchmaker/create-profile",
        method: "POST",
        body,
        token
    })
        .then(async (res) => {
            dispatch({ type: SETUP_ACCOUNT_SUCCESS, status: UserStatus.VERIFIED });
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const mediaService = (file) => (dispatch, getState) => {
    const token = getState().auth.user.Token;

    let imageFile = new FormData();
    imageFile.append("file", file);
    return fetchUtil({
        image: true,
        body: imageFile,
        method: "POST",
        url: "/media/upload",
        token: token
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const logout = () => (dispatch, getState) => {
    const token = getState().auth.user.Token;

    return fetchUtil({
        url: "/user/logout",
        method: "POST",
        token
    })
        .then((res) => {
            persistor.purge().then(() => {
                dispatch({ type: SIGN_OUT });
                localStorage.isAuthenticated = false;
                // localStorage.clear();
            });
            return Promise.resolve(res.Data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const updateFCMToken = (data) => (dispatch, getState) => {
    const token = getState().auth.user.Token;
    return fetchUtil({
        url: "/user/device-id",
        method: "POST",
        body: JSON.stringify(data),
        token
    })
        .then(async (res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};
