import React, { useState } from "react";
import { Progress } from "reactstrap";

import "./ActiveChat.scss";

import { ImageTypes } from "../../constants";
import { toaster } from "../../utils/toasterUtil";
import {
    toBase64,
    initializeMediaUtil,
    getMediaStream,
    uploadOnS3,
    finalizeMediaUtil
} from "../../utils/mediaService";

import Triangle from "../../assets/images/Triangle.png";
import ImagePlaceholder from "../../assets/images/img_placeholder.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCross, faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { canFileUploaded } from "../../utils/commonUtil";

import ChatSendIcon from "../../components/SVGICON/chat-send-icon";

const ChatInputMessage = (props) => {
    const { onMessageSent } = props;
    const [messageContent, setMessageContent] = useState("");
    const [file, setFile] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [progress, setProgress] = useState(0);

    const handleSubmit = async (e) => {
        // e.preventDefault();
        if ((messageContent && messageContent.trim().length !== 0) || file) {
            let media;
            if (file) {
                media = await uploadMedia(file);
            }
            props.onMessageSent({ messageContent, media });
            setMessageContent("");
            setFile(null);
            setImagePreview(null);
        }
    };

    const handleFileChange = (e) => {
        let file = e.target.files[0];
        if (!file) {
            return;
        }
        let fileType = file.type.split("/").pop().toUpperCase();

        if (!ImageTypes.includes(fileType)) {
            toaster({ title: "File Type not supported", type: "danger" });
            return;
        } else if (!canFileUploaded(file)) {
            toaster({ title: "File size too large. Max 8mb image allowed", type: "danger" });
            return;
        }
        toBase64(file, (base64) => {
            setFile(file);
            setImagePreview(base64);
        });
        e.target.value = null;
    };

    const uploadMedia = async (file) => {
        setProgress(1);
        let credentialsRequest = initializeMediaUtil(file);
        let mediaStreamRequest = getMediaStream(file);
        const [credentials, mediaStream] = await Promise.all([
            credentialsRequest,
            mediaStreamRequest
        ]);
        await uploadOnS3(mediaStream, credentials.Data, (progress) => {
            setProgress(progress);
        });
        let finalizeRes = await finalizeMediaUtil(credentials?.Data?.MediaId);
        setProgress(0);

        return finalizeRes?.Data?.Media;
    };

    const handleInputKeyDown = (e) => {
        if (e.keyCode === 13 && !e.shiftKey) {
            e.preventDefault();
            handleSubmit();
        }
    };

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="d-flex position-relative align-items-center px-4 active-chat__footer">
                    {/* <input
                        value={messageContent}
                        onChange={(e) => setMessageContent(e.target.value)}
                        className="active-chat__input w-100"
                        type="text"
                        placeholder="Enter message..."
                    /> */}
                    <textarea
                        value={messageContent}
                        // onChange={(e) => setMessageContent(e.target.value)}
                        onChange={(e) => {
                            setMessageContent(e.target.value);
                            e.target.style.height = "38px";
                            e.target.style.height = `${e.target.scrollHeight}px`;
                        }}
                        onKeyDown={handleInputKeyDown}
                        className="active-chat__input w-100 fancy-scroll"
                        placeholder="Enter message..."
                        style={{ maxHeight: "50px" }}
                    ></textarea>
                    <div className="position-absolute active-chat__file">
                        <img src={ImagePlaceholder} alt="send" className="img-fluid" />
                        <input
                            onChange={handleFileChange}
                            accept="image/*"
                            type="file"
                            className="w-100 h-100"
                        />
                    </div>

                    {/* <img
                        onClick={handleSubmit}
                        className="send-message"
                        src={Triangle}
                        alt="send"
                    /> */}
                    <ChatSendIcon onClick={handleSubmit} className="send-message" />
                    {file && imagePreview && (
                        <div className="chat-image-preview-container">
                            <div className="img-container container-height position-relative mb-0">
                                <div className="img-container__div1212">
                                    <img
                                        className={`w-100 h-100 ${progress > 0 ? "blur" : ""}`}
                                        src={imagePreview}
                                        alt="img"
                                    />
                                </div>
                                {progress > 0 && (
                                    <div className="chat-image-progress">
                                        <Progress color="primary" value={progress}>
                                            {progress}%
                                        </Progress>
                                    </div>
                                )}
                                {!progress && (
                                    <div
                                        className="position-absolute remove-btn hide"
                                        onClick={() => {
                                            setImagePreview(null);
                                            setFile(null);
                                        }}
                                    >
                                        {/* <img className="font-size-24" src={Triangle} alt="send" /> */}
                                        <FontAwesomeIcon
                                            className="text-danger"
                                            icon={faWindowClose}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </form>
        </>
    );
};

export default ChatInputMessage;
