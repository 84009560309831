import React, { useState } from "react";
import { Modal } from "react-responsive-modal";

import "./ChatHeader.scss";
import "react-responsive-modal/styles.css";

import Button from "../../components/Button/Button";
import ChatProfile from "../ChatProfile/ChatProfile";

const ReportAbuseModal = (props) => {
    const {
        participants = [],
        open,
        onModalClose = () => {},
        onUserClick = () => {},
        onNextPress = () => {},
        selectedUser = null
    } = props;
    const [isError, setIsError] = useState(false);

    const handleNextPress = () => {
        if (!selectedUser) {
            setIsError(true);
            return;
        }
        onNextPress();
    };

    const handleOnUserClick = (data) => {
        setIsError(false);
        onUserClick(data);
    };

    return (
        <Modal
            classNames={{ modal: "previousModal" }}
            open={open}
            onClose={() => {
                setIsError(false);
                onModalClose();
            }}
            showCloseIcon={true}
            center
        >
            <div className="d-flex flex-column align-items-center justify-content-center perivious__modal ">
                <h4 className="pb-3">Chat Info</h4>
                <div className="d-flex pt-4">
                    {participants.map((item, index) => {
                        return (
                            <ChatProfile
                                key={index}
                                exit={true}
                                picture={item.MediaPath}
                                data={item}
                                classes="pl-5"
                                info={false}
                                onUserClick={handleOnUserClick}
                                selectedUser={selectedUser}
                            />
                        );
                    })}
                </div>

                {isError && (
                    <div className="user-error-container">
                        <p>Please select user</p>
                    </div>
                )}

                <div className="d-flex flex-column flex-md-row mt-4">
                    <Button title="Next" onClick={handleNextPress} />
                </div>
            </div>
        </Modal>
    );
};

export default ReportAbuseModal;
