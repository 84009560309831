import React from "react";
import { TooltipBody, TooltipContent } from "./TooltipDesignMain.styles";
import TooltipDesignSecond from "./TooltipDesignSecond";
import TooltipDesign from "./TooltipDesign";
import TooltipCustomCard from "./TooltipCustomCard";
import toolTipConstant from "./constant";
import dummy from "../../../../assets/images/verify1.png";
import love from "../../../../assets/images/Love.png";
import users from "../../../../assets/images/users.png";
import chattour from "../../../../assets/images/chattour.png";
import logouttour from "../../../../assets/images/logouttour.png";
import notificationtour from "../../../../assets/images/notificationtour.png";
import perivioustour from "../../../../assets/images/perivioustour.png";
import settingstour from "../../../../assets/images/settingstour.png";
import reporttour from "../../../../assets/images/reporttour.png";
import UserGuide from "../../../../assets/images/UserGuide.png";
import verifyguide from "../../../../assets/images/verifyguide.png";
import VerifyusersIcon from "../../../../assets/images/VerifyusersIcon.png";
import verifydoticon from "../../../../assets/images/verifydiricon.png";
import "./Tour.scss";

const TooltipDesignMain = ({
    backProps,
    primaryProps,
    tooltipProps,
    index,
    continuous,
    cardMaxWidth = "",
    designtype,
    cardPadding,
    cardBlur,
    skipProps,
    onCancel = () => {},
    isCloseButton
}) => {
    return (
        <TooltipBody {...tooltipProps} maxWidth={cardMaxWidth} cardBlur={cardBlur}>
            <TooltipContent cardPadding={cardPadding}>
                {designtype == toolTipConstant.step1 && (
                    <TooltipDesignSecond
                        title="Welcome to Halal Connections!"
                        description="Let us explain all the features we have designed for you."
                        isImg={love}
                        imgWidth="7.625rem"
                        primaryProps={primaryProps}
                        skipProps={skipProps}
                        isSkipButton={true}
                        isNextButton={true}
                        titleClass="main-title mb-3"
                        onCancel={onCancel}
                    />
                )}

                {designtype == toolTipConstant.step2 && (
                    <TooltipDesign
                        title="Users"
                        description="In this area you can find the profile of the users you are assisting in a list like this one.
                        When you click the profile picture, you will find a detailed description of the user: "
                        // childrenCard={<TooltipCustomCard  />}
                        RightImage={users}
                        primaryProps={primaryProps}
                        backProps={backProps}
                        footerLayoutTwo={true}
                        middleContentWidth="85%"
                        stepTwoAlignSelf="center"
                        isSkipButton={true}
                        skipProps={skipProps}
                        detail={true}
                        primaryImg={UserGuide}
                        onCancel={onCancel}
                    />
                )}
                {designtype == toolTipConstant.step3 && (
                    <TooltipDesign
                        title="Users to verify"
                        description="In this area you will find the users that need to be verified to acces the app "
                        // childrenCard={<TooltipCustomCard  />}
                        RightImage={VerifyusersIcon}
                        primaryProps={primaryProps}
                        backProps={backProps}
                        footerLayoutTwo={true}
                        middleContentWidth="85%"
                        stepTwoAlignSelf="center"
                        isSkipButton={true}
                        skipProps={skipProps}
                        detail={true}
                        primaryImg={verifyguide}
                        onCancel={onCancel}
                    />
                )}
                {designtype == toolTipConstant.step4 && (
                    <TooltipDesign
                        title="Users to verify "
                        description="Every time you see a red dot in this icon means you have a new user to verify "
                        // childrenCard={<TooltipCustomCard  />}
                        RightImage={verifydoticon}
                        primaryProps={primaryProps}
                        backProps={backProps}
                        footerLayoutTwo={true}
                        middleContentWidth="85%"
                        stepTwoAlignSelf="center"
                        isSkipButton={true}
                        skipProps={skipProps}
                        detail={true}
                        primaryImg={verifyguide}
                        onCancel={onCancel}
                    />
                )}
                {designtype == toolTipConstant.step5 && (
                    <TooltipDesign
                        title="Active Chats"
                        description="In this area you can find all the chats you are part of."
                        RightImage={chattour}
                        primaryProps={primaryProps}
                        backProps={backProps}
                        detail={true}
                        isSkipButton={true}
                        skipProps={skipProps}
                        detail={true}
                        onCancel={onCancel}
                        // footerLayoutTwo={true}
                    />
                )}
                {designtype == toolTipConstant.step6 && (
                    <TooltipDesign
                        title="Reports"
                        description="In this area you can notify us if you are expreriencing any abuse or wrong behaviour."
                        RightImage={reporttour}
                        primaryProps={primaryProps}
                        backProps={backProps}
                        isSkipButton={true}
                        skipProps={skipProps}
                        onCancel={onCancel}
                    />
                )}
                {designtype == toolTipConstant.step7 && (
                    <TooltipDesign
                        title="Previous Chats "
                        description="In this area you can find all the chats you were part of."
                        RightImage={perivioustour}
                        primaryProps={primaryProps}
                        backProps={backProps}
                        isSkipButton={true}
                        skipProps={skipProps}
                        onCancel={onCancel}
                    />
                )}
                {designtype == toolTipConstant.step8 && (
                    <TooltipDesign
                        title="Settings"
                        description="In this area you will find, Account, Notifications, Help and About Us"
                        RightImage={settingstour}
                        leftIconPosition="flex-end"
                        primaryProps={primaryProps}
                        backProps={backProps}
                        isSkipButton={true}
                        onCancel={onCancel}
                        skipProps={skipProps}
                    />
                )}
                {designtype == toolTipConstant.step9 && (
                    <TooltipDesign
                        title="Log out"
                        description="An easy way to leave the platform"
                        RightImage={logouttour}
                        leftIconPosition="flex-end"
                        primaryProps={primaryProps}
                        backProps={backProps}
                        isSkipButton={true}
                        skipProps={skipProps}
                        onCancel={onCancel}
                    />
                )}
                {designtype == toolTipConstant.step10 && (
                    <TooltipDesign
                        title="Notifications"
                        description="You will see a pink dot next to the bell 
                        everytime you have a new alert"
                        RightImage={notificationtour}
                        RightIcon="fas fa-chevron-up"
                        isLeftIcon={false}
                        primaryProps={primaryProps}
                        backProps={backProps}
                        isSkipButton={true}
                        skipProps={skipProps}
                        onCancel={onCancel}
                    />
                )}

                {designtype == toolTipConstant.step11 && (
                    <TooltipDesignSecond
                        title="You are ready to go!"
                        description="Now is time to create real connection for real people, good luck!"
                        isImg={love}
                        imgWidth="11.625rem"
                        primaryProps={primaryProps}
                        skipProps={skipProps}
                        isNextButton={false}
                        isCloseButton={true}
                        onCancel={onCancel}
                        titleClass="main-title mb-4"
                    />
                )}
            </TooltipContent>
        </TooltipBody>
    );
};

export default TooltipDesignMain;
