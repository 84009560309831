import { injectBaseConstantMethods } from "./BaseConstants";

const WorkingSituation = {
    UNEMPLOYED: 1,
    FULL_TIME: 2,
    PART_TIME: 3
};

const displayTextKeys = {
    [WorkingSituation.UNEMPLOYED]: "Unemployed",
    [WorkingSituation.FULL_TIME]: "Full Time",
    [WorkingSituation.PART_TIME]: "Part Time"
};

const labelClass = {
    [WorkingSituation.UNEMPLOYED]: "",
    [WorkingSituation.FULL_TIME]: "",
    [WorkingSituation.PART_TIME]: ""
};

export default injectBaseConstantMethods(WorkingSituation, displayTextKeys, labelClass);
