
import React, { Component } from 'react';
const CamerIcon = (props) => {
    return (
        <svg className={props.className} onClick={props.onClick} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 0C31 0 40 9 40 20C40 31 31 40 20 40C9 40 0 31 0 20C0 9 9 0 20 0Z" fill="#241762"/>
            <path d="M20.0996 25.5C22.5849 25.5 24.5996 23.4853 24.5996 21C24.5996 18.5147 22.5849 16.5 20.0996 16.5C17.6143 16.5 15.5996 18.5147 15.5996 21C15.5996 23.4853 17.6143 25.5 20.0996 25.5Z" fill="white"/>
            <path d="M16.8 10L14.8 12.2H11.3C10 12.2 9 13.2 9 14.5V27.9C9 29 10 30 11.2 30H29C30.2 30 31.2 29 31.2 27.8V14.5C31.2 13.3 30.2 12.3 29 12.3H25.5L23.5 10H16.8ZM20.1 26.7C17 26.7 14.5 24.2 14.5 21.1C14.5 18 17 15.5 20.1 15.5C23.2 15.5 25.7 18 25.7 21.1C25.7 24.2 23.2 26.7 20.1 26.7Z" fill="white"/>
        </svg>

    );
}
export default CamerIcon