import React, { Component } from "react";
import "./ToggleBtn.scss";
const ToggleBtn = (props) => {
    const { checked = false, value = null, onChange = () => {} } = props;

    return (
        <>
            <div className="notification-toggle pb-3">
                <label className="switch">
                    <input
                        type="checkbox"
                        checked={checked}
                        onChange={() => onChange(value)}
                    ></input>
                    <span className={checked ? "slider round" : " slider slider-off round"}></span>
                </label>
            </div>
        </>
    );
};
export default ToggleBtn;
