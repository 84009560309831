import React, { useState } from "react";

import "./ReasonModal.scss";
import "react-responsive-modal/styles.css";

import { Modal } from "react-responsive-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import Button from "../Button/Button";
import { RadioBoxItem } from "../RadioBoxItem";
import { ReportCauses } from "../../constants";

const ReasonModal = (props) => {
    let {
        open = false,
        onModalClose = () => {},
        selectedReason = "",
        onReasonSelect = () => {},
        onNextPress = () => {},
        loading = false
    } = props;
    const [isError, setIsError] = useState(false);

    const handleNextPress = () => {
        if (!selectedReason) {
            setIsError(true);
            return;
        }
        onNextPress();
    };

    const handleCheckbox = (reportType) => {
        setIsError(false);
        onReasonSelect(reportType);
    };

    return (
        <Modal
            classNames={{ modal: "reasonModal" }}
            open={open}
            onClose={onModalClose}
            showCloseIcon={false}
            center
            closeOnOverlayClick={false}
        >
            <FontAwesomeIcon
                className="back-icon"
                onClick={() => {
                    setIsError(false);
                    onModalClose();
                }}
                icon={faAngleLeft}
            />
            <div className="d-flex flex-column align-items-center justify-content-center reason__modal position-relative">
                <h4 className="pb-3">
                    Please choose at least one of the following reasons to submit a report:
                </h4>
                <div className="d-flex  pt-3 w-100">
                    <div className="pl-md-4 pr-md-4 pr-3">
                        <ul class="reason-checkox">
                            <RadioBoxItem
                                value={ReportCauses.SEXUAL_CONTENT}
                                label={ReportCauses.getDisplayTextKey(ReportCauses.SEXUAL_CONTENT)}
                                checked={selectedReason === ReportCauses.SEXUAL_CONTENT}
                                onClick={() => handleCheckbox(ReportCauses.SEXUAL_CONTENT)}
                            />
                            <RadioBoxItem
                                value={ReportCauses.HARASSMENT}
                                label={ReportCauses.getDisplayTextKey(ReportCauses.HARASSMENT)}
                                checked={selectedReason === ReportCauses.HARASSMENT}
                                onClick={() => handleCheckbox(ReportCauses.HARASSMENT)}
                            />
                            <RadioBoxItem
                                value={ReportCauses.VIOLENCE}
                                label={ReportCauses.getDisplayTextKey(ReportCauses.VIOLENCE)}
                                checked={selectedReason === ReportCauses.VIOLENCE}
                                onClick={() => handleCheckbox(ReportCauses.VIOLENCE)}
                            />
                            <RadioBoxItem
                                value={ReportCauses.BREAKING_PRIVACY_POLICY}
                                label={ReportCauses.getDisplayTextKey(
                                    ReportCauses.BREAKING_PRIVACY_POLICY
                                )}
                                checked={selectedReason === ReportCauses.BREAKING_PRIVACY_POLICY}
                                onClick={() => handleCheckbox(ReportCauses.BREAKING_PRIVACY_POLICY)}
                            />
                            <RadioBoxItem
                                value={ReportCauses.BULLYING}
                                label={ReportCauses.getDisplayTextKey(ReportCauses.BULLYING)}
                                checked={selectedReason === ReportCauses.BULLYING}
                                onClick={() => handleCheckbox(ReportCauses.BULLYING)}
                            />
                        </ul>
                    </div>
                    <ul class="reason-checkox">
                        <RadioBoxItem
                            value={ReportCauses.NO_POLYGAMY}
                            label={ReportCauses.getDisplayTextKey(ReportCauses.NO_POLYGAMY)}
                            checked={selectedReason === ReportCauses.NO_POLYGAMY}
                            onClick={() => handleCheckbox(ReportCauses.NO_POLYGAMY)}
                        />
                        <RadioBoxItem
                            value={ReportCauses.IMPERSONATING_TO_BE_SOMEONE}
                            label={ReportCauses.getDisplayTextKey(
                                ReportCauses.IMPERSONATING_TO_BE_SOMEONE
                            )}
                            checked={selectedReason === ReportCauses.IMPERSONATING_TO_BE_SOMEONE}
                            onClick={() => handleCheckbox(ReportCauses.IMPERSONATING_TO_BE_SOMEONE)}
                        />
                        <RadioBoxItem
                            value={ReportCauses.INAPPROPRIATE_LANGUAGE}
                            label={ReportCauses.getDisplayTextKey(
                                ReportCauses.INAPPROPRIATE_LANGUAGE
                            )}
                            checked={selectedReason === ReportCauses.INAPPROPRIATE_LANGUAGE}
                            onClick={() => handleCheckbox(ReportCauses.INAPPROPRIATE_LANGUAGE)}
                        />
                        <RadioBoxItem
                            value={ReportCauses.DISCRIMINATION}
                            label={ReportCauses.getDisplayTextKey(ReportCauses.DISCRIMINATION)}
                            checked={selectedReason === ReportCauses.DISCRIMINATION}
                            onClick={() => handleCheckbox(ReportCauses.DISCRIMINATION)}
                        />
                    </ul>
                </div>
                {isError && (
                    <div className="error-container position-absolute">
                        <p>Please select reason</p>
                    </div>
                )}
                <Button className="mt-5" title="Next" onClick={handleNextPress} loading={loading} />
            </div>
        </Modal>
    );
};
export default ReasonModal;
