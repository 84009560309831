import {
    REPORT_LISTING_REQUEST,
    REPORT_LISTING_SUCCESS,
    REPORT_LISTING_FAILURE
} from "../actions/reportActions";

const initialState = {
    list: [],
    fetching: false,
    totalReports: 0
};

export default (state = initialState, action) => {
    switch (action.type) {
        case REPORT_LISTING_REQUEST:
            return {
                ...state,
                list: (() => {
                    if (!action.Page || action.Page === 1) {
                        return [];
                    }

                    return state.list;
                })(),
                fetching: true
            };

        case REPORT_LISTING_SUCCESS:
            return {
                ...state,
                list: [...state.list, ...action.payload],
                fetching: false,
                totalReports: action.totalReports
            };

        case REPORT_LISTING_FAILURE:
            return {
                ...state,
                list: [],
                fetching: false,
                totalReports: 0
            };

        default:
            return state;
    }
};
