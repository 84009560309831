import { appendQueryParams } from "../../utils/urlUtil";
import { fetchUtil } from "../../utils/fetchUtil";
import { NotificationStatus } from "../../constants";

export const NOTIFICATIONS_LISTING_REQUEST = "NOTIFICATIONS_LISTING_REQUEST ";
export const NOTIFICATIONS_LISTING_SUCCESS = "NOTIFICATIONS_LISTING_SUCCESS";
export const NOTIFICATIONS_LISTING_FAILURE = "NOTIFICATIONS_LISTING_FAILURE ";
export const NOTIFICATIONS_STATUS_UPDATE = "NOTIFICATIONS_STATUS_UPDATE";

export const getNotificationListing = (params = {}) => (dispatch, getState) => {
    const token = getState().auth.user.Token;

    dispatch({
        type: NOTIFICATIONS_LISTING_REQUEST,
        Page: params.Page
    });

    return fetchUtil({
        url: appendQueryParams("/notification/notifications", { ...params }),
        token
    })
        .then((res) => {
            let notificationsIds = res.Data.Notifications.filter(
                (item) => item.ReadStatus === NotificationStatus.UNREAD
            ).map((item) => item.Id);

            dispatch({
                type: NOTIFICATIONS_LISTING_SUCCESS,
                payload: res.Data.Notifications,
                totalNotifications: res.Data.Count,
                unreadCount: res.Data.UnreadCount
            });

            if (Boolean(notificationsIds.length)) {
                dispatch(readNotifications({ NotificationIds: notificationsIds }));
            }

            return Promise.resolve(res);
        })
        .catch((err) => {
            dispatch({
                type: NOTIFICATIONS_LISTING_FAILURE
            });
            return Promise.reject(err);
        });
};

export const readNotifications = (payload) => (dispatch, getState) => {
    const token = getState().auth.user.Token;

    return fetchUtil({
        url: "/notification/read-notifications",
        token,
        body: JSON.stringify(payload),
        method: "POST"
    })
        .then((res) => {
            dispatch({
                type: NOTIFICATIONS_STATUS_UPDATE,
                unreadCount: res.Data.UnreadCount,
                readIds: payload.NotificationIds
            });
        })
        .catch((err) => {});
};

export const getNotificationSettings = (params = {}) => (dispatch, getState) => {
    const token = getState().auth.user.Token;

    return fetchUtil({
        url: appendQueryParams("/user/settings", { ...params }),
        token
    })
        .then((res) => {
            return Promise.resolve(res.Data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const updateNotificationSetting = (payload) => (dispatch, getState) => {
    const token = getState().auth.user.Token;

    return fetchUtil({
        url: "/user/update-settings",
        body: JSON.stringify(payload),
        token,
        method: "POST"
    })
        .then((res) => {
            return Promise.resolve(res.Data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};
