import {
    ME_SUCCESS,
    SET_TOKEN,
    IS_USER_VERIFIED,
    UPDATE_USER,
    VERIFICATION_FAILED,
    SETUP_ACCOUNT_SUCCESS
} from "../actions";
import { UserStatus } from "../../constants";

const initialState = {
    isAuthenticated: false,
    isVerified: false,
    user: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ME_SUCCESS:
            const isUserVerify = action.payload.User.Status === UserStatus.VERIFIED;
            return {
                ...state,
                isAuthenticated: true,
                isVerified: isUserVerify,
                user: {
                    ...state.user,
                    ...action.payload.User
                }
            };
        case SET_TOKEN:
            return {
                ...state,
                user: {
                    ...state.user,
                    Token: action.token
                }
            };
        case IS_USER_VERIFIED:
            return {
                ...state,
                isVerified: true
            };
        case UPDATE_USER:
            return {
                ...state,
                user: { ...state.user, ...action.payload?.User }
            };
        case VERIFICATION_FAILED:
            return {
                ...initialState
            };
        case SETUP_ACCOUNT_SUCCESS:
            return {
                ...state,
                user: {
                    ...state.user,
                    Status: action.status
                }
            };
        default:
            return state;
    }
};
