import { fetchUtil } from "../../utils/fetchUtil";
import { appendQueryParams } from "../../utils/urlUtil";
import Config from "../../Config";

export const USER_LISTING_REQUEST = "USER_LISTING_REQUEST";
export const USER_LISTING_SUCCESS = "USER_LISTING_SUCCESS";
export const USER_LISTING_FAILURE = "USER_LISTING_FAILURE";

export const getUsersListing = (params = {}, abortSignal = null) => (dispatch, getState) => {
    const token = getState().auth.user.Token;

    dispatch({ type: USER_LISTING_REQUEST, Page: params.Page });

    return fetchUtil({
        url: appendQueryParams("/user/list", { Limit: Config.LIMIT, ...params }),
        token,
        abortSignal
    })
        .then((res) => {
            if (res && res.Data) {
                dispatch({
                    type: USER_LISTING_SUCCESS,
                    payload: res.Data.Users,
                    count: res.Data.Count
                });
                return Promise.resolve(res.Data);
            }
            return Promise.reject(false);
        })
        .catch((err) => {
            if (err.name === "AbortError") {
                return;
            }
            dispatch({ type: USER_LISTING_FAILURE });
            return Promise.reject(err);
        });
};

export const getUserDetails = (id) => (dispatch, getState) => {
    const token = getState().auth.user.Token;

    return fetchUtil({
        url: appendQueryParams("/user/details", { UserId: id }),
        token
    })
        .then((res) => {
            return Promise.resolve(res.Data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const getUserDetailsList = (payload) => (dispatch, getState) => {
    const token = getState().auth.user.Token;

    const { Matches, Count } = payload;

    let params = {
        UsersIds: Matches.map((item) => item.UserId)
    };

    return fetchUtil({
        url: appendQueryParams("/user/users-details", { ...params }),
        token
    })
        .then((res) => {
            if (res.Data && res.Data.Users) {
                return Promise.resolve({
                    matches: Matches,
                    matchDetails: res.Data.Users,
                    total: Count
                });
            }
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};
