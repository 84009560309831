export default {
    USER_MESSAGE: 1,
    MEDIA_MESSAGE: 2,
    CHAT_ACCEPTED: 11,
    CHAT_REJECTED: 12,
    GUARDIAN_ADDED: 21,
    GUARDIAN_REMOVED: 22,
    USER_LEFT: 31,
    GUARDIAN_LEFT: 32
};
