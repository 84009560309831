import React from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";

import "./Notifications.scss";

//import danger from "../../assets/images/danger.png";
import { NotificationTypes } from "../../constants";
import imageUpload from "../../assets/images/imageupload.png";
import { getRoute, APP_ROUTES } from "../../helpers/routeHelpers";

const NotificationItem = (props) => {
    const { data } = props;

    const history = useHistory();

    const handleClick = () => {
        const { Type } = data;

        switch (Type) {
            case NotificationTypes.NEW_USER_JOINS_APP:
                history.push(getRoute(APP_ROUTES.USER_DETAIL, { id: data?.Initiator?.Id }));
                break;

            case NotificationTypes.PREFERENCES_COMPLETED:
                history.push(getRoute(APP_ROUTES.USER_DETAIL, { id: data?.Initiator?.Id }));
                break;

            case NotificationTypes.USER_UPDATED_PROFILE:
                history.push(getRoute(APP_ROUTES.USER_DETAIL, { id: data?.Initiator?.Id }));
                break;

            case NotificationTypes.RECEIVE_REFERRALS:
                history.push(getRoute(APP_ROUTES.USER_DETAIL, { id: data?.Initiator?.Id }));
                break;

            case NotificationTypes.NEW_MESSAGE_TO_MATCH_MAKER_INDIVIDUAL:
                history.push(
                    getRoute(APP_ROUTES.ACTIVE_CHAT, { id: data.Meta.ChatId, tabname: "single" })
                );
                break;

            case NotificationTypes.NEW_MESSAGE_TO_MATCH_MAKER_GROUP:
                history.push(
                    getRoute(APP_ROUTES.ACTIVE_CHAT, { id: data.Meta.ChatId, tabname: "group" })
                );
                break;

            case NotificationTypes.MATCH_ACCEPTED:
                history.push(getRoute(APP_ROUTES.USER_DETAIL, { id: data?.Initiator?.Id }));
                break;

            default:
                break;
        }
    };

    return (
        <div
            className="d-flex align-items-start justify-content-between notification-card__wrapper fancy-scroll pl-4"
            onClick={handleClick}
        >
            <div className="d-flex align-items-center">
                <img
                    className="notification-card__img"
                    src={data?.Initiator?.MediaUrl || imageUpload}
                />
                <div className="notification-card__info pl-5">
                    <h5>{data?.Initiator?.Name || "-"}</h5>
                    <p className="py-2">{data?.Description}</p>
                    <h6>{moment(data.CreatedAt).fromNow()}</h6>
                </div>
            </div>
            {data.icon ? (
                <img className="notification-card__icon" src={data.icon} />
            ) : (
                    <p className="info">{data.info}</p>
                )}
        </div>
    );
};

export default NotificationItem;
