import React, { useState, useEffect } from "react";
import PageTitle from "../../components/PageTitle/PageTitle";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Modal } from "react-responsive-modal";

import "./UserDetail.scss";
import "./MatchModal.scss";
import "./SuccessModal.scss";
import "react-responsive-modal/styles.css";

import { faTimes, faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { Button } from "../../components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FilterSelecter from "../../components/FilterSelecter/FilterSelecter";
import UserDetailCard from "./DetailCard";
import Loader from "../../components/Loader/Spinner";
import MatchCard from "./MatchCard";
import {
    getUserDetails,
    getQuickMatches,
    getManualMatches,
    sendReferral
} from "../../store/actions";

const initialStateFilters = {
    Personal: {
        Age: false,
        Children: false,
        CurrentLocation: false,
        MaritalStatus: false,
        Height: false,
        Relocate: false
    },
    Professional: {
        LevelOfEducation: false,
        WorkingPreference: false
    },
    Religion: {
        AlcoholConsumption: false,
        HalalPreferences: false,
        PrayerRoutine: false,
        Sect: false,
        SmokingHabits: false,
        MuslimConvert: false
    }
};

const MATCH_MODE = {
    QUICK: "Quick",
    MANUAL: "Manual"
};

const UserDetail = (props) => {
    const { getUserDetails, getQuickMatches, getManualMatches, sendReferral } = props;

    const [manualMatch, setManualMatch] = useState(false);
    const [selectedMatch, setSelectedMatch] = useState(null);
    const [matchesDetails, setMatchesDetails] = useState([]);
    const [currentUser, setCurrentUser] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [matchesLoading, setMatchesLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [confirmModal, setConfirmModal] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const [matchSuggestions, setMatchSuggestions] = useState([]);
    const [filters, setFilters] = useState(initialStateFilters);
    const [selectedTraits, setSelectedTraits] = useState([]);
    const [matchMode, setMatchMode] = useState(null);
    const [totalMatches, setTotalMatches] = useState(0);

    const { id } = useParams();

    useEffect(() => {
        setLoading(true);
        setCurrentUser(null);
        getUserDetails(id)
            .then((res) => {
                let ownTraits = res?.Preferences?.Personality?.Traits.map((item) => {
                    return {
                        ...item,
                        label: item,
                        value: false
                    };
                });
                setSelectedTraits(ownTraits);
                setCurrentUser(res);
            })
            .finally(() => {
                setLoading(false);
            });

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const getUserQuickMatches = (pageNo = 1) => {
        let params = {
            UserId: id,
            Page: pageNo,
            Column: "MatchPercentage",
            Direction: "DESC"
        };

        setMatchesLoading(true);
        setMatchMode(MATCH_MODE.QUICK);

        getQuickMatches(params)
            .then((res) => {
                const { matches, matchDetails, total } = res;

                handleMatchesReceived(matches, matchDetails, total, pageNo);
            })
            .catch((err) => {})
            .finally(() => {
                setMatchesLoading(false);
            });
    };

    const getUserManualMatches = (pageNo = 1) => {
        let payload = {
            UserId: id,
            Page: pageNo,
            Personal: filters.Personal,
            Professional: filters.Professional,
            Religion: filters.Religion,
            Sort: { Column: "MatchPercentage", Direction: "DESC" },
            Traits: selectedTraits.filter((item) => item.value === true).map((item) => item.label)
        };

        setMatchesLoading(true);
        setMatchMode(MATCH_MODE.MANUAL);

        getManualMatches(payload)
            .then((res) => {
                const { matches, matchDetails, total } = res;

                handleMatchesReceived(matches, matchDetails, total, pageNo);
            })
            .catch((err) => {})
            .finally(() => {
                setMatchesLoading(false);
            });
    };

    const setFiltersToDefault = () => {
        setFilters(initialStateFilters);

        let ownTraits = selectedTraits.map((item) => {
            return {
                ...item,
                value: false
            };
        });
        setSelectedTraits(ownTraits);
    };

    const handleFilterChange = (key, value, objKey) => {
        let obj = { ...filters, [objKey]: { ...filters[objKey], [key]: !value } };
        setFilters(obj);
    };

    const handleTraitsChange = (value, index) => {
        let array = selectedTraits.map((item, i) => {
            if (index === i) {
                return {
                    ...item,
                    value: !value
                };
            } else return item;
        });

        setSelectedTraits(array);
    };

    const handleMatchSelected = (obj) => {
        let currentSelected = matchesDetails.find((user) => user.UserId === obj.UserId);
        setSelectedMatch({ ...currentSelected, MatchPercentage: obj.MatchPercentage });
    };

    const handleSendReferral = () => {
        let payload = {
            UserId: currentUser.UserId,
            ReferenceId: selectedMatch.UserId,
            MatchPercentage: selectedMatch.MatchPercentage,
            OtherUserIds: []
        };

        setMatchesLoading(true);

        sendReferral(payload)
            .then((res) => {
                setSuccessModal(true);

                let filteredMatches = matchSuggestions.filter(
                    (matches) => matches.UserId !== selectedMatch.UserId
                );

                setMatchSuggestions(filteredMatches);
            })
            .catch((err) => {})
            .finally(() => {
                setMatchesLoading(false);
                setConfirmModal(false);
            });
    };

    const closeSuccessModal = () => {
        setSelectedMatch(null);
        setSuccessModal(false);
    };

    const onScrollToBottom = (event) => {
        const bottom =
            Math.floor(event.target.scrollHeight - event.target.scrollTop) ===
            event.target.clientHeight;

        if (bottom && matchSuggestions.length < totalMatches && !matchesLoading) {
            let pageNumber = page + 1;

            if (matchMode === MATCH_MODE.QUICK) {
                getUserQuickMatches(pageNumber);
            } else if (matchMode === MATCH_MODE.MANUAL) {
                getUserManualMatches(pageNumber);
            }
            setPage(page + 1);
        }
    };

    const handleMatchListingClose = () => {
        setMatchSuggestions([]);
        setMatchesDetails([]);
        setPage(1);
    };

    const handleMatchesReceived = (matches, matchDetails, total, pageNo) => {
        setTotalMatches(total);

        setMatchSuggestions((prev) => {
            if (pageNo == 1) {
                return matches;
            }

            return [...prev, ...matches];
        });

        setMatchesDetails((prev) => {
            if (pageNo == 1) {
                return matchDetails;
            }

            return [...prev, ...matchDetails];
        });

        setManualMatch(false);
        setFiltersToDefault();
    };

    return (
        <>
            <PageTitle link="/dashboard/user-listing" title="Back to Users" icon={true} />
            <div className="row d-flex justify-content-around">
                {isLoading ? (
                    <Loader />
                ) : (
                    !isLoading &&
                    !currentUser && (
                        <div className="d-flex flex-row justify-content-center">
                            <h5>User Not Found</h5>
                        </div>
                    )
                )}

                {currentUser && <UserDetailCard data={currentUser} />}

                {selectedMatch ? (
                    <UserDetailCard
                        match={true}
                        data={selectedMatch}
                        openConfirmModal={() => setConfirmModal(true)}
                        onBackPress={() => setSelectedMatch(null)}
                    />
                ) : Boolean(matchSuggestions.length) ? (
                    <div
                        className="pt-4 user-deatil__wrapper  match-manual fancy-scroll "
                        onScroll={onScrollToBottom}
                    >
                        <FontAwesomeIcon
                            onClick={handleMatchListingClose}
                            className="ml-3 back-icon"
                            icon={faAngleLeft}
                        />
                        <div className="row d-flex justify-content-evenly pt-4 ">
                            {Boolean(matchSuggestions) &&
                                matchSuggestions.map((obj, index) => (
                                    <MatchCard
                                        key={index}
                                        onClick={() => handleMatchSelected(obj)}
                                        percentage={true}
                                        data={obj}
                                    />
                                ))}

                            {matchesLoading && <Loader />}
                        </div>
                    </div>
                ) : (
                    !manualMatch &&
                    currentUser && (
                        <div className="user-deatil__wrapper d-flex align-items-center justify-content-center">
                            <Button
                                onClick={() => getUserQuickMatches()}
                                title="Quick Match"
                                isLoading={matchesLoading}
                                className="match-btn"
                            />
                            <Button
                                onClick={() => setManualMatch(true)}
                                className="ml-4 match-btn"
                                title="Find Manually"
                            />
                        </div>
                    )
                )}

                {manualMatch && (
                    <div className="user-deatil__wrapper match-filter fancy-scroll">
                        <div className="d-flex align-items-center justify-content-between">
                            <h3 className="text-center match__title pt-3 pb-4 pl-5">
                                Filters based on Preferences
                            </h3>
                            <FontAwesomeIcon
                                onClick={() => setManualMatch(false)}
                                className="cancel-icon mr-2"
                                icon={faTimes}
                            />
                        </div>
                        <div className="match-filter__selecter">
                            <p>Personal</p>
                            <ul className="filter-list">
                                {Object.keys(filters.Personal).map((item, index) => {
                                    return (
                                        <FilterSelecter
                                            key={index}
                                            label={item}
                                            onClick={() =>
                                                handleFilterChange(
                                                    item,
                                                    filters.Personal[item],
                                                    "Personal"
                                                )
                                            }
                                            value={filters.Personal[item]}
                                        />
                                    );
                                })}
                            </ul>
                        </div>
                        <div className="match-filter__selecter">
                            <p>Professional</p>
                            <ul className="filter-list">
                                {Object.keys(filters.Professional).map((item, index) => {
                                    return (
                                        <FilterSelecter
                                            key={index}
                                            label={item}
                                            onClick={() =>
                                                handleFilterChange(
                                                    item,
                                                    filters.Professional[item],
                                                    "Professional"
                                                )
                                            }
                                            value={filters.Professional[item]}
                                        />
                                    );
                                })}
                            </ul>
                        </div>
                        <div className="match-filter__selecter">
                            <p>Religion</p>
                            <ul className="filter-list">
                                {Object.keys(filters.Religion).map((item, index) => {
                                    return (
                                        <FilterSelecter
                                            key={index}
                                            label={item}
                                            onClick={() =>
                                                handleFilterChange(
                                                    item,
                                                    filters.Religion[item],
                                                    "Religion"
                                                )
                                            }
                                            value={filters.Religion[item]}
                                        />
                                    );
                                })}
                            </ul>
                        </div>
                        {Boolean(selectedTraits.length) && (
                            <div className="match-filter__selecter">
                                <p>Personality Traits</p>
                                <ul className="filter-list">
                                    {selectedTraits &&
                                        selectedTraits.map((item, index) => {
                                            return (
                                                <FilterSelecter
                                                    key={index}
                                                    label={item.label}
                                                    onClick={() =>
                                                        handleTraitsChange(item.value, index)
                                                    }
                                                    value={item.value}
                                                />
                                            );
                                        })}
                                </ul>
                            </div>
                        )}

                        <div className="d-flex justify-content-center my-4">
                            <Button
                                title="Find Match"
                                isLoading={matchesLoading}
                                onClick={() => getUserManualMatches()}
                            />
                        </div>
                    </div>
                )}
            </div>

            <Modal
                classNames={{ modal: "matchModal" }}
                open={confirmModal}
                onClose={() => setConfirmModal(false)}
                showCloseIcon={false}
                center
            >
                <div className="d-flex flex-column align-items-center justify-content-center match__modal h-100">
                    <p>
                        Are you sure you want to match
                        <br /> {currentUser?.Name} and {selectedMatch?.Name}
                    </p>
                    <div className="d-flex">
                        <Button
                            title="Yes"
                            onClick={handleSendReferral}
                            isLoading={matchesLoading}
                        />
                        <Button
                            className="ml-3"
                            title="No"
                            onClick={() => setConfirmModal(false)}
                        />
                    </div>
                </div>
            </Modal>
            <Modal
                classNames={{ modal: "CustomModal" }}
                open={successModal}
                onClose={closeSuccessModal}
                showCloseIcon={false}
                center
            >
                <div className="custom-modal d-flex flex-column align-items-center justify-content-center h-100">
                    <p>
                        {currentUser?.Name} and {selectedMatch?.Name} have been matched
                    </p>
                    <button className="custom-btn" onClick={closeSuccessModal}>
                        Ok
                    </button>
                </div>
            </Modal>
        </>
    );
};

const mapDispatchToProps = {
    getUserDetails,
    getQuickMatches,
    getManualMatches,
    sendReferral
};

export default connect(null, mapDispatchToProps)(UserDetail);
