import React, { useState } from "react";
import moment from "moment";
import Viewer from "react-viewer";

import "./Sender.scss";
import men2 from "../../assets/images/men2.png";

const Sender = (props) => {
    const { userImage = men2, data, fromReport = false } = props;
    const [imagePreview, setImagePreview] = useState(null);
    return (
        <>
            <div className={`d-flex align-items-end justify-content-end sender-chat`}>
                {props.message ? (
                    <div className={`sender-chat__content ${!fromReport && "margin-msg"}`}>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: props.message.replace(/[↵\n]/g, "<br/>")
                            }}
                        ></p>
                        <p className="caption-date">{moment(data?.SendDate).format("LT")}</p>
                    </div>
                ) : (
                    <div className={`sender-chat__image ${fromReport && "chat-img-container"}`}>
                        <figure>
                            <img
                                onClick={() => setImagePreview(data?.Attachments[0]?.Path)}
                                src={props.picture}
                                className={`${fromReport && "report-img"}`}
                            />
                            <figcaption className="caption">
                                {props.caption ? props.caption : null}
                                <p className="caption-date">
                                    {moment(data?.SendDate).format("LT")}
                                </p>
                            </figcaption>
                        </figure>
                    </div>
                )}
                <img className="sender-profile" src={userImage} alt="receiver" />
            </div>
            {Boolean(imagePreview) && (
                <Viewer
                    visible={Boolean(imagePreview)}
                    onClose={() => setImagePreview(null)}
                    images={[{ src: imagePreview, alt: "uploaded-attachment" }]}
                    rotatable={false}
                    changeable={false}
                    drag={false}
                    noToolbar
                    noFooter
                    noNavbar
                    zIndex={99999}
                />
            )}
        </>
    );
};
export default Sender;
