import React, { useState, useRef, useLayoutEffect } from "react";
import { Redirect } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import { Modal } from "react-responsive-modal";
import { useDispatch, connect } from "react-redux";
import parsePhoneNumber from "libphonenumber-js";
import ProfilePicture from "profile-picture";
import { AvForm } from "availity-reactstrap-validation";

import "./AccountSetup.scss";
import "profile-picture/build/ProfilePicture.css";
import "react-responsive-modal/styles.css";
import "react-phone-input-2/lib/style.css";

import { logo, upload, accountsetup } from "../../assets/images/index";
import { TextInput as InputField } from "../../components/TextInput";
import Button from "../../components/Button/Button";
import { convertBase64ToFileObj } from "../../utils/commonUtil";
import { createProfile, mediaService } from "../../store/actions";
import { UserStatus } from "../../constants";
import { APP_ROUTES } from "../../helpers/routeHelpers";
import { toaster } from "../../utils/toasterUtil";

let data = "";

const AccountSetup = (props) => {
    const { isAuthenticated, user } = props;
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [phoneIsFocused, setPhoneIsFocused] = useState(false);
    const [open, setOpen] = useState(false);
    const [accountSetup, setAccountSetup] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const dispatch = useDispatch();
    const profilePictureRef = useRef(null);
    const mediaRef = useRef(null);

    useLayoutEffect(() => {
        if (user?.Status === UserStatus.VERIFIED) {
            props.history.replace(APP_ROUTES.DASHBOARD);
            return;
        }
    }, []);

    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    const onSetup = () => {
        setAccountSetup(true);
    };

    const onChange = (evt) => {
        setName(evt.target.value);
    };

    const handleRedirectToDashboard = () => {
        return props.history.replace(APP_ROUTES.DASHBOARD);
    };

    const handleUpload = async () => {
        const profilePicture = profilePictureRef.current;

        if (!profilePicture.state.file) {
            return;
        }

        const imageAsDataURL = profilePicture.getImageAsDataUrl();

        data = imageAsDataURL;
        let cropedFile = await convertBase64ToFileObj(imageAsDataURL, profilePicture.state.file);
        mediaRef.current = cropedFile;
        onCloseModal();
    };

    const canSubmit = () => {
        let regex = new RegExp(/^[a-zA-Z ]+$/);

        if (!regex.test(name)) {
            setErrors({ ...errors, name: true });
            return;
        }

        return true;
    };

    const handleCreateProfile = async () => {
        setErrors({});
        if (!canSubmit()) {
            return;
        }

        let phoneNumber = parsePhoneNumber(`+${phone}`);
        let validPhone = phoneNumber ? phoneNumber.isValid() : false;

        if (!validPhone) {
            setErrors({ phone: "Please enter a valid phone number" });
            return;
        }
        if (!mediaRef.current) {
            toaster({ title: "Please Select A Profile Picture", type: "danger" });
            return;
        }
        setIsLoading(true);
        try {
            let media = await dispatch(mediaService(mediaRef.current));
            let payload = {
                FullName: name,
                Phone: {
                    Extension: "+" + phoneNumber?.countryCallingCode,
                    Number: phoneNumber?.nationalNumber
                },
                MediaId: media?.Data?.Id
            };
            await dispatch(createProfile(payload));
            setIsLoading(false);
            onSetup();
        } catch (err) {
            setIsLoading(false);
        }
    };

    if (!isAuthenticated) {
        return <Redirect to={APP_ROUTES.LOGIN} />;
    }

    return (
        <>
            <div className="setup">
                <img className="auth-logo" src={logo} alt="logo" />
                {!accountSetup ? (
                    <div className="setup__upload">
                        <img onClick={onOpenModal} className="mb-3" src={data || upload} />

                        <AvForm onValidSubmit={handleCreateProfile}>
                            <InputField
                                type="name"
                                placeholder="Name"
                                name="email"
                                classes="hc-text-input-2"
                                onChange={onChange}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: "Please fill in the required fields"
                                    },
                                    pattern: {
                                        value: "/^[a-zA-Z ]+$/",
                                        errorMessage: `Please enter a valid full name`
                                    }
                                }}
                            />

                            <PhoneInput
                                country={"gb"}
                                autoFormat={true}
                                onFocus={() => setPhoneIsFocused(true)}
                                onBlur={() => setPhoneIsFocused(false)}
                                value={phone}
                                inputStyle={
                                    Boolean(phoneIsFocused) && errors.phone
                                        ? {
                                              boxShadow: "0 0 0 0.2rem rgb(220 53 69 / 25%)"
                                          }
                                        : {}
                                }
                                onChange={(phone) => setPhone(phone)}
                                countryCodeEditable={false}
                            />
                            {errors && errors.phone && (
                                <p className="error mt-2">Please enter a valid phone number</p>
                            )}

                            <Button
                                title="Submit"
                                isLoading={isLoading}
                                onClick={handleCreateProfile}
                                className="btn btn-primary mt-5"
                            />
                        </AvForm>

                        <Modal
                            classNames={{ modal: "setupModal height-auto" }}
                            open={open}
                            onClose={onCloseModal}
                            center
                        >
                            <ProfilePicture ref={profilePictureRef} />
                            <div className="d-flex justify-content-center setup__modal pt-5">
                                <Button
                                    title="Apply"
                                    onClick={() => {
                                        handleUpload();
                                    }}
                                />
                                <Button title="Close" onClick={onCloseModal} className="ml-3" />
                            </div>
                        </Modal>
                    </div>
                ) : (
                    <div className="d-flex flex-column align-items-center setup__complete">
                        <img className="pt-5 pb-4" src={accountsetup} alt="setup-complete" />
                        <p className="py-5">Account Setup completed</p>
                        <Button
                            title="OK"
                            onClick={handleRedirectToDashboard}
                            className="my-4 z-indx"
                        />
                    </div>
                )}
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        user: state.auth.user
    };
};

export default connect(mapStateToProps)(AccountSetup);
