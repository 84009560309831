import React from "react";

import "./FilterSelecter.scss";

import { splitByCase } from "../../utils/commonUtil";

const FilterSelecter = (props) => {
    const { label, value, onClick = () => {} } = props;

    return (
        <li className="">
            <input className="" type="checkbox" id={label} onClick={onClick} />
            <label className={` ${value && "filter-selected"} `} htmlFor={label}>
                {splitByCase(label)}
            </label>
        </li>
    );
};
export default FilterSelecter;
