import { fetchUtil } from "../../utils/fetchUtil";
import { appendQueryParams } from "../../utils/urlUtil";
import Config from "../../Config";
import { addChatsToEntity, updateChatInEntity } from "../actions";
import { getIdsFromData } from "../../utils/commonUtil";
import { scrollToBottom } from "../../utils/domUtil";
import { ChatType } from "../../constants";

export const CHAT_LIST_REQUEST = "CHAT_LIST_REQUEST";
export const CHAT_LIST_SUCCESS = "CHAT_LIST_SUCCESS";
export const CHAT_LIST_FAILED = "CHAT_LIST_FAILED";

export const MESSAGE_LIST_REQUEST = "MESSAGE_LIST_REQUEST";
export const MESSAGE_LIST_SUCCESS = "MESSAGE_LIST_SUCCESS";
export const MESSAGE_LIST_FAILED = "MESSAGE_LIST_FAILED";

export const UPDATE_CHATLIST_DATA = "UPDATE_CHATLIST_DATA";
export const MESSAGE_UNREAD_COUNT = "MESSAGE_UNREAD_COUNT";
export const ADD_NEW_MESSAGE = "ADD_NEW_MESSAGE";
export const CLEAR_CHAT_INITIAL_FETCH = "CLEAR_CHAT_INITIAL_FETCH";
export const CLEAR_CURRENT_CHAT = "CLEAR_CURRENT_CHAT";
export const SET_CURRENT_CHAT = "SET_CURRENT_CHAT";
export const UPDATE_MESSAGE_ID = "UPDATE_MESSAGE_ID";

export const getChatListing = (
    params = {},
    tabname,
    abortSignal = null,
    shouldClear = null
) => async (dispatch, getState) => {
    let token = getState().auth.user.Token;

    dispatch({ type: CHAT_LIST_REQUEST, tabname, page: params.Page });

    return fetchUtil({
        url: appendQueryParams("/chat/list", {
            Limit: Config.LIMIT,
            ...params
        }),
        token,
        abortSignal
    })
        .then(async (res) => {
            if (res && res.Data) {
                await dispatch(addChatsToEntity(res.Data.Chats));
                await dispatch({
                    type: CHAT_LIST_SUCCESS,
                    payload: getIdsFromData(res.Data.Chats, "ChatId"),
                    tabname
                });
                return Promise.resolve(getIdsFromData(res.Data.Chats, "ChatId"));
            }
            return Promise.reject(false);
        })
        .catch((err) => {
            if (err.name === "AbortError") {
                if (!shouldClear || shouldClear()) {
                    // dispatch(clearChatInitialFetch(tabname));
                }
                return;
            }
            dispatch({ type: CHAT_LIST_FAILED, tabname });
            return Promise.reject(err);
        });
};

export const updateChatListData = (key, value, tabname) => {
    return { type: UPDATE_CHATLIST_DATA, key, value, tabname };
};

export const getChatById = (id) => (dispatch, getState) => {
    const token = getState().auth.user.Token;

    return fetchUtil({
        url: appendQueryParams("/chat/details", { ChatId: parseInt(id) }),
        token
    })
        .then((res) => {
            if (res && res.Data) {
                dispatch(addChatsToEntity([res.Data]));
                return Promise.resolve(res.Data);
            }
            return Promise.reject(false);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const getChatUnReadCount = (abortSignal = null) => (dispatch, getState) => {
    let token = getState().auth.user.Token;

    return fetchUtil({
        url: `/chat/unread-chat-count`,
        token
        // abortSignal
    })
        .then((res) => {
            dispatch({
                type: MESSAGE_UNREAD_COUNT,
                payload: {
                    unReadSingleCount: res.Data?.find(
                        (item) => item.ChatType === ChatType.INDIVIDUAL
                    )?.Count,
                    unReadGroupCount: res.Data?.find((item) => item.ChatType === ChatType.GROUP)
                        ?.Count
                }
            });
        })
        .catch((err) => {
            if (err.name === "AbortError") {
                return;
            }
        });
};

export const updateChatMessageData = (chatId, msgData) => async (dispatch, getState) => {
    const existingChatsData = getState().entities.chats;

    if (!existingChatsData[chatId]) {
        await dispatch(getChatById(chatId));
    } else {
        let updatedData = {
            LastEventId: msgData.Id,
            LastChatMessage: msgData,
            LastMessage: msgData.Content,
            LastMessageDate: msgData.CreatedAt,
            LastMessageSeen: msgData.LastMessageSeen
        };
        dispatch(updateChatInEntity(chatId, updatedData));
    }
};

export const moveChatToTop = (chatId, tabname) => (dispatch, getState) => {
    const existingList = getState().chat.chatList[tabname].data;

    let newList = [...existingList];
    if (newList.includes(chatId)) {
        if (newList[0] !== chatId) {
            let currentIndex = newList.findIndex((id) => id === chatId);
            newList.splice(currentIndex, 1);
            newList.unshift(chatId);
            dispatch({ type: UPDATE_CHATLIST_DATA, value: newList, tabname, key: "data" });
        }
    } else {
        let updatedList = [...new Set([chatId, ...newList])];
        dispatch({ type: UPDATE_CHATLIST_DATA, value: updatedList, tabname, key: "data" });
    }
};

export const addMessage = (data) => (dispatch, getState) => {
    dispatch({
        type: ADD_NEW_MESSAGE,
        payload: data
    });
    setTimeout(() => {
        scrollToBottom();
    }, 0);
};

export const clearChatInitialFetch = () => {
    return { type: CLEAR_CHAT_INITIAL_FETCH };
};

export const getMessageListing = (id, params = {}, abortSignal = null, canDispatch = true) => (
    dispatch,
    getState
) => {
    const token = getState().auth.user.Token;
    dispatch({ type: MESSAGE_LIST_REQUEST, page: params.Page });

    return fetchUtil({
        url: appendQueryParams(`/chat/messages/${id}`, {
            Limit: Config.LIMIT,
            Column: "CreatedAt",
            Direction: "DESC",
            ...params
        }),
        token,
        abortSignal
    })
        .then((res) => {
            if (res && res.Data) {
                if (canDispatch) {
                    dispatch({
                        type: MESSAGE_LIST_SUCCESS,
                        payload: res.Data.ChatMessages.reverse(),
                        totalMessages: res.Data.Count
                    });
                }

                return Promise.resolve(res.Data);
            }
            return Promise.reject(false);
        })
        .catch((err) => {
            if (err.name === "AbortError") {
                return;
            }
            dispatch({ type: MESSAGE_LIST_FAILED });
            return Promise.reject(err);
        });
};

export const clearCurrentChat = () => {
    return {
        type: CLEAR_CURRENT_CHAT
    };
};

export const setCurrentChat = (chatId) => {
    return {
        type: SET_CURRENT_CHAT,
        chatId
    };
};

export const updateChatMessageId = (messageUUID, messageId) => {
    return { type: UPDATE_MESSAGE_ID, messageUUID, messageId };
};

export const updateChatUnreadCount = (tabname) => (dispatch, getState) => {
    let unreadCount = getState().chat.chatList[tabname].unReadCount;
    let updatedUnreadCount = unreadCount > 0 ? Number(unreadCount) - 1 : unreadCount;
    dispatch({
        type: UPDATE_CHATLIST_DATA,
        key: "unReadCount",
        value: updatedUnreadCount,
        tabname
    });
};
