import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams, history, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faAngleRight, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import Dropdown from "react-dropdown";
import { Modal } from "react-responsive-modal";

import "./ReportDetail.scss";
import "react-dropdown/style.css";
import "react-responsive-modal/styles.css";
import "./CustomModal.scss";

import PageTitle from "../../components/PageTitle/PageTitle";
import UserCard from "./UserCard";
import ChatWindow from "./ChatWindow";
import CommentBox from "../../components/CommentBox/CommentBox";
import { Button } from "../../components/Button";
import Loader from "../../components/Loader/Spinner";
import { getRoute, APP_ROUTES } from "../../helpers/routeHelpers";
import { getReportDetails, handleAppealAction } from "../../store/actions";
import { dateTimeFormat, convertDateTime } from "../../utils/dateFormatUtils";
import ReportItem from "./ReportItem";
import {
    UserTypes,
    ReportStatus,
    AppealReasons,
    ReportCauses,
    ReportTypes,
    UserStatus
} from "../../constants";
import { WarningBox } from "../../components/WarningBox";
import { toaster } from "../../utils/toasterUtil";

import AngleRightIcon from "../../components/SVGICON/angle-right-icon"
import AngleDownIcon from "../../components/SVGICON/angle-down-icon"

const reviewOptions = [
    { value: ReportTypes.RESOLVE, label: "Unfounded Report" },
    { value: ReportTypes.WARNING, label: ReportTypes.getDisplayTextKey(ReportTypes.WARNING) },
    { value: ReportTypes.SUSPEND, label: ReportTypes.getDisplayTextKey(ReportTypes.SUSPEND) },
    { value: ReportTypes.REMOVE, label: ReportTypes.getDisplayTextKey(ReportTypes.REMOVE) }
];

const AppealActions = {
    END_SUSPENSION: 1,
    CONTINUE_SUSPENSION: 2,
    RESUME_PROFILE: 3,
    APPEAL_DECLINE: 4
};

const AppealActionText = {
    [AppealActions.END_SUSPENSION]: "Suspension Ended",
    [AppealActions.CONTINUE_SUSPENSION]: "Suspension Continued",
    [AppealActions.RESUME_PROFILE]: "Profile Resumed",
    [AppealActions.APPEAL_DECLINE]: "Appeal Declined"
};

const ResolvedActionText = {
    [ReportTypes.RESOLVE]: "Report Was Unfound",
    [ReportTypes.SUSPEND]: "Profile Suspended",
    [ReportTypes.REMOVE]: "Removed From Platform",
    [ReportTypes.WARNING]: "Warning Sent"
};

const appealOptionsSuspended = [
    { value: AppealActions.END_SUSPENSION, label: "End Suspension" },
    { value: AppealActions.CONTINUE_SUSPENSION, label: "Continue Suspension" }
];

const AppealActionsRemoved = [
    { value: AppealActions.RESUME_PROFILE, label: "Resume Profile" },
    { value: AppealActions.APPEAL_DECLINE, label: "Appeal Decline" }
];

const ReportDetail = (props) => {
    const { getReportDetails, handleAppealAction } = props;

    const { id } = useParams();
    const history = useHistory();
    const [reportDetails, setReportDetails] = useState(null);
    const [actionType, setActionType] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [suspensionEndModal, setSuspensionEndModal] = useState(false);
    const [suspensionContinueModal, setSuspensionContinueModal] = useState(false);
    const [removedConfirmModal, setRemovedConfirmModal] = useState(false);
    const [reactivatedModal, setReactivatedModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [chatId, setChatId] = useState(null);
    const [value, setValue] = useState("");

    useEffect(() => {
        setIsLoading(true);
        getReportDetails(id)
            .then((res) => {
                setReportDetails(res.Data);
                setChatId(res.Data.ChatId);
            })
            .catch((err) => {})
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    const handleReview = (action) => {
        setActionType(action.value);

        if (action.value === ReportTypes.RESOLVE) {
            setValue("UnfoundReport");
        } else if (action.value === ReportTypes.WARNING) {
            setValue("Warning");
        } else if (action.value === ReportTypes.SUSPEND) {
            setValue("Suspend");
        } else if (action.value === ReportTypes.REMOVE) {
            setValue("Remove");
        }
    };

    const handleSubmitAppeal = (action) => {
        let payload = {
            ReportId: id,
            Type: action.value
        };

        setLoading(true);

        handleAppealAction(payload)
            .then((res) => {
                if (res && res.Data) {
                    setReportDetails(res.Data);
                    if (action.value === AppealActions.END_SUSPENSION) {
                        setSuspensionEndModal(true);
                    } else if (action.value === AppealActions.CONTINUE_SUSPENSION) {
                        setSuspensionContinueModal(true);
                    } else if (action.value === AppealActions.RESUME_PROFILE) {
                        setReactivatedModal(true);
                    } else if (action.value === AppealActions.APPEAL_DECLINE) {
                        setRemovedConfirmModal(true);
                    }
                }
            })
            .catch((err) => {})
            .finally(() => {
                setLoading(false);
            });
    };

    const renderParticipants = () => {
        if (reportDetails?.Participants && Boolean(reportDetails?.Participants?.length)) {
            return reportDetails.Participants.filter(
                (item) => item.Type !== UserTypes.MATCH_MAKER && item.Type !== UserTypes.GUARDIAN
            );
        }
        return [];
    };

    const sendMessageToUser = (data) => {
        if (!data.ChatId) {
            toaster({ title: "No Chat Available For This User", type: "danger" });
            return;
        }
        history.push(getRoute(APP_ROUTES.ACTIVE_CHAT, { tabname: "single", id: data.ChatId }));
    };

    const reviewScreen = () => {
        return (
            <div className="report__detail d-flex flex-column flex-lg-row pt-2">
                <div className="report__info d-flex flex-column-reverse flex-md-row">
                    <div className="">
                        <div className="report__info-title d-flex flex-column-reverse flex-md-row align-items-baseline pt-3 pt-lg-0">
                            <div className="d-flex">
                                <FontAwesomeIcon
                                    className="mr-3"
                                    icon={faCircle}
                                    style={{
                                        color: ReportStatus.getLabelClass(reportDetails?.Status)
                                    }}
                                />
                                <h2> {ReportCauses.getDisplayTextKey(reportDetails?.Cause)} : </h2>
                                <p className="pl-3">
                                    {ReportStatus.getDisplayTextKey(reportDetails?.Status)}
                                </p>
                            </div>
                            {props?.user?.Id !== reportDetails?.ReportedBy?.Id && (
                                <Dropdown
                                    onChange={handleReview}
                                    arrowClosed={
                                        <AngleRightIcon className="mr-3"/>
                                    }
                                    arrowOpen={
                                        <AngleDownIcon className="mr-3"/>
                                    }
                                    className="option-dropdown"
                                    options={reviewOptions}
                                    placeholder="Actions"
                                    value={value}
                                />
                            )}
                        </div>
                        <div className="report-detail pl-0 pl-lg-4">
                            <ReportItem
                                label="User Reported"
                                text={reportDetails?.ReportedUser?.Name || "-"}
                            />
                            <ReportItem
                                label="Reported by"
                                text={reportDetails?.ReportedBy?.Name || "-"}
                            />
                            <ReportItem
                                label="Date"
                                text={convertDateTime({
                                    date: reportDetails?.Date,
                                    customFormat: dateTimeFormat.appDateFormat
                                })}
                            />
                        </div>
                        <div className="report__info-content pl-0 pl-lg-4 pt-2 pt-lg-5">
                            <h2>About the incident:</h2>
                            <p className="pt-2">{reportDetails?.About || "-"}</p>
                        </div>
                    </div>
                </div>
                <div className="report__content d-flex flex-column">
                    <ChatWindow chatId={chatId} participants={renderParticipants()} />

                    <div className="report__content-user pt-5">
                        <h3 className="pb-3">Send messages to: </h3>
                        <div className="report-detail__match-card d-flex">
                            {renderParticipants().map((participant, index) => {
                                return (
                                    <UserCard
                                        data={participant}
                                        key={index}
                                        onClick={() => sendMessageToUser(participant)}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderAction = () => {
        if (reportDetails.Status === ReportStatus.APPEAL_RESOLVED) {
            return AppealActionText[reportDetails?.AppealAction];
        } else if (reportDetails.Status === ReportStatus.RESOLVED) {
            return ResolvedActionText[reportDetails?.ReportAction];
        }

        return "-";
    };

    const resolveScreen = () => {
        return (
            <div className="report__detail d-flex flex-column flex-lg-row  justify-content-between ">
                <div className="report__info d-flex">
                    <div className="">
                        <div className="report__info-title d-flex align-items-baseline">
                            <FontAwesomeIcon
                                className="resolve mr-3"
                                icon={faCircle}
                                style={{
                                    color: ReportStatus.getLabelClass(reportDetails?.Status)
                                }}
                            />
                            <h2> {ReportCauses.getDisplayTextKey(reportDetails?.Cause)} : </h2>
                            <p className="pl-3">
                                {ReportStatus.getDisplayTextKey(reportDetails?.Status)}
                            </p>
                        </div>
                        <div className="report-detail pl-0 pl-md-4">
                            <ReportItem
                                label="User Reported"
                                text={reportDetails?.ReportedUser?.Name || "-"}
                            />
                            <ReportItem
                                label="Reported by"
                                text={reportDetails?.ReportedBy?.Name || "-"}
                            />
                            <ReportItem
                                label="Date"
                                text={convertDateTime({
                                    date: reportDetails?.Date,
                                    customFormat: dateTimeFormat.appDateFormat
                                })}
                            />
                            <ReportItem label="Actions" text={renderAction()} />
                            <ReportItem
                                label="Resolved by"
                                text={reportDetails?.Resolver?.FullName || "-"}
                            />
                        </div>
                        <div className="report__info-content pl-0 pl-lg-4 pt-2 pt-lg-5">
                            <h2>About the incident:</h2>
                            <p className="pt-2">{reportDetails?.About || "-"}</p>
                        </div>
                        <div className="pt-2 pt-lg-5 pl-0 pl-lg-4">
                            <CommentBox
                                title="Matchmaker’ s remarks:"
                                description={reportDetails?.MatchMakerRemarks || "-"}
                            />
                        </div>
                    </div>
                </div>
                <div className="report__content d-flex flex-column mt-5 mt-lg-0">
                    <ChatWindow chatId={chatId} participants={renderParticipants()} />
                    <div className="report__content-user pt-5">
                        <h3 className="pb-3">Send messages to: </h3>
                        <div className="report-detail__match-card d-flex">
                            {renderParticipants().map((participant, index) => {
                                return (
                                    <UserCard
                                        data={participant}
                                        key={index}
                                        onClick={() => sendMessageToUser(participant)}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    const appealScreen = () => {
        return (
            <div className="report__detail d-flex flex-column flex-lg-row  justify-content-between ">
                <div className="report__info d-flex flex-column-reverse flex-md-row">
                    <div className="">
                        <div className="report__info-title d-flex flex-column-reverse flex-md-row align-items-baseline pt-3 pt-lg-0">
                            <div className="d-flex">
                                <FontAwesomeIcon
                                    className="appeal mr-3"
                                    icon={faCircle}
                                    style={{
                                        color: ReportStatus.getLabelClass(reportDetails?.Status)
                                    }}
                                />
                                <h2> {ReportCauses.getDisplayTextKey(reportDetails?.Cause)} : </h2>
                                <p className="pl-3">
                                    {ReportStatus.getDisplayTextKey(reportDetails?.Status)}
                                </p>
                            </div>
                            {loading ? (
                                <Button isLoading={true} />
                            ) : (
                                <Dropdown
                                    arrowClosed={
                                        <FontAwesomeIcon className="mr-3" icon={faAngleRight} />
                                    }
                                    arrowOpen={
                                        <FontAwesomeIcon className="mr-3" icon={faAngleDown} />
                                    }
                                    className="option-dropdown"
                                    options={
                                        reportDetails.ReportedUserStatus === UserStatus.SUSPENDED
                                            ? appealOptionsSuspended
                                            : AppealActionsRemoved
                                    }
                                    placeholder="Actions"
                                    onChange={handleSubmitAppeal}
                                />
                            )}
                        </div>
                        <div className="report-detail pl-0 pl-md-4">
                            <ReportItem
                                label="User Reported"
                                text={reportDetails?.ReportedUser?.Name || "-"}
                            />
                            <ReportItem
                                label="Reported by"
                                text={reportDetails?.ReportedBy?.Name || "-"}
                            />
                            <ReportItem
                                label="Date"
                                text={convertDateTime({
                                    date: reportDetails?.Date,
                                    customFormat: dateTimeFormat.appDateFormat
                                })}
                            />
                            <ReportItem label="Actions" text={renderAction()} />
                            <ReportItem
                                label="Resolved by"
                                text={reportDetails?.Resolver?.FullName || "-"}
                            />
                        </div>
                        <div className="report__info-content pl-0 pl-lg-4 pt-2 pt-lg-5">
                            <h2>About the incident:</h2>
                            <p className="pt-2">{reportDetails?.About || "-"}</p>
                        </div>
                        <div className="pt-2 pt-lg-5 pl-0 pl-lg-4">
                            <CommentBox
                                title="Matchmaker’ s remarks:"
                                description={reportDetails?.MatchMakerRemarks || "-"}
                            />
                        </div>
                        <div className=" d-flex justify-content-center pt-5 pl-0 pl-lg-4">
                            <ChatWindow chatId={chatId} participants={renderParticipants()} />
                        </div>
                    </div>
                </div>
                <div className="report__content d-flex flex-column pt-4 pt-lg-0 ">
                    <div className="report-appeal">
                        <h4 className="pb-4">Appeal Report</h4>

                        <ReportItem label="Appealed By" text={reportDetails?.Appeal?.AppealBy} />
                        <ReportItem
                            label="Appeal Date"
                            text={convertDateTime({
                                date: reportDetails?.Appeal?.Date,
                                customFormat: dateTimeFormat.appDateFormat
                            })}
                        />
                        <ReportItem
                            label="Reason To Appeal"
                            text={AppealReasons.getDisplayTextKey(reportDetails?.Appeal?.Reason)}
                        />
                    </div>
                    <div className="report-appeal-coment pt-2 pt-lg-5">
                        <CommentBox
                            title="User Remarks :"
                            description={reportDetails?.Appeal?.Remarks}
                        />
                    </div>
                    <div className="report__content-user pt-5">
                        <h3 className="pb-3">Send messages to: </h3>
                        <div className=" report-detail__match-card d-flex">
                            {renderParticipants().map((participant, index) => {
                                return (
                                    <UserCard
                                        data={participant}
                                        key={index}
                                        onClick={() => sendMessageToUser(participant)}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            <PageTitle
                link={getRoute(APP_ROUTES.REPORT_LISTING)}
                title="Back to Reports"
                icon={true}
            />

            {isLoading ? (
                <Loader />
            ) : reportDetails &&
              (reportDetails?.Status === ReportStatus?.APPEAL_REQUESTED ||
                  reportDetails?.Status === ReportStatus?.UNDER_APPEAL) ? (
                appealScreen()
            ) : reportDetails?.Status === ReportStatus?.APPEAL_RESOLVED ||
              reportDetails?.Status === ReportStatus?.RESOLVED ? (
                resolveScreen()
            ) : (
                (reportDetails?.Status === ReportStatus?.PENDING_REVIEW ||
                    reportDetails?.Status === ReportStatus?.UNDER_REVIEW) &&
                reviewScreen()
            )}

            {!isLoading && !reportDetails && (
                <div className="d-flex flex-row justify-content-center">
                    <p className="mr-2">Report Not Found</p>{" "}
                </div>
            )}

            {actionType && (
                <WarningBox
                    setAction={(type) => setActionType(type)}
                    participants={renderParticipants()}
                    type={actionType}
                    reportId={parseInt(id)}
                    reportedUserId={reportDetails?.ReportedUserId}
                    updateCurrentReport={(data) => setReportDetails(data)}
                    setDropDownValue={(value) => setValue(value)}
                />
            )}

            <Modal
                classNames={{ modal: "CustomModal" }}
                open={suspensionContinueModal}
                onClose={() => setSuspensionContinueModal(false)}
                showCloseIcon={false}
                center
            >
                <div className="custom-modal d-flex flex-column align-items-center justify-content-center h-100">
                    <p>The suspension period will continue</p>
                    <button
                        className="custom-btn"
                        onClick={() => setSuspensionContinueModal(false)}
                    >
                        Ok
                    </button>
                </div>
            </Modal>
            <Modal
                classNames={{ modal: "CustomModal" }}
                open={suspensionEndModal}
                onClose={() => setSuspensionEndModal(false)}
                showCloseIcon={false}
                center
            >
                <div className="custom-modal d-flex flex-column align-items-center justify-content-center h-100">
                    <p>The suspension period will end</p>
                    <button className="custom-btn" onClick={() => setSuspensionEndModal(false)}>
                        Ok
                    </button>
                </div>
            </Modal>
            <Modal
                classNames={{ modal: "CustomModal" }}
                open={removedConfirmModal}
                onClose={() => setRemovedConfirmModal(false)}
                showCloseIcon={false}
                center
            >
                <div className="custom-modal d-flex flex-column align-items-center justify-content-center h-100">
                    <p>
                        {" "}
                        {reportDetails?.ReportedUser?.Name} will be removed permanently from the
                        platform
                    </p>
                    <button className="custom-btn" onClick={() => setRemovedConfirmModal(false)}>
                        Ok
                    </button>
                </div>
            </Modal>

            <Modal
                classNames={{ modal: "CustomModal" }}
                open={reactivatedModal}
                onClose={() => setReactivatedModal(false)}
                showCloseIcon={false}
                center
            >
                <div className="custom-modal d-flex flex-column align-items-center justify-content-center h-100">
                    <p> {reportDetails?.ReportedUser?.Name} profile will be reactivated</p>
                    <button className="custom-btn" onClick={() => setReactivatedModal(false)}>
                        Ok
                    </button>
                </div>
            </Modal>
        </>
    );
};

const mapDispatchToProps = {
    getReportDetails,
    handleAppealAction
};

const mapStateToProps = (state) => {
    return {
        user: state?.auth?.user
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportDetail);
