import io from "socket.io-client";
import Config from "../Config";
import { store } from "../store";
import { logger } from "./loggerUtils";
import { changeSocketConnectivityStatus } from "../store/actions";

export const SocketTypes = {
    NEW_USER_ID_VERIFICATION: "new_user_id_verification",
    MESSAGE: "message",
    READ_MESSAGE: "read_message",
    CHAT_JOIN: "chat_join",
    GUARDIAN_ADDED: "guardian_added",
    GUARDIAN_REMOVED: "guardian_removed"
};

export class Socket {
    static socket = null;

    static getSocket = () => {
        if (Socket.socket === null) {
            const authToken = store.getState().auth.user.Token;
            Socket.socket = io(`${Config.env().API_URL}?authorization=${authToken}`, {
                transports: ["websocket"],
                upgrade: false,
                reconnection: true,
                reconnectionAttempts: Infinity,
                reconnectionDelay: 1000,
                reconnectionDelayMax: 5000
            });
        }
        return Socket.socket;
    };

    static init = () => {
        Socket.getSocket().on("connect", () => {
            logger("socket connected!");
            store.dispatch(changeSocketConnectivityStatus(true));
        });

        Socket.getSocket().on("connection-error", (err) => {
            logger("socket connection error", err);
        });

        Socket.getSocket().on("error", (error) => {
            logger(error);
        });
        Socket.getSocket().on("disconnect", (reason) => {
            logger("socket disconnected", reason);
            store.dispatch(changeSocketConnectivityStatus(false));
        });
    };

    static onNewUserJoined = (cb) => {
        Socket.getSocket().on(SocketTypes.NEW_USER_ID_VERIFICATION, cb);
    };

    static onMessageRecieved = (cb) => {
        Socket.getSocket().on(SocketTypes.MESSAGE, cb);
    };

    static readMessage = (chatId) => {
        Socket.getSocket().emit(SocketTypes.READ_MESSAGE, {
            ChatId: chatId
        });
    };

    static onGuardianAdded = (cb) => {
        Socket.getSocket().on(SocketTypes.GUARDIAN_ADDED, cb);
    };

    static onGuardianRemoved = (cb) => {
        Socket.getSocket().on(SocketTypes.GUARDIAN_REMOVED, cb);
    };

    static joinChat = (chatId) => {
        Socket.getSocket().emit(SocketTypes.CHAT_JOIN, {
            ChatId: chatId
        });
    };

    static sendMessage = (data, cb) => {
        Socket.getSocket().emit(
            SocketTypes.MESSAGE,
            {
                ...data
            },
            cb
        );
    };

    static disconnect = () => {
        Socket.getSocket().disconnect();
        Socket.socket = null;
    };

    static remove = (name, listener = null) => {
        if (Socket.socket) {
            if (listener) {
                Socket.getSocket().removeListener(name, listener);
            } else {
                Socket.getSocket().removeAllListeners(name);
            }
        }
    };
}
