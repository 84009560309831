import React, { useState, useEffect } from "react";
import "./PreviousChat.scss";
import PageTitle from "../../components/PageTitle/PageTitle";
import SearchBox from "../../components/SearchBox/SearchBox";
import ChatList from "../../components/ChatList/ChatListItem";
import ChatHeader from "../../components/ChatHeader/ChatHeader";
import Receiver from "../../components/Receiver/Receiver";
import ChatInfo from "../../components/ChatInfo/ChatInfo";
import Sender from "../../components/Sender/Sender";
import men1 from "../../assets/images/men1.png";
import men2 from "../../assets/images/men2.png";
import women1 from "../../assets/images/women1.png";
import women2 from "../../assets/images/women2.png";
import $ from "jquery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
const width = window.innerWidth;
const PreviousChat = (props) => {
    const [mobileview, setMobileview] = useState(width < 780);
    const [detail, setDetail] = useState(false);
    const detailHandler = () => setDetail(!detail);

    useEffect(() => {
        $(window).resize(() => {
            const width = window.innerWidth;
            const mobileview = width < 780;
            setMobileview(mobileview);
        });
    }, []);
    const data = [
        {
            img1: men1,
            name1: "zainul",
            description: "Its nice to meeting you, how’s Its nice to meeting you, how’s",
            lastActive: "3h",
            update: false
        },
        {
            img1: men2,
            name1: "Faneshka",
            description: "Its nice to meeting you, how’s Its nice to meeting you, how’s",
            lastActive: "5h",
            update: true
        },
        {
            img1: men2,
            name1: "Imran",
            description: "Its nice to meeting you, how’s Its nice to meeting you, how’s",
            lastActive: "6h",
            update: true
        }
    ];
    const headerData = [
        {
            img2: men1,
            name2: "zain",
            img1: women2,
            name1: "Aysha"
        }
    ];
    return (
        <>
            <PageTitle title="Previous Chats" />
            <div
                className={
                    !detail
                        ? "previous-chat__wrapper mobile-wrapper  d-flex"
                        : "previous-chat__wrapper mobile-wrapper-detail fancy-scroll d-flex"
                }
            >
                <div className="previous-chat__info">
                    <div className="previous-chat__header d-flex align-items-center pl-5 right-border">
                        <SearchBox />
                    </div>
                    <div className="previous-chat__body right-border">
                        {data
                            ? data.map((obj, index) => (
                                  <ChatList
                                      onClick={mobileview ? detailHandler : null}
                                      data={obj}
                                  />
                              ))
                            : null}
                    </div>
                </div>
                <div className="previous-chat__message">
                    <div className="previous-chat__header d-flex align-items-center pl-2 pl-md-5">
                        {mobileview ? (
                            <FontAwesomeIcon
                                icon={faAngleLeft}
                                onClick={detailHandler}
                                className="back-icon mr-3"
                            />
                        ) : null}

                        {headerData
                            ? headerData.map((obj, index) => <ChatHeader data={obj} />)
                            : null}
                    </div>
                    <div className="previous-chat__body">
                        <Receiver
                            name="zain"
                            message="Message text in here in this style to look like you have lots of friends!"
                        />
                        <ChatInfo info="Ayesha added her Guardian" />
                        <Sender message="Hi! Introduce yourselves and tell each other a bit about you likes, dislikes, hobbied etc." />
                    </div>
                </div>
            </div>
        </>
    );
};
export default PreviousChat;
