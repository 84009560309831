import { APP_ROUTES } from "./helpers/routeHelpers";
import {
    Home,
    Signup,
    ActiveChat,
    PreviousChat,
    Verification,
    UserListing,
    UserDetail,
    ReportListing,
    ReportDetail,
    UserProfile,
    Settings
} from "./screens/index";
const routes = [
    {
        path: APP_ROUTES.ACTIVE_CHAT,
        name: "chats",
        exact: true,
        component: ActiveChat
    },
    {
        path: APP_ROUTES.USER_LISTING,
        name: "user-listing",
        exact: true,
        component: UserListing
    },
    {
        path: APP_ROUTES.PREVIOUS_CHAT,
        name: "perivious-chat",
        exact: true,
        component: ActiveChat
    },
    {
        path: APP_ROUTES.VERIFICATION,
        name: "verification",
        exact: true,
        component: Verification
    },
    {
        path: APP_ROUTES.USER_DETAIL,
        name: "UserDetail",
        exact: true,
        component: UserDetail
    },
    {
        path: APP_ROUTES.REPORT_LISTING,
        name: "ReportListing",
        exact: true,
        component: ReportListing
    },
    {
        path: APP_ROUTES.REPORT_DETAIL,
        name: "Report-detail",
        exact: true,
        component: ReportDetail
    },
    {
        path: APP_ROUTES.USER_LISTING,
        name: "User Listing",
        component: UserListing
    },
    {
        path: APP_ROUTES.USER_PROFILE,
        name: "user-profile",
        exact: true,
        component: UserProfile
    },
    {
        path: APP_ROUTES.SETTINGS,
        name: "user-profile",
        exact: true,
        component: Settings
    }
];
export default routes;
