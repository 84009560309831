import { injectBaseConstantMethods } from "./BaseConstants";

const ReportTypes = {
    WARNING: 1,
    SUSPEND: 2,
    REMOVE: 3,
    RESOLVE: 4
};

const displayTextKeys = {
    [ReportTypes.WARNING]: "Warning",
    [ReportTypes.SUSPEND]: "Suspend",
    [ReportTypes.REMOVE]: "Remove",
    [ReportTypes.RESOLVE]: "Resolve"
};

const labelClasses = {
    [ReportTypes.WARNING]: "",
    [ReportTypes.SUSPEND]: "",
    [ReportTypes.REMOVE]: "",
    [ReportTypes.RESOLVE]: ""
};

export default injectBaseConstantMethods(ReportTypes, displayTextKeys, labelClasses);
