import React from "react";

import "./RadioBoxItem.scss";

const RadioBoxItem = (props) => {
    const { value, label, checked = false, onClick = () => {} } = props;

    return (
        <li>
            <input
                className="styled-checkbox"
                id={"cause-" + value}
                type="radio"
                value={value}
                checked={checked}
                onChange={(e) => onClick(e.target.value)}
            />
            <label htmlFor={"cause-" + value}>{label}</label>
        </li>
    );
};

export default RadioBoxItem;
