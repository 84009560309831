import {
    PENDING_USERS_LISTING_REQUEST,
    PENDING_USERS_LISTING_SUCCESS,
    PENDING_USERS_LISTING_FAILURE,
    FILTER_USER_VERIFICATION,
    NEW_VERIFICATION_IDENTITY
} from "../actions";

const initialState = {
    loading: false,
    totalUsers: 0,
    list: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case PENDING_USERS_LISTING_REQUEST:
            return {
                ...state,
                loading: true,
                list: (() => {
                    if (!action.Page || action.Page === 1) {
                        return [];
                    }
                    return state.list;
                })()
            };

        case PENDING_USERS_LISTING_SUCCESS:
            return {
                ...state,
                loading: false,
                list: [...state.list, ...action.payload],
                totalUsers: action.totalUsers
            };

        case PENDING_USERS_LISTING_FAILURE:
            return {
                ...state,
                loading: false,
                list: [],
                totalUsers: 0
            };

        case FILTER_USER_VERIFICATION:
            return {
                ...state,
                loading: false,
                list: state.list.filter((user) => user.Id !== action.payload),
                totalUsers: state.totalUsers - 1
            };

        case NEW_VERIFICATION_IDENTITY:
            return {
                ...state,
                loading: false,
                list: [action.payload, ...state.list]
            };

        default:
            return state;
    }
};
