import React, { useState, useEffect } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

import "./SuccessModal.scss";
const SuccessModal = (props) => {
    let { open = false, onCloseModal, description, name, btnAction = () => {} } = props;

    return (
        <>
            <Modal
                classNames={{ modal: "CustomModal" }}
                open={open}
                onClose={onCloseModal}
                showCloseIcon={false}
                center
            >
                <div className="custom-modal d-flex flex-column align-items-center justify-content-center h-100">
                    <p>{description}</p>
                    <button className="custom-btn" onClick={btnAction}>
                        {name}
                    </button>
                </div>
            </Modal>
        </>
    );
};
export default SuccessModal;
