import { injectBaseConstantMethods } from "./BaseConstants";

const PrayerRoutine = {
    REGULAR: 1,
    MODERATE: 2,
    RARELY: 3
};

const displayTextKeys = {
    [PrayerRoutine.REGULAR]: "Regular",
    [PrayerRoutine.MODERATE]: "Moderate",
    [PrayerRoutine.RARELY]: "Rarely"
};

const labelClass = {
    [PrayerRoutine.SINGLE]: "",
    [PrayerRoutine.SEPERATED]: "",
    [PrayerRoutine.DIVORCED]: ""
};

export default injectBaseConstantMethods(PrayerRoutine, displayTextKeys, labelClass);
