
import React, { Component } from 'react';
const Bellicon = (props) => {
    return (
        <svg className={props.className} onClick={props.onClick} width="25" height="30" viewBox="0 0 25 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.6703 2.3494V3.1483L14.4495 3.32471C18.5337 4.24942 21.4331 7.77326 21.4331 11.9277V19.5783C21.4331 20.6199 21.6455 21.6304 22.1625 22.58L23.6649 25.4458H16.6874H15.9129H15.8294H14.4869H10.5749H9.24384L9.61446 26.7242C9.98485 28.0018 11.1761 28.8916 12.5309 28.8916C13.9309 28.8916 15.0004 27.9638 15.4271 26.7865L15.7678 25.8462L15.699 26.2938C15.4661 27.8085 14.1621 29 12.5309 29C10.8998 29 9.59567 27.8085 9.36282 26.2938L9.23244 25.4458H8.37443H1.33583L2.84201 22.5727L2.85243 22.5529L2.86195 22.5325C3.27268 21.6555 3.56758 20.6661 3.56758 19.5783V11.9277C3.56758 7.77105 6.46743 4.25526 10.5388 3.38768L11.3304 3.219V2.40964V1.80723C11.3304 1.37048 11.7027 1 12.1642 1H12.8365C13.3154 1 13.6703 1.3877 13.6703 1.80723V2.3494Z" stroke="#6246EA" stroke-width="2" />
            <path d="M20.0634 9.54545C22.738 9.54545 24.9062 7.40863 24.9062 4.77273C24.9062 2.13682 22.738 0 20.0634 0C17.3889 0 15.2207 2.13682 15.2207 4.77273C15.2207 7.40863 17.3889 9.54545 20.0634 9.54545Z" fill="#F7401C" />
        </svg>



    );
}
export default Bellicon