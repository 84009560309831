import React from "react";
import { useHistory } from "react-router-dom";

import "./MatchCard.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";
import { toaster } from "../../utils/toasterUtil";

const MatchCard = (props) => {
    const { data, userId = null } = props;

    const history = useHistory();

    const handleNavigation = () => {
        if (userId) {
            history.push(getRoute(APP_ROUTES.USER_DETAIL, { id: userId }));
        }
    };

    const handleViewProfile = () => {
        if (data?.UserId) {
            history.push(getRoute(APP_ROUTES.USER_DETAIL, { id: data?.UserId }));
        } else {
            toaster({ title: "No Data Available For This User", type: "danger" });
        }
    };

    return (
        <>
            {data?.MediaPath ? (
                <div
                    onClick={handleViewProfile}
                    className="match-card__wrapper d-flex align-items-center mr-3 mb-3"
                >
                    <img src={data?.MediaPath} />
                    <div className="">
                        <p className="">{data?.FullName}</p>
                        {props.percentage ? (
                            <p className="percentage pl-3 pt-2">{data.percentage}</p>
                        ) : null}
                    </div>
                </div>
            ) : (
                <div className="match-card__wrapper d-flex align-items-center justify-content-center mr-3 mb-3" onClick={handleNavigation}>
                    <FontAwesomeIcon
                        className="add-icon "
                        icon={faPlus}
                    />
                </div>
            )}
        </>
    );
};
export default MatchCard;
