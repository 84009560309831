export const APP_ROUTES = {
    SIGNUP: "/signup-verification",
    LOGIN: "/login",
    ACCOUNT_SETUP: "/account-setup",
    DASHBOARD: "/dashboard",
    USER_LISTING: "/dashboard/user-listing",
    ACTIVE_CHAT: "/dashboard/chat/:tabname?/:id?",
    PREVIOUS_CHAT: "/dashboard/previous-chats/:tabname/:id?",
    VERIFICATION: "/dashboard/verification",
    USER_DETAIL: "/dashboard/user-detail/:id",
    USER_ACCOUNT: "/dashboard/user-account",
    FAQ: "/dashboard/Faq",
    ABOUT_US: "/dashboard/about-us",
    CONTACT_US: "/dashboard/contact-us",
    TERMS_CONDITION: "/dashboard/terms-and-condition",
    NOTIFICATION: "/dashboard/notification",
    USER_DETAIL: "/dashboard/user-detail/:id",
    SETTINGS: "/dashboard/settings/:type",
    REPORT_LISTING: "/dashboard/report-listing",
    REPORT_DETAIL: "/dashboard/report-detail/:id",
    USER_PROFILE: "/dashboard/previous-chats/user-profile"
};

export const getRoute = (route, obj = {}) => {
    if (Object.keys(obj).length) {
        let objectKeys = Object.keys(obj);
        objectKeys.forEach((item) => {
            route = route.replace(new RegExp(/:([\d\w?])+/, "i"), (match) => {
                let formattedMatchedValue =
                    match[match.length - 1] === "?"
                        ? match.slice(1, match.length - 1)
                        : match.slice(1);
                return obj[formattedMatchedValue];
            });
        });
        return route;
    }
    return route;
};
