import phoneNumberValidation from "libphonenumber-js";
import { store } from "../store";
import { ChatType, UserTypes } from "../constants";

export const convertBase64ToFileObj = (base64Url, customFile) => {
    return fetch(base64Url)
        .then((res) => res.blob())
        .then((blob) => {
            const file = new File([blob], customFile.name, { type: customFile.type });
            return file;
        });
};

export const objectContainsKey = (object, key) => {
    return typeof object === "object" && object && object[key] !== undefined;
};

export const convertInches = (inches) => {
    if (inches) {
        let feetFromInches = Math.floor(inches / 12);
        let inchesRemainder = inches % 12;

        return feetFromInches + "ft " + inchesRemainder + '"';
    }
    return "";
};

export const splitByCase = (word) => {
    return word.replace(/([a-z0-9])([A-Z])/g, "$1 $2");
};

export const validatePhone = (phoneNumber) => {
    if (phoneNumber) {
        let parsPhoneNumber = phoneNumberValidation(
            `Phone:+${phoneNumber.value}`,
            phoneNumber.countryCode
        );
        if (parsPhoneNumber) {
            return parsPhoneNumber.isValid();
        } else {
            return false;
        }
    } else {
        return false;
    }
};

export const getIdsFromData = (data, key = "Id") => {
    return data.map((item) => item[key]);
};

export const getUserImagesPayload = (users, chatType) => {
    const currentUserId = store.getState().auth.user.Id;
    let usersData = users.filter((user) => user.UserId !== currentUserId);
    if (chatType === ChatType.INDIVIDUAL) {
        return usersData;
    }
    return usersData.filter((user) => user.Type === UserTypes.USER);
};

export const getUserNames = (users) => {
    const chatUsers = [];
    {
        !!users.length &&
            users.map((user) => {
                if (user.UserId !== store.getState().auth.user.Id) chatUsers.push(user.Name);
            });
    }

    return chatUsers.join(", ");
};

export const escapeHTMLElementsFromMessage = (message) => {
    //todo places in util helper
    var htmlEscapes = {
        "<": "&lt;",
        ">": "&gt;"
    };
    // Regex containing the keys listed immediately above.
    var htmlEscaper = /[<>]/g;
    // Escape a string for HTML interpolation.
    return message.replace(htmlEscaper, function (match) {
        return htmlEscapes[match];
    });
};

export const getRandomNumber = () => {
    return new Date().getTime().toString() + Math.floor(Math.random() * 1000000);
};

export const canFileUploaded = (file) => {
    let totalBytes = file.size;
    let fileInMb = totalBytes / 1024 / 1024;
    if (fileInMb <= 8) {
        return true;
    }
    return false;
};
