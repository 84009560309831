import React from "react";
import ReactJoyride, { EVENTS } from "react-joyride";
import { useHistory } from "react-router-dom";
import Tooltip from "./Tooltip/Tooltip";
import TooltipDesignMain from "./Tooltip/TooltipDesignMain/TooltipDesignMain";
// import { withNamespaces } from "react-i18next";
// import { withNamespaces } from "react-i18next";
import { APP_ROUTES } from "../../helpers/routeHelpers";

const Tour = (props, { run = true }) => {
    const { onTourCancel = () => {} } = props;

    const history = useHistory();
    const test = () => {
        console.log("!!!!@@@@");
    };
    // const handleJoyrideCallback = data => {

    //     if (data.action == 'update' && data.status == 'running' && data.lifecycle == 'tooltip' && data.type == 'tooltip') {
    //         history.push(APP_ROUTES.USER_LISTING);
    //     }
    //     if (data.action == 'reset') {
    //         localStorage.setItem('tour', false);
    //     }
    // };

    return (
        <ReactJoyride
            className="TourContainer"
            tooltipComponent={Tooltip}
            continuous={true}
            run={run}
            disableScrolling= {true}
            scrollToFirstStep={true}
            showProgress={true}
            showSkipButton={true}
            TOURSTART={test}
            stepIndex={() => (index) => {
                console.log(index);
            }}
            // callback={handleJoyrideCallback}
            steps={[
                //Step 1
                {
                    placementBeacon: "",
                    content: (
                        backProps,
                        primaryProps,
                        closeProps,
                        tooltipProps,
                        index,
                        continuous,
                        skipProps
                    ) => (
                        <TooltipDesignMain
                            backProps={backProps}
                            primaryProps={primaryProps}
                            skipProps={skipProps}
                            tooltipProps={tooltipProps}
                            index={index}
                            continuous={continuous}
                            cardMaxWidth="40.5rem"
                            designtype="step-1"
                            onCancel={onTourCancel}
                        />
                    ),

                    // locale: { skip: <strong aria-label="skip">{props.t("SKIP")}</strong> },
                    placement: "center",
                    disableBeacon: true,
                    target: "body"
                },
                //Step 2
                {
                    content: (
                        backProps,
                        primaryProps,
                        closeProps,
                        tooltipProps,
                        index,
                        continuous
                    ) => (
                        <TooltipDesignMain
                            backProps={backProps}
                            primaryProps={primaryProps}
                            closeProps={closeProps}
                            tooltipProps={tooltipProps}
                            index={index}
                            continuous={continuous}
                            cardMaxWidth="75.875rem"
                            designtype="step-2"
                            cardPadding="1.313rem 1.25rem"
                            cardBlur="2rem"
                            onCancel={onTourCancel}
                        />
                    ),
                    // locale: { skip: <strong aria-label="skip">{props.t("SKIP")}</strong> },
                    placement: "right",
                    disableBeacon: true,
                    target: ".sidebar-config-user"
                },
                {
                    content: (
                        backProps,
                        primaryProps,
                        closeProps,
                        tooltipProps,
                        index,
                        continuous
                    ) => (
                        <TooltipDesignMain
                            backProps={backProps}
                            primaryProps={primaryProps}
                            closeProps={closeProps}
                            tooltipProps={tooltipProps}
                            index={index}
                            continuous={continuous}
                            cardMaxWidth="75.5rem"
                            designtype="step-3"
                            cardPadding="1.375rem"
                            cardBlur="2rem"
                            onCancel={onTourCancel}
                        />
                    ),
                    // locale: { skip: <strong aria-label="skip">{props.t("SKIP")}</strong> },
                    placement: "right",
                    disableBeacon: true,
                    target: ".sidebar-config-verify"
                },
                {
                    content: (
                        backProps,
                        primaryProps,
                        closeProps,
                        tooltipProps,
                        index,
                        continuous
                    ) => (
                        <TooltipDesignMain
                            backProps={backProps}
                            primaryProps={primaryProps}
                            closeProps={closeProps}
                            tooltipProps={tooltipProps}
                            index={index}
                            continuous={continuous}
                            cardMaxWidth="75.5rem"
                            designtype="step-4"
                            cardPadding="1.375rem"
                            cardBlur="2rem"
                            onCancel={onTourCancel}
                        />
                    ),
                    // locale: { skip: <strong aria-label="skip">{props.t("SKIP")}</strong> },
                    placement: "right",
                    disableBeacon: true,
                    target: ".sidebar-config-verify-dot"
                },
                //Step 3
                {
                    content: (
                        backProps,
                        primaryProps,
                        closeProps,
                        tooltipProps,
                        index,
                        continuous
                    ) => (
                        <TooltipDesignMain
                            backProps={backProps}
                            primaryProps={primaryProps}
                            closeProps={closeProps}
                            tooltipProps={tooltipProps}
                            index={index}
                            continuous={continuous}
                            cardMaxWidth="40.5rem"
                            designtype="step-5"
                            cardPadding="1.313rem 1rem 1.313rem 1.25rem"
                            onCancel={onTourCancel}
                        />
                    ),
                    // locale: { skip: <strong aria-label="skip">{props.t("SKIP")}</strong> },
                    placement: "right",
                    disableBeacon: true,
                    target: ".sidebar-config-chat"
                },
                //Step 4
                {
                    content: (
                        backProps,
                        primaryProps,
                        closeProps,
                        tooltipProps,
                        index,
                        continuous
                    ) => (
                        <TooltipDesignMain
                            backProps={backProps}
                            primaryProps={primaryProps}
                            closeProps={closeProps}
                            tooltipProps={tooltipProps}
                            index={index}
                            continuous={continuous}
                            cardMaxWidth="40.5rem"
                            designtype="step-6"
                            cardPadding="1.313rem 1rem 1.313rem 1.25rem"
                            onCancel={onTourCancel}
                        />
                    ),
                    // locale: { skip: <strong aria-label="skip">{props.t("SKIP")}</strong> },
                    placement: "right",
                    disableBeacon: true,
                    target: ".sidebar-config-report"
                },
                // step 5
                {
                    content: (
                        backProps,
                        primaryProps,
                        closeProps,
                        tooltipProps,
                        index,
                        continuous
                    ) => (
                        <TooltipDesignMain
                            backProps={backProps}
                            primaryProps={primaryProps}
                            closeProps={closeProps}
                            tooltipProps={tooltipProps}
                            index={index}
                            continuous={continuous}
                            cardMaxWidth="40.5rem"
                            designtype="step-7"
                            cardPadding="1.313rem 1rem 1.313rem 1.25rem"
                            onCancel={onTourCancel}
                        />
                    ),
                    // locale: { skip: <strong aria-label="skip">{props.t("SKIP")}</strong> },
                    placement: "right",
                    disableBeacon: true,
                    target: ".sidebar-config-chat-arrow"
                },
                // step 6
                {
                    content: (
                        backProps,
                        primaryProps,
                        closeProps,
                        tooltipProps,
                        index,
                        continuous
                    ) => (
                        <TooltipDesignMain
                            backProps={backProps}
                            primaryProps={primaryProps}
                            closeProps={closeProps}
                            tooltipProps={tooltipProps}
                            index={index}
                            continuous={continuous}
                            cardMaxWidth="40.5rem"
                            designtype="step-8"
                            cardPadding="1.313rem 1rem 1.313rem 1.25rem"
                            onCancel={onTourCancel}
                        />
                    ),
                    // locale: { skip: <strong aria-label="skip">{props.t("SKIP")}</strong> },
                    placement: "right",
                    disableBeacon: true,
                    target: ".sidebar-config-settings"
                },
                // step 7
                {
                    content: (
                        backProps,
                        primaryProps,
                        closeProps,
                        tooltipProps,
                        index,
                        continuous
                    ) => (
                        <TooltipDesignMain
                            backProps={backProps}
                            primaryProps={primaryProps}
                            closeProps={closeProps}
                            tooltipProps={tooltipProps}
                            index={index}
                            continuous={continuous}
                            cardMaxWidth="40.5rem"
                            designtype="step-9"
                            cardPadding="1.313rem 1rem 1.313rem 1.25rem"
                            onCancel={onTourCancel}
                        />
                    ),
                    // locale: { skip: <strong aria-label="skip">{props.t("SKIP")}</strong> },
                    placement: "right-end",
                    disableBeacon: true,
                    target: ".sidebar-config-logout"
                },
                // step 8

                {
                    content: (
                        backProps,
                        primaryProps,
                        closeProps,
                        tooltipProps,
                        index,
                        continuous
                    ) => (
                        <TooltipDesignMain
                            backProps={backProps}
                            primaryProps={primaryProps}
                            closeProps={closeProps}
                            tooltipProps={tooltipProps}
                            index={index}
                            continuous={continuous}
                            cardMaxWidth="40.5rem"
                            designtype="step-10"
                            cardPadding="1.313rem 1rem 1.313rem 1.25rem"
                            cardBlur="2rem"
                            onCancel={onTourCancel}
                        />
                    ),
                    // locale: { skip: <strong aria-label="skip">{props.t("SKIP")}</strong> },
                    placement: "top",
                    disableBeacon: true,
                    target: ".sidebar-config-notification"
                },
                // step 9

                //Step 10
                {
                    content: (
                        backProps,
                        primaryProps,
                        closeProps,
                        tooltipProps,
                        index,
                        continuous,
                        skipProps,
                        isCloseButton
                    ) => (
                        <TooltipDesignMain
                            backProps={backProps}
                            primaryProps={primaryProps}
                            skipProps={skipProps}
                            tooltipProps={tooltipProps}
                            isCloseButton={isCloseButton}
                            index={index}
                            continuous={continuous}
                            cardMaxWidth="46.25rem"
                            designtype="step-11"
                            onCancel={onTourCancel}
                        />
                    ),
                    // locale: { skip: <strong aria-label="skip">{props.t("SKIP")}</strong> },
                    placement: "center",
                    disableBeacon: true,
                    target: "body"
                }
            ]}
            styles={{
                options: {
                    arrowColor: "#ffffffeb",
                    backgroundColor: "#e3ffeb",
                    overlayColor: "#0000002b",
                    primaryColor: "#000",
                    textColor: "#004a14",
                    width: 900,
                    zIndex: 1000,
                    beaconSize: 36,
                    spotlightShadow: "0 0 15px rgba(0, 0, 0, 0.5)"
                }
            }}
        />
    );
};

export default Tour;
