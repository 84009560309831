import React, { useState, useEffect, useRef } from "react";
import { connect, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

import "./ChatWindow.scss";
import "./ReportDetail.scss";

import ChatDateTime from "../../components/ChatDateTime/ChatDateTime";
import ChatInfo from "../../components/ChatInfo/ChatInfo";
import Receiver from "../../components/Receiver/Receiver";
import Sender from "../../components/Sender/Sender";
import Loader from "../../components/Loader/Spinner";
import { scrollToBottom } from "../../utils/domUtil";
import { ChatMessageTypes, GenderType } from "../../constants";
import { getMessageListing, clearCurrentChat } from "../../store/actions";

const ChatWindow = (props) => {
    const { chatId, participants = [], currentUser, getMessageListing, clearCurrentChat } = props;

    const [page, setPage] = useState(1);
    const [fetching, setFetching] = useState(false);
    const [messages, setMessages] = useState([]);
    const [totalMessages, setTotalMessages] = useState(0);

    const chatAbortController = useRef(null);

    const currentChat = useSelector((state) => state.entities.chats[chatId]);

    useEffect(() => {
        chatAbortController.current = new AbortController();

        if (chatId) {
            setFetching(true);
            getMessageListing(chatId, { Page: page }, chatAbortController.current.signal, false)
                .then((res) => {
                    setMessages([...res.ChatMessages].reverse());
                    setTotalMessages(res.Count);
                    scrollToBottom();
                })
                .catch(() => {})
                .finally(() => {
                    setFetching(false);
                });
        }

        return () => {
            if (chatAbortController.current) {
                chatAbortController.current.abort();
            }

            setPage(1);
            clearCurrentChat();
        };
    }, [chatId]);

    const handleScroll = (event) => {
        const isTop = event.target.scrollTop < 10;
        let container = document.getElementById("chatbox-scroll-div");
        let prevScrollTop = container.scrollTop;
        let prevScrollHeight = container.scrollHeight;

        if (isTop && !fetching && messages.length < totalMessages) {
            let newPage = page + 1;
            setFetching(true);
            getMessageListing(chatId, { Page: newPage }, null, false)
                .then((res) => {
                    setMessages((prev) => {
                        let newChat = [...res.ChatMessages].reverse();
                        return [...newChat, ...prev];
                    });
                    container.scrollTop =
                        container.scrollHeight - (prevScrollHeight - prevScrollTop);
                    setPage(newPage);
                })
                .catch(() => {})
                .finally(() => {
                    setFetching(false);
                });
        }
    };

    const renderNames = () => {
        if (Boolean(participants.length)) {
            return participants.map((item) => item.Name).join(" & ");
        }

        return "-";
    };

    const renderMessage = (data) => {
        if (data.Type === ChatMessageTypes.CHAT_ACCEPTED) {
            return <ChatInfo info={`Chat Request has been accepted`} />;
        } else if (data.Type === ChatMessageTypes.CHAT_REJECTED) {
            return <ChatInfo info={`${data.SenderName} declined chat.`} />;
        } else if (data.Type === ChatMessageTypes.GUARDIAN_ADDED) {
            let userData = currentChat?.Users?.find((user) => user.UserId === data.SenderId);

            return (
                <ChatInfo
                    info={`${userData?.Name} added ${
                        userData?.Gender === GenderType.MALE ? "his " : "her "
                    } Guardian`}
                />
            );
        } else if (data.Type === ChatMessageTypes.GUARDIAN_REMOVED) {
            let userData = currentChat?.Users?.find((user) => user.UserId === data.SenderId);
            return (
                <ChatInfo
                    info={`${userData?.Name || "Participant"} removed ${
                        userData?.Gender === GenderType.MALE ? "his" : "her"
                    } Guardian`}
                />
            );
        } else if (data.Type === ChatMessageTypes.USER_LEFT) {
            return <ChatInfo info={`${data?.SenderName} left the conversation`} />;
        } else if (data.Type === ChatMessageTypes.GUARDIAN_LEFT) {
            return <ChatInfo info={`${data?.SenderName}'s guardian left the conversation`} />;
        } else if (
            data.SenderId === currentUser.Id &&
            data.Type === ChatMessageTypes.MEDIA_MESSAGE
        ) {
            return (
                <Sender
                    picture={data?.Attachments[0]?.ThumbPath}
                    userImage={data.SenderMediaUrl}
                    data={data}
                    caption={data.Content}
                    fromReport={true}
                />
            );
        } else if (
            data.Type === ChatMessageTypes.MEDIA_MESSAGE &&
            data.SenderId !== currentUser.Id
        ) {
            return (
                <Receiver
                    picture={data?.Attachments[0]?.ThumbPath}
                    userImage={data.SenderMediaUrl}
                    data={data}
                    caption={data.Content}
                    fromReport={true}
                />
            );
        } else if (
            data.SenderId === currentUser.Id &&
            data.Type === ChatMessageTypes.USER_MESSAGE
        ) {
            return <Sender userImage={data.SenderMediaUrl} data={data} message={data.Content} />;
        } else if (
            data.Type === ChatMessageTypes.USER_MESSAGE &&
            data.SenderId !== currentUser.Id
        ) {
            return (
                <Receiver
                    type={data.Type}
                    userImage={data.SenderMediaUrl}
                    data={data}
                    message={data.Content}
                    fromReport={true}
                />
            );
        }
    };

    const renderDate = (msg, index) => {
        let previousMessage = messages[index - 1];
        let date;

        if (!previousMessage) {
            date = moment(msg.SendDate).format("LLL");
        } else {
            let isPreviousDay = moment(previousMessage.SendDate).isBefore(msg.SendDate, "day");
            if (isPreviousDay) {
                date = moment(msg.SendDate).format("LL");
            }
        }

        return date ? <ChatDateTime date={date} /> : null;
    };

    return (
        <div
            className="report__content-chat fancy-scroll"
            onScroll={handleScroll}
            id="chatbox-scroll-div"
        >
            <div className=" report__content-title d-flex align-items-baseline justify-content-between pb-3">
                <p>{renderNames()}</p>
                <FontAwesomeIcon className="ml-3" icon={faTimes} />
            </div>

            <div className="report__content-message fancy-scroll">
                {fetching && (
                    <div
                        className={`${
                            !Boolean(messages.length) ? "loader-container" : "mt-2 mb-2"
                        }`}
                    >
                        <Loader />
                    </div>
                )}

                {!fetching && !messages.length && (
                    <div className="loader-container">
                        <ChatInfo info="No chats to show" />
                    </div>
                )}

                {Boolean(messages.length) &&
                    messages.map((item, index) => {
                        return (
                            <div key={index}>
                                <div>{renderDate(item, index)} </div>
                                <div> {renderMessage(item)} </div>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

const mapDispatchToProps = {
    getMessageListing,
    clearCurrentChat
};

const mapStateToProps = (state) => {
    return {
        currentUser: state.auth.user
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatWindow);
