import React, { useState, useEffect, useRef } from "react";
import ProfilePicture from "profile-picture";
import { Modal } from "react-responsive-modal";

import "profile-picture/build/ProfilePicture.css";
import "react-responsive-modal/styles.css";
import "./ImageUpload.scss";
import { Button } from "../Button";
import camera from "../../assets/images/camera.png";
import CameraIcon from "../../components/SVGICON/camera-icon";
import imageupload from "../../assets/images/imageupload.png";
import { convertBase64ToFileObj } from "../../utils/commonUtil";
import { toaster } from "../../utils/toasterUtil";

const ImageUpload = (props) => {
    const { isLoading, onApply, previewUrl } = props;
    const [open, setOpen] = useState(false);
    const [imagePreview, setImagePreview] = useState(previewUrl || imageupload);
    const profilePictureRef = useRef(null);

    const onOpenModal = () => setOpen(true);

    const onCloseModal = () => setOpen(false);

    const getSelectedMedia = async () => {
        const profilePicture = profilePictureRef.current;
        if (!profilePicture.state.file) {
            return;
        }
        const imageAsDataURL = profilePicture.getImageAsDataUrl();
        setImagePreview(imageAsDataURL);
        return await convertBase64ToFileObj(imageAsDataURL, profilePicture.state.file);
    };

    const handleApply = async () => {
        let mediaFile = await getSelectedMedia();
        if (mediaFile) {
            if (onApply && typeof onApply === "function") {
                onApply(mediaFile, onCloseModal);
            } else {
                onCloseModal();
            }
        } else {
            toaster({ title: "Please select image.", type: "danger" });
        }
    };

    return (
        <>
            <div className="upload-wrapper d-flex">
                <img className={"image-upload"} src={imagePreview} />
                {/* <img onClick={onOpenModal} className="camera" src={camera} alt="upload" /> */}
                <CameraIcon onClick={onOpenModal} className="camera" />
            </div>
            <Modal
                classNames={{ modal: "setupModal" }}
                open={open}
                onClose={onCloseModal}
                showCloseIcon={false}
                center
            >
                <ProfilePicture ref={profilePictureRef} />
                <div className="d-flex justify-content-center setup__modal pt-5">
                    <Button title="Apply" onClick={handleApply} isLoading={isLoading} />
                    <Button title="Close" onClick={onCloseModal} className="ml-3" />
                </div>
            </Modal>
        </>
    );
};
export default ImageUpload;
