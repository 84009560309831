import React, { Component } from "react";
import "./TermsCondition.scss";
const TermsCondition = (props) => {
    return (
        <>
            <div className="terms">
                <div className="terms-wrapper">
                    <div className="terms-wrapper__content fancy-scroll">
                        <h2>Terms and Conditions</h2>
                        <p className="pt-4">
                            Terms and Conditions for Membership between Halal Connections and the
                            Customer
                        <br />
                            <br />
                            PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY. BY USING THE Halal
                            Connections WEBSITE, YOU AGREE TO BE BOUND BY THESE TERMS AND CONDITIONS.
                        <br />
                            <br />
                            This Internet web site is provided by Halal Connections (‘the Company’).
                            This is a legal agreement between you and Halal Connections . By using this
                            website, you agree to be bound to the following Terms and Conditions. If you
                            do not agree with these terms and conditions then you are not authorised to
                            use or access this web site. The application form is a contractual offer by
                            you to us subject to these Terms and Conditions and no others. On acceptance
                            by us, there exists a binding contractual relationship between you and us.
                            This document (‘The Membership Terms and Conditions’) sets out the
                            relationship between you and the Company. The Company agrees with you as
                            follows: Definitions, In this agreement the following words shall have the
                            following meanings: ‘Us’ refers to Halal Connections and ‘we’ and ‘our’ will
                            be construed accordingly. ‘You’ refers to the customer or user who uses the
                            site subject to these Terms and Conditions and ‘your’ will be construed
                            accordingly. ‘Advertisement’ refers to the Advertisement(s) placed by you on
                            the Halal Connections web site. ‘Site’ means our web site located at
                            www.halalconnections.com, and ‘Content’ means the information and other
                            material available within the Site.
                        <br />
                            <br />
                            Membership Conditions
                        <br />
                            <br /> We are a private members club and will only accept membership
                            applications from single, widowed, divorced or legally separated
                        <br />
                            <br />
                            adults of the Muslim religion, over 18 years of age. No children are
                            eligible to apply for such membership.
                        <br />
                            Your membership is for your sole, personal use only. You may not authorise
                            others to use your membership, and you may not assign or otherwise transfer
                            your account to any other person or entity.
                    </p>
                    </div>
                </div>
            </div>
        </>
    );
};
export default TermsCondition;
