import { fetchUtil } from "../../utils/fetchUtil";
import { getMe } from "./";

export const verifyCurrentPassword = (bodyData) => (dispatch, getState) => {
    const token = getState().auth.user.Token;

    return fetchUtil({
        url: "/user/current-password-verification",
        method: "POST",
        token,
        body: JSON.stringify(bodyData)
    })
        .then((res) => {
            if (res && res.Data) {
                return Promise.resolve(res);
            }
            return Promise.reject(false);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const changePasswordRequest = () => (dispatch, getState) => {
    const token = getState().auth.user.Token;

    return fetchUtil({
        url: "/user/change-password-request",
        method: "POST",
        token
    })
        .then((res) => {
            if (res && res.Data) {
                return Promise.resolve(res.Data);
            }
            return Promise.reject(false);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const checkPasswordVerificationCode = (bodyData) => (dispatch, getState) => {
    const token = getState().auth.user.Token;

    return fetchUtil({
        url: "/user/change-password-verification",
        method: "POST",
        body: JSON.stringify(bodyData),
        token
    })
        .then((res) => {
            return Promise.resolve(res.Data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const changePassword = (bodyData) => (dispatch, getState) => {
    const token = getState().auth.user.Token;

    return fetchUtil({
        url: "/user/matchmaker/change-password",
        method: "POST",
        body: JSON.stringify(bodyData),
        token
    })
        .then((res) => {
            return Promise.resolve(res.Data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const changePhoneNumberRequest = () => (dispatch, getState) => {
    const token = getState().auth.user.Token;

    return fetchUtil({
        url: "/user/change-phone-request",
        method: "POST",
        token
    })
        .then((res) => {
            if (res && res.Data) {
                return Promise.resolve(res.Data);
            }
            return Promise.reject(false);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const checkPhoneVerificationCode = (bodyData) => (dispatch, getState) => {
    const token = getState().auth.user.Token;

    return fetchUtil({
        url: "/user/change-phone-verification",
        method: "POST",
        body: JSON.stringify(bodyData),
        token
    })
        .then(async (res) => {
            return Promise.resolve(res.Data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const updateProfile = (bodyData) => (dispatch, getState) => {
    const token = getState().auth.user.Token;

    return fetchUtil({
        url: "/user/matchmaker/update-profile",
        method: "PATCH",
        body: JSON.stringify(bodyData),
        token
    })
        .then(async (res) => {
            await dispatch(getMe(token));
            return Promise.resolve(res.Data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const contactUs = (payload) => (dispatch, getState) => {
    const token = getState().auth.user.Token;

    return fetchUtil({
        url: "/reporting/contact-us",
        method: "POST",
        body: JSON.stringify(payload),
        token
    })
        .then(async (res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};
