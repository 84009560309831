import React from "react";
import { Switch, Route, useParams } from "react-router-dom";

import PageTitle from "../../components/PageTitle/PageTitle";
import routes from "./routes";
import { SettingRouteTypes } from "../../constants";

const Settings = (props) => {
    const { type } = useParams();

    return (
        <>
            <PageTitle title={SettingRouteTypes.getDisplayTextKey(type)} />
            <Switch>
                {routes.map((route, index) => {
                    return (
                        <Route
                            key={index}
                            path={route.path}
                            name={route.name}
                            exact={route.exact}
                            render={(props) => <route.component {...props} />}
                        />
                    );
                })}
            </Switch>
        </>
    );
};

export default Settings;
