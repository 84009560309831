import React, { useState, useEffect, useRef } from "react";
import { AvForm } from "availity-reactstrap-validation";
import PhoneInput from "react-phone-input-2";
import { connect, useDispatch } from "react-redux";
import parsePhoneNumber from "libphonenumber-js";

import "./UserAccount.scss";
import "profile-picture/build/ProfilePicture.css";
import "react-phone-input-2/lib/style.css";
import ImageUpload from "../../components/ImageUpload/ImageUpload";
import { Button } from "../../components/Button";
import { lockIcon } from "../../assets/images";
import TextInput from "../../components/TextInput/TextInput";
import ChangeAccount from "./ChangeAccount/ChangeAccount";
import SuccessModal from "../../components/SuccessModal/SuccessModal";
import { AccountSettingChangeTypes, CountryCodes } from "../../constants";
import {
    verifyCurrentPassword,
    changePassword,
    updateProfile,
    mediaService
} from "../../store/actions";
import { validatePhone } from "../../utils/commonUtil";
import { EditableText } from "../../components/EditableText";
import { toaster } from "../../utils/toasterUtil";
import BriefcaseIcon from "../../components/SVGICON/briefcase-icon"

const UserAccount = (props) => {
    const { currentUser } = props;
    const [changeSettingType, setChangeSettingType] = useState(null);
    const [isVerified, setIsVerified] = useState(false);
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [newPhoneNumber, setNewPhoneNumber] = useState(null);
    const [isValidPhone, setisValidPhone] = useState(true);
    const [showChangeConfirmation, setShowChangeConfirmation] = useState(false);
    const [passwordLoading, setPasswordLoading] = useState(false);
    const [phoneLoading, setPhoneLoading] = useState(false);
    const [mediaLoading, setMediaLoading] = useState(false);
    const [nameLoading, setNameLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const dispatch = useDispatch();
    const formRef = useRef(null);
    const successType = useRef(AccountSettingChangeTypes.PASSWORD);
    const phoneInputTouched = useRef(null);

    const handleChangePhoneSubmit = () => {
        if (changeSettingType === AccountSettingChangeTypes.PHONE && isVerified) {
            updatePhoneNumber();
        } else {
            setIsVerified(false);
            setChangeSettingType(AccountSettingChangeTypes.PHONE);
            setShowChangeConfirmation(true);
        }
    };

    const updatePhoneNumber = () => {
        if (!validatePhone(newPhoneNumber)) {
            setisValidPhone(validatePhone(newPhoneNumber));
            return;
        }

        let phoneNumber = parsePhoneNumber(
            newPhoneNumber.value,
            newPhoneNumber.countryCode.toUpperCase()
        );

        setPhoneLoading(true);
        const bodyData = {
            Phone: { Number: phoneNumber.nationalNumber, Extension: `+${newPhoneNumber.dialCode}` }
        };
        dispatch(updateProfile(bodyData))
            .then((res) => {
                setNewPhoneNumber("");
                setChangeSettingType(null);
                setIsVerified(false);
                successType.current = AccountSettingChangeTypes.PHONE;
                setIsSuccess(true);
            })
            .catch((err) => {})
            .finally(() => {
                setPhoneLoading(false);
            });
    };

    const handleChangePasswordSubmit = () => {
        if (changeSettingType === AccountSettingChangeTypes.PASSWORD && isVerified) {
            updatePassword();
        } else {
            verifyPassword();
        }
    };

    const verifyPassword = () => {
        setIsVerified(false);
        setPasswordLoading(true);
        const bodyData = {
            CurrentPassword: currentPassword
        };
        dispatch(verifyCurrentPassword(bodyData))
            .then((res) => {
                setChangeSettingType(AccountSettingChangeTypes.PASSWORD);
                setShowChangeConfirmation(true);
            })
            .catch((err) => {})
            .finally(() => {
                setPasswordLoading(false);
            });
    };

    const updatePassword = () => {
        setPasswordLoading(true);
        const bodyData = {
            OldPassword: currentPassword,
            NewPassword: newPassword,
            ConfirmPassword: confirmPassword
        };
        dispatch(changePassword(bodyData))
            .then((res) => {
                formRef.current.reset();
                setCurrentPassword("");
                setNewPassword("");
                setConfirmPassword("");
                setChangeSettingType(null);
                setIsVerified(false);
                successType.current = AccountSettingChangeTypes.PASSWORD;
                setIsSuccess(true);
            })
            .catch((err) => {})
            .finally(() => {
                setPasswordLoading(false);
            });
    };

    const handleVerificationSuccess = () => {
        setIsVerified(true);
    };

    const handleImageSelect = async (imageFile, closeModalFn) => {
        setMediaLoading(true);
        let media = await dispatch(mediaService(imageFile));
        const bodyData = {
            MediaId: media?.Data?.Id
        };
        dispatch(updateProfile(bodyData))
            .then((res) => {
                closeModalFn();
                toaster({ title: "Profile picture updated successfully" });
            })
            .catch((err) => {})
            .finally(() => {
                setMediaLoading(false);
            });
    };

    const handleNameUpdate = (updatedName, closeEditFn) => {
        setNameLoading(true);

        const bodyData = {
            FullName: updatedName
        };
        dispatch(updateProfile(bodyData))
            .then((res) => {
                closeEditFn();
                toaster({ title: "Name updated successfully" });
            })
            .catch((err) => {})
            .finally(() => {
                setNameLoading(false);
            });
    };

    return (
        <>
            <div className="user-account__wrapper fancy-scroll">
                <div className="user-account__upload d-flex flex-column justify-content-center pt-3 pt-md-5">
                    <ImageUpload
                        previewUrl={currentUser.MediaUrl}
                        onApply={handleImageSelect}
                        isLoading={mediaLoading}
                    />
                    <EditableText
                        content={currentUser.FullName}
                        onSubmit={handleNameUpdate}
                        isLoading={nameLoading}
                    />
                </div>
                <div className="d-flex flex-column flex-md-row justify-content-around pt-4">
                    <div className="user-account__password text-center mb-5 mb-md-0">
                        <AvForm onValidSubmit={handleChangePasswordSubmit} ref={formRef}>
                            <TextInput
                                name="password"
                                placeholder="Current Password"
                                required={true}
                                type="password"
                                inputIcon={lockIcon}
                                customIcon={<BriefcaseIcon className="pointer"/>}
                                value={currentPassword}
                                onChange={(e) => setCurrentPassword(e.target.value)}
                            />
                            {isVerified &&
                                changeSettingType === AccountSettingChangeTypes.PASSWORD && (
                                    <>
                                        <TextInput
                                            name="new-password"
                                            placeholder="New Password"
                                            required={true}
                                            type="password"
                                            inputIcon={lockIcon}
                                            value={newPassword}
                                            onChange={(e) => setNewPassword(e.target.value)}
                                        />
                                        <TextInput
                                            name="confirm-password"
                                            placeholder="Confirm the Password"
                                            validate={{
                                                required: { value: true },
                                                match: {
                                                    value: "new-password",
                                                    errorMessage:
                                                        "Confirm Password and New Password should be same."
                                                }
                                            }}
                                            type="password"
                                            inputIcon={lockIcon}
                                            value={confirmPassword}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                        />
                                    </>
                                )}
                            <Button
                                className="mt-4"
                                title="Change Password"
                                isLoading={passwordLoading}
                            />
                        </AvForm>
                    </div>
                    <div className="user-account__contact text-center">
                        <PhoneInput
                            name="PhoneDisplay"
                            value={`${currentUser.Phone?.Extension}${currentUser.Phone?.Number}`}
                            labelText={"Current Phone Number"}
                            autoFormat={true}
                            disableSearchIcon={true}
                            enableSearch={true}
                            countryCodeEditable={false}
                            disabled={true}
                        />
                        {isVerified && changeSettingType === AccountSettingChangeTypes.PHONE && (
                            <>
                                <PhoneInput
                                    inputClass={!isValidPhone && "custom-inp-error"}
                                    name="PhoneInput"
                                    country={CountryCodes[
                                        currentUser.Phone?.Extension?.slice(1)
                                    ].toLowerCase()}
                                    labelText="New Phone Number"
                                    autoFormat={true}
                                    onBlur={() => {
                                        setisValidPhone(validatePhone(newPhoneNumber));
                                        phoneInputTouched.current = true;
                                    }}
                                    disableSearchIcon={true}
                                    enableSearch={true}
                                    countryCodeEditable={false}
                                    onChange={(value, data) => {
                                        setNewPhoneNumber({ value: value, ...data });
                                        if (phoneInputTouched.current) {
                                            setisValidPhone(
                                                validatePhone({ value: value, ...data })
                                            );
                                        }
                                    }}
                                />
                                {!isValidPhone && (
                                    <p className="custom-inp-error-message">
                                        Please enter a valid phone number
                                    </p>
                                )}
                            </>
                        )}
                        <Button
                            title="Change Phone No"
                            onClick={handleChangePhoneSubmit}
                            isLoading={phoneLoading}
                            className="mt-4"
                        />
                    </div>
                    <ChangeAccount
                        type={changeSettingType}
                        showChangeConfirmation={showChangeConfirmation}
                        setShowChangeConfirmation={setShowChangeConfirmation}
                        onCancel={() => setChangeSettingType(null)}
                        onSuccess={handleVerificationSuccess}
                    />
                    <SuccessModal
                        open={isSuccess}
                        title={
                            successType.current === AccountSettingChangeTypes.PASSWORD
                                ? "Change Password"
                                : "Change Phone Number"
                        }
                        description={`Your ${
                            successType.current === AccountSettingChangeTypes.PASSWORD
                                ? "password"
                                : "phone number"
                        } has been changed`}
                        name="OK"
                        onCloseModal={() => setIsSuccess(false)}
                        btnAction={() => setIsSuccess(false)}
                    />
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        currentUser: state.auth.user
    };
};

export default connect(mapStateToProps, null)(UserAccount);
