import React from "react";
import { Modal } from "react-responsive-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";

import "./ConfirmSuspension.scss";
import "react-responsive-modal/styles.css";

import Button from "../Button/Button";

const ConfirmSuspension = (props) => {
    const {
        open = false,
        onModalClose,
        loading,
        onYesPress = () => {},
        selectedUser = null,
        suspensionDays = ""
    } = props;

    return (
        <Modal
            classNames={{ modal: "confirmsuspensionmodal" }}
            open={open}
            onClose={onModalClose}
            showCloseIcon={false}
            center
            closeOnOverlayClick={false}
        >
            <FontAwesomeIcon className="back-icon" icon={faAngleLeft} onClick={onModalClose} />
            <div className="d-flex flex-column justify-content-center align-items-center confirmsuspend">
                <p>Are you sure?</p>
                <p className="text-center py-4 main-description">
                    Suspending {selectedUser?.Name} will put their chats, connections and profile on
                    hold for {suspensionDays} days{" "}
                </p>
                <div className="d-flex ">
                    <Button title="Yes" isLoading={loading} onClick={onYesPress} />

                    {!loading && <Button title="No" className="ml-5" onClick={onModalClose} />}
                </div>
            </div>
        </Modal>
    );
};
export default ConfirmSuspension;
