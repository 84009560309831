import React, { useEffect, useRef, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import uuidv4 from "uuid/v4";

import "./ActiveChat.scss";

import ChatInfo from "../../components/ChatInfo/ChatInfo";
import ChatHeader from "../../components/ChatHeader/ChatHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import {
    getMessageListing,
    clearCurrentChat,
    getChatById,
    setCurrentChat,
    updateChatUnreadCount
} from "../../store/actions";
import { Socket } from "../../utils/socketUtils";
import { scrollToBottom } from "../../utils/domUtil";
import { Spinner } from "../../components/Loader";
import ChatMessageList from "./ChatMessageList";
import ChatInputMessage from "./ChatInputMessage";
import { escapeHTMLElementsFromMessage } from "../../utils/commonUtil";
import { UserTypes, ChatTabs, ChatMessageTypes } from "../../constants";
import {
    updateChatMessageId,
    updateChatMessageData,
    moveChatToTop,
    addMessage,
    updateChatInEntity
} from "../../store/actions";

const ChatMessageSection = (props) => {
    const {
        currentChat,
        fetching,
        dataAvailable,
        messages,
        currentUser,
        mobileview,
        detailHandler
    } = props;
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const { id, tabname } = useParams();
    const dispatch = useDispatch();
    const chatMessageAbortController = useRef(null);
    const location = useLocation();

    const isPreviousChatRoute = () => {
        return location.pathname.includes("previous-chats");
    };

    useEffect(() => {
        chatMessageAbortController.current = new AbortController();
        Socket.joinChat(id);
        dispatch(getMessageListing(id, {}, chatMessageAbortController.current.signal))
            .then((res) => {
                if (currentChat && !currentChat?.LastMessageSeen) {
                    dispatch(updateChatUnreadCount(tabname));
                }
                dispatch(updateChatInEntity(id, { LastMessageSeen: true }));
                Socket.readMessage(id);
                setTimeout(() => {
                    scrollToBottom();
                }, 30);
            })
            .catch((err) => {});

        return () => {
            setPage(1);
            dispatch(clearCurrentChat());
            if (chatMessageAbortController.current) {
                chatMessageAbortController.current.abort();
            }
        };
    }, [id, dispatch]);

    useEffect(() => {
        if (!currentChat) {
            setIsLoading(true);
            dispatch(getChatById(id)).finally(() => {
                setIsLoading(false);
            });
        } else {
            dispatch(setCurrentChat(parseInt(id), currentChat?.Customer?.Id));
        }
    }, [id, currentChat, dispatch]);

    const handleScroll = (e) => {
        const isTop = e.target.scrollTop < 30;

        if (isTop && !fetching && dataAvailable) {
            let container = document.getElementById("chatbox-scroll-div");
            let prevScrollTop = container.scrollTop;
            let prevScrollHeight = container.scrollHeight;
            let currentPage = page + 1;
            dispatch(getMessageListing(id, { Page: currentPage })).then((res) => {
                container.scrollTop = container.scrollHeight - (prevScrollHeight - prevScrollTop);
            });
            setPage(currentPage);
        }
    };

    const handleMessageSent = ({ messageContent, media }) => {
        const hasMedia = media && media.Id;
        const uniqueId = uuidv4();
        const escapedMessage = escapeHTMLElementsFromMessage(messageContent);

        const socketMsgData = {
            ChatId: currentChat.ChatId,
            Content: escapedMessage,
            ...(hasMedia && { AttachmentIds: [media.Id] })
        };

        const addMsgData = {
            Id: uniqueId,
            CreatedAt: Date.now(),
            UpdatedAt: Date.now(),
            ChatId: currentChat.ChatId,
            SenderId: currentUser.Id,
            SenderType: UserTypes.MATCH_MAKER,
            Content: escapedMessage,
            ...(hasMedia && { Attachments: [media] }),
            SenderMediaUrl: currentUser?.Media?.ThumbPath,
            Name: currentUser.FullName,
            SendDate: Date.now(),
            Type: hasMedia ? ChatMessageTypes.MEDIA_MESSAGE : ChatMessageTypes.USER_MESSAGE
        };

        Socket.sendMessage(socketMsgData, async (data) => {
            dispatch(updateChatMessageId(uniqueId, data?.ChatEvent?.Id));
            await dispatch(
                updateChatMessageData(currentChat.ChatId, {
                    ...data.ChatEvent,
                    LastMessageSeen: true
                })
            );
            dispatch(
                moveChatToTop(
                    currentChat.ChatId,
                    tabname === ChatTabs.SINGLE ? ChatTabs.SINGLE : ChatTabs.GROUP
                )
            );
        });
        Socket.readMessage(currentChat.ChatId);
        dispatch(addMessage(addMsgData));
    };

    return !isLoading && currentChat ? (
        <>
            <div className="active-chat__header d-flex align-items-center pl-2 pl-md-5">
                {mobileview ? (
                    <FontAwesomeIcon
                        icon={faAngleLeft}
                        onClick={detailHandler}
                        className="back-icon mr-4"
                    />
                ) : null}
                <ChatHeader
                    active={true}
                    chatId={id}
                    group={tabname === ChatTabs.GROUP ? true : false}
                />
            </div>
            <div
                className="active-chat__body fancy-scroll"
                onScroll={handleScroll}
                id="chatbox-scroll-div"
                style={{height: 'calc(100vh - 370px)'}}
            >
                {fetching && (
                    <Spinner
                        classes={!messages.length ? "h-100" : ""}
                        {...(messages.length && { size: "sm" })}
                    />
                )}
                {!fetching && !messages.length && (
                    <div className="d-flex justify-content-center align-items-center h-100">
                        <ChatInfo info="No chats to show" />
                    </div>
                )}
                {!!messages.length && (
                    <ChatMessageList messages={messages} currentChat={currentChat} />
                )}
            </div>
            {!isPreviousChatRoute() && <ChatInputMessage onMessageSent={handleMessageSent} />}
        </>
    ) : isLoading ? (
        <Spinner classes="h-100" />
    ) : null;
};

const mapStateToProps = (state, ownProps) => {
    const currentChat = state.entities.chats[ownProps.match.params?.id];
    return {
        currentChat,
        fetching: state.chat.currentChat.fetching,
        messages: state.chat.currentChat.messages,
        dataAvailable: state.chat.currentChat.dataAvailable,
        currentUser: state.auth.user
    };
};

export default connect(mapStateToProps)(ChatMessageSection);
