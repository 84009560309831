import React from "react";

const Tooltip = ({
    continuous,
    index,
    step,
    backProps,
    closeProps,
    primaryProps,
    tooltipProps,
    isLastStep,
    size,
    skipProps
}) => {
    return (
        <div>
            {step.content(
                backProps,
                primaryProps,
                closeProps,
                tooltipProps,
                index,
                continuous,
                skipProps
            )}
        </div>
    );
};

export default Tooltip;
