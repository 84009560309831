
import React, { Component } from 'react';
const BriefcaseIcon = (props) => {
    return (

        <svg className={props.className} onClick={props.onClick} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.96112 6.79688C8.05501 6.79688 8.95001 7.56152 8.95001 8.49609C8.95001 9.43066 8.05501 10.1953 6.96112 10.1953C5.86723 10.1953 4.97223 9.43066 4.97223 8.49609C4.97223 7.56152 5.86723 6.79688 6.96112 6.79688ZM6.96112 1.69922C5.86723 1.69922 4.97223 2.46387 4.97223 3.39844H8.95001C8.95001 2.46387 8.05501 1.69922 6.96112 1.69922ZM11.9333 13.5938H1.98889C0.895001 13.5938 0 12.8291 0 11.8945V5.09766C0 4.16309 0.895001 3.39844 1.98889 3.39844H2.98334C2.98334 1.5293 4.77334 0 6.96112 0C9.1489 0 10.9389 1.5293 10.9389 3.39844H11.9333C13.0272 3.39844 13.9222 4.16309 13.9222 5.09766V11.8945C13.9222 12.8291 13.0272 13.5938 11.9333 13.5938Z" fill="#828282"/>
        </svg>

    );
}
export default BriefcaseIcon