import React from "react";

import "./ReportItem.scss";

const ReportItem = (props) => {
    const { label = "", text = "" } = props;
    return (
        <div className="report__info-detail d-flex align-items-baseline pb-2">
            <h4>{label}:</h4>
            <p className="pl-2">{text}</p>
        </div>
    );
};
export default ReportItem;
