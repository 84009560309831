import React from "react";
import { AvForm } from "availity-reactstrap-validation";

import "./SuspensionModal.scss";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { TextInput } from "../../components/TextInput";

import Button from "../Button/Button";

const SuspensionModal = (props) => {
    const {
        open = false,
        onModalClose = () => {},
        loading = false,
        onNextPress = () => {},
        remarksValue = "",
        setRemarksValue = () => {},
        suspensionDays = "",
        setSuspensionDays = () => {}
    } = props;

    return (
        <>
            <Modal
                classNames={{ modal: "suspendModal" }}
                open={open}
                onClose={onModalClose}
                showCloseIcon={false}
                center
                closeOnOverlayClick={false}
            >
                <FontAwesomeIcon
                    className="back-icon ml-3"
                    onClick={onModalClose}
                    icon={faAngleLeft}
                />
                <div className="d-flex flex-column align-items-center justify-content-center suspend__modal ">
                    <h4 className="pb-3">What will be the duration of this suspension?</h4>
                    <div className="duration">
                        <ul className="filter-list__radio d-flex justify-content-evenly">
                            <li>
                                <input
                                    type="radio"
                                    name="rado-group"
                                    id="7"
                                    checked={suspensionDays === "7"}
                                    onChange={(e) => setSuspensionDays(e.target.id)}
                                />
                                <label for="7">7 Days</label>
                            </li>
                            <li>
                                <input
                                    type="radio"
                                    name="rado-group"
                                    id="14"
                                    checked={suspensionDays === "14"}
                                    onChange={(e) => setSuspensionDays(e.target.id)}
                                />
                                <label for="14">14 Days</label>
                            </li>
                        </ul>
                    </div>
                    <h4 className="pt-3 pb-3">Please type your remarks below:</h4>
                    <AvForm className="text-center" onValidSubmit={onNextPress}>
                        <div className="d-flex position-relative">
                            <TextInput
                                type="textarea"
                                placeholder="Please tell us your remarks..."
                                required
                                name="remarksValue"
                                rows="12"
                                cols="60"
                                value={remarksValue}
                                onChange={(e) => setRemarksValue(e.target.value)}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: "Description is required."
                                    }
                                }}
                                maxlength="300"
                                className="mt-1 mb-3"
                            />
                            {/* <textarea
                                className="mt-1 mb-3"
                                rows="12"
                                cols="50"
                                value={remarksValue}
                                placeholder="Please tell us your remark..."
                                maxlength="300"
                                onChange={(e) => setRemarksValue(e.target.value)}
                            ></textarea> */}
                            <span className="comment-limit">{remarksValue.length}/300</span>
                        </div>
                        <Button onClick={onNextPress} title="Send" isLoading={loading} />
                    </AvForm>
                </div>
            </Modal>
        </>
    );
};
export default SuspensionModal;
