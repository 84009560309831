import { injectBaseConstantMethods } from "./BaseConstants";

const EducationLevel = {
    HIGH_SCHOOL: 1,
    UNDERGRADUATE: 2,
    POSTGRADUATE: 3
};

const displayTextKeys = {
    [EducationLevel.HIGH_SCHOOL]: "High School",
    [EducationLevel.UNDERGRADUATE]: "Undergraduate",
    [EducationLevel.POSTGRADUATE]: "Postgraduate"
};

const labelClass = {
    [EducationLevel.HIGH_SCHOOL]: "",
    [EducationLevel.UNDERGRADUATE]: "",
    [EducationLevel.POSTGRADUATE]: ""
};

export default injectBaseConstantMethods(EducationLevel, displayTextKeys, labelClass);
