import React, { useState, useEffect } from "react";

import "./ProfileCard.scss";

import matches from "../../assets/images/matches.png";
import referal from "../../assets/images/referal.png";
import chats from "../../assets/images/chats.png";
import { getMediaThumbPath } from "../../utils/mediaUtils";
import imageupload from "../../assets/images/imageupload.png";
import $ from "jquery";
import MatchIcon from "../SVGICON/match-icon";
import ReferalIcon from "../SVGICON/referal-icon";
import CurrentChat from "../SVGICON/current-chat";

const width = window.innerWidth;

const ProfileCard = (props) => {
    const { isSelected = false } = props;

    const [mobileview, setMobileview] = useState(width < 1100);

    const { data, onClick = () => {} } = props;
    $(".scroll-top").on("click", function () {
        $(window).scrollTop(0);
    });

    const ScrollEvent = () => {
        window.scrollBy(0, 30);
    };

    useEffect(() => {
        $(window).resize(() => {
            const width = window.innerWidth;
            const mobileview = width < 1100;
            setMobileview(mobileview);
        });
        $(".sidebar").css({ display: "block" });
    }, []);

    const renderStyles = () => {
        const selectedClass = isSelected ? "selectedCard" : "";

        if (mobileview) {
            return (
                " d-flex align-items-center justify-content-between profile-card__wrapper scroll-top " +
                selectedClass
            );
        }
        return (
            " d-flex align-items-center justify-content-between profile-card__wrapper  " +
            selectedClass
        );
    };

    return (
        <div
            onClick={() => {
                onClick();
                ScrollEvent();
            }}
            className={renderStyles()}
        >
            <div className={"d-flex align-items-center profile-card__profile "}>
                <img
                    src={getMediaThumbPath(data?.User?.Media) || imageupload}
                    alt="profile-pic"
                    className={`profile-avatar`}
                />
                <p className="px-3 text-truncate">{data?.User?.FullName || "-"}</p>
            </div>
            <div className="profile-card__info pl-3">
                <div className="d-flex align-items-center spacer">
                    <MatchIcon className="match__icon" />
                    <p className="pl-3 text-truncate"> {data?.TotalMatches} Current Matches </p>
                </div>
                <div className="d-flex align-items-center spacer">
                    <ReferalIcon className="match__icon" />
                    <p className="pl-3 text-truncate">{data?.ReferralsNeeded} Referrals Needed </p>
                </div>
                <div className="d-flex align-items-center">
                    <CurrentChat className="match__icon active-chat-icon" />
                    <p className="pl-3 text-truncate">{data?.TotalConnections} Active Chats</p>
                </div>
            </div>
        </div>
    );
};

export default ProfileCard;
