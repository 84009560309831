import React from "react";
import "./ChatInfo.scss";

const ChatInfo = (props) => {
    return (
        <>
            <p className="chat-info">{props.info}</p>
        </>
    );
};
export default ChatInfo;
