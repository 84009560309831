import { injectBaseConstantMethods } from "./BaseConstants";

const UserSect = {
    SHIAH: 1,
    SUNNI: 2
};

const displayTextKeys = {
    [UserSect.SHIAH]: "Shiah",
    [UserSect.SUNNI]: "Sunni"
};

const labelClass = {
    [UserSect.SHIAH]: "",
    [UserSect.SUNNI]: ""
};

export default injectBaseConstantMethods(UserSect, displayTextKeys, labelClass);
