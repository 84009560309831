import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import "./ChatProfile.scss";
import "react-responsive-modal/styles.css";

import { Link } from "react-router-dom";
import { getRoute, APP_ROUTES } from "../../helpers/routeHelpers";
import { UserStatus } from "../../constants";

const ChatProfile = (props) => {
    const {
        data,
        info = true,
        onUserClick = () => {},
        selectedUser = null,
        fromReport = false
    } = props;

    const location = useLocation();

    useEffect(() => {
        if (isSingleChat() && onUserClick && typeof onUserClick === "function" && data) {
            onUserClick(data);
        }
    }, []);

    const isSingleChat = () => {
        return location.pathname.includes("single");
    };

    const isPrevChat = () => {
        return location.pathname.includes("previous-chats");
    };

    const isDeleted = () => {
        return (
            data.ChatParticipantStatus === UserStatus.DELETED ||
            data.ChatParticipantStatus === UserStatus.ADMIN_DELETED
        );
    };

    const renderBlur = () => {
        if (isDeleted()) {
            return "image-blur";
        }
        return "";
    };

    const renderSelected = () => {
        if (selectedUser && selectedUser?.UserId === data.UserId) {
            return " selected";
        }
        return "";
    };

    return (
        <>
            <div
                className={`chat-profile d-flex flex-column align-items-center text-center  pointer`}
                onClick={() => onUserClick(data)}
            >
                <img className={renderBlur() + renderSelected()} src={data.MediaPath} />
                <h4 className="my-2 ">{data.Name}</h4>
                {!isPrevChat() && info && !fromReport && (
                    <Link to={getRoute(APP_ROUTES.USER_DETAIL, { id: data.UserId })}>
                        see profile
                    </Link>
                )}

                {isPrevChat() && (
                    <div onClick={() => onUserClick(data)}>
                        <Link
                            to={
                                !isDeleted() &&
                                getRoute(APP_ROUTES.USER_DETAIL, { id: data.UserId })
                            }
                        >
                            see profile
                        </Link>
                    </div>
                )}
            </div>
        </>
    );
};
export default ChatProfile;
