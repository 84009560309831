
import React, { Component } from 'react';
const ChatSingle = (props) => {
    return (
        <svg onClick={props.onClick} className={props.className} width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.4775 22.8265V20.6526C18.4775 19.4994 18.0194 18.3935 17.204 17.5782C16.3886 16.7628 15.2827 16.3047 14.1296 16.3047H5.43382C4.28069 16.3047 3.17479 16.7628 2.3594 17.5782C1.54402 18.3935 1.08594 19.4994 1.08594 20.6526V22.8265" stroke="#9D9D9D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M9.78343 11.9575C12.1847 11.9575 14.1313 10.0109 14.1313 7.6096C14.1313 5.20833 12.1847 3.26172 9.78343 3.26172C7.38216 3.26172 5.43555 5.20833 5.43555 7.6096C5.43555 10.0109 7.38216 11.9575 9.78343 11.9575Z" stroke="#9D9D9D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    );
}
export default ChatSingle