import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Spinner } from "reactstrap";
import { DebounceInput } from "react-debounce-input";

import "./UserListing.scss";

import ProfileCard from "../../components/ProfileCard/ProfileCard";
import PageTitle from "../../components/PageTitle/PageTitle";
import UserSummary from "../../components/UserSummary/UserSummary";
import SearchBox from "../../components/SearchBox/SearchBox";
//import { MultiSelect } from "../../components/MultiSelect";
import { getUsersListing } from "../../store/actions";
import { GenderType, UserStatus } from "../../constants";

const width = window.innerWidth;

const SORTING_OPTIONS = [
    { key: "A to Z" },
    { key: "Z to A" },
    { key: "Matches  1 to 6" },
    { key: "Matches  6 to 1" },
    { key: "New to Old" },
    { key: "Old to New" }
];

const GENDER_OPTIONS = [{ key: "Male" }, { key: "Female" }];

const FILTER_OPTIONS = [
    { key: "Unverified" },
    { key: "Verified" },
    { key: "Completing Profile" },
    { key: "Match Ready" },
    { key: "Selecting Preferences" },
    { key: "Suspended" },
    { key: "Removed" },
    { key: "Id Verification Pending" }
];

const FILTER_COLUMNS = {
    ["Unverified"]: UserStatus.UNVERIFIED,
    ["Verified"]: UserStatus.VERIFIED,
    ["Completing Profile"]: UserStatus.COMPLETING_PROFILE,
    ["Match Ready"]: UserStatus.MATCH_READY,
    ["Selecting Preferences"]: UserStatus.SELECTING_PREFERENCES,
    ["Suspended"]: UserStatus.SUSPENDED,
    ["Removed"]: UserStatus.REMOVED,
    ["Id Verification Pending"]: UserStatus.ID_VERIFICATION_PENDING,
    ["Deleted"]: UserStatus.DELETED,
    ["Admin Deleted"]: UserStatus.ADMIN_DELETED
};

const GENDER_FILTER_COLUMNS = {
    ["Male"]: GenderType.MALE,
    ["Female"]: GenderType.FEMALE,
    ["Other"]: GenderType.OTHER
};

const SORT_COLUMNS = {
    ["A to Z"]: { value: "ASC", column: "FullName" },
    ["Z to A"]: { value: "DESC", column: "FullName" },
    ["Matches  1 to 6"]: { value: "ASC", column: "TotalMatches" },
    ["Matches  6 to 1"]: { value: "DESC", column: "TotalMatches" },
    ["New to Old"]: { value: "DESC", column: "CreatedAt" },
    ["Old to New"]: { value: "ASC", column: "CreatedAt" }
};

const UserListing = (props) => {
    const { getUsersListing, data, fetching, totalUsers } = props;

    const [page, setPage] = useState(1);
    const [currentSortOrder, setCurrentSortOrder] = useState(null);
    const [currentSortColumn, setCurrentSortColumn] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [filter, setFilter] = useState(false);
    const [genderFilter, setGenderFilter] = useState(null);
    const [selectedSort, setSelectedSort] = useState(null);
    const [searchText, setSearchText] = useState("");
    const [autoFocus, setAutoFocus] = useState(false);

    const userListingAbortController = useRef(null);

    useEffect(() => {
        userListingAbortController.current = new AbortController();

        getUsersListing({
            Page: page,
            ...(currentSortOrder && {
                Direction: currentSortOrder
            }),
            ...(currentSortColumn && {
                Column: currentSortColumn
            }),
            ...((filter || filter === 0) && {
                Status: filter
            }),
            ...(searchText && { Q: searchText }),
            ...(genderFilter && { Gender: genderFilter })
        }).then((res) => {
            handleLoadMore(res?.Users, res.Count);
        });

        return () => {
            if (userListingAbortController.current) {
                userListingAbortController.current.abort();
            }
        };
    }, [
        page,
        currentSortOrder,
        currentSortColumn,
        getUsersListing,
        searchText,
        filter,
        genderFilter
    ]);

    useEffect(() => {
        function scrollListner(e) {
            const bottom = window.innerHeight + window.scrollY >= document.body.offsetHeight - 50;

            if (bottom && data.length < totalUsers && !fetching) {
                setPage(page + 1);
            }
        }

        window.addEventListener("scroll", scrollListner);

        return () => {
            window.removeEventListener("scroll", scrollListner);
        };
    }, [data]);

    useEffect(() => {
        function handleWindowResize() {
            handleLoadMore();
        }

        window.addEventListener("resize", handleWindowResize);

        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, [data]);

    const handleLoadMore = (newData = [], total = totalUsers) => {
        const isScrollable = document.body.scrollHeight > window.innerHeight;

        if (!isScrollable && !fetching) {
            if ([...data, ...newData].length < total) {
                setPage(page + 1);
            }
        }
    };

    const onOptionSelected = (option) => {
        let selected = SORT_COLUMNS[option.key];
        setSelectedSort(option.key);

        setPage(1);
        setSelectedUser(null);
        setCurrentSortOrder(selected.value);
        setCurrentSortColumn(selected.column);
    };

    const filterSelected = (option) => {
        let selected = FILTER_COLUMNS[option];

        setSelectedUser(null);
        setPage(1);
        if (selected === filter) {
            setFilter(null);
            return;
        }

        setFilter(selected);
    };

    const genderFilterSelected = (option) => {
        let selected = GENDER_FILTER_COLUMNS[option];

        setSelectedUser(null);
        setPage(1);
        if (selected === genderFilter) {
            setGenderFilter(null);
            return;
        }

        setGenderFilter(selected);
    };

    const onOptionRemoved = (option) => {
        setPage(1);
        setCurrentSortOrder(null);
        setCurrentSortColumn(null);
    };

    const handleUserSelect = (user) => {
        setSelectedUser(user);

        setTimeout(() => {
            document.documentElement.scrollTop += 10;
        }, 0);
    };

    const handleUserSearch = (e) => {
        e.preventDefault();
        setAutoFocus(true);
        setPage(1);
        setSearchText(e.target.value);
    };

    const renderSearch = (props) => {
        return <SearchBox classes="w-250" {...props} autoFocus={autoFocus ? "autoFocus" : ""} />;
    };

    return (
        <div>
            <PageTitle title="Users" />
            {/* <MultiSelect
                options={SORTING_OPTIONS}
                onOptionSelect={onOptionSelected}
                onOptionRemoved={onOptionRemoved}
            /> */}
            <div className={"dropdown mb-5 d-flex"}>
                <div>
                    <button
                        className="dropdown-btn dropdown-toggle"
                        type="button"
                        id="dropdownMenuButtonSort"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        Sort by
                    </button>
                    <div className="dropdown-menu">
                        <ul className="dropdown-option-list">
                            {SORTING_OPTIONS
                                ? SORTING_OPTIONS.map((obj, index) => {
                                      return (
                                          <li>
                                              <input
                                                  className="styled-checkbox"
                                                  id={obj.key}
                                                  //   name="radio-group"
                                                  checked={selectedSort === obj.key}
                                                  type="radio"
                                                  value={obj.key}
                                                  onClick={() => onOptionSelected(obj)}
                                              />
                                              <label for={obj.key}>{obj.key}</label>
                                          </li>
                                      );
                                  })
                                : null}
                        </ul>
                    </div>
                </div>
                <div>
                    <button
                        className="ml-5 dropdown-btn dropdown-toggle"
                        type="button"
                        id="dropdownMenuButtonFilter"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        Filters
                    </button>

                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButtonFilter">
                        <ul className="dropdown-option-list">
                            {FILTER_OPTIONS.map((item, index) => {
                                return (
                                    <li>
                                        <input
                                            className="styled-checkbox"
                                            id={item.key}
                                            // name="radio-group"
                                            type="radio"
                                            checked={filter === FILTER_COLUMNS[item.key]}
                                            onClick={() => filterSelected(item.key)}
                                            value={item.key}
                                        />
                                        <label for={item.key}>{item.key}</label>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
                <div>
                    <button
                        className="dropdown-btn dropdown-toggle"
                        type="button"
                        id="dropdownMenuButtonSort"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        Gender
                    </button>
                    <div className="dropdown-menu test">
                        <ul className="dropdown-option-list">
                            {GENDER_OPTIONS
                                ? GENDER_OPTIONS.map((item, index) => {
                                      return (
                                          <li>
                                              <input
                                                  className="styled-checkbox"
                                                  id={item.key}
                                                  // name="radio-group"
                                                  type="radio"
                                                  checked={
                                                      genderFilter ===
                                                      GENDER_FILTER_COLUMNS[item.key]
                                                  }
                                                  onClick={() => genderFilterSelected(item.key)}
                                                  value={item.key}
                                              />
                                              <label for={item.key}>{item.key}</label>
                                          </li>
                                      );
                                  })
                                : null}
                        </ul>
                    </div>
                </div>
            </div>

            <div className="mb-5 d-flex flex-row justify-content-end">
                <DebounceInput
                    element={renderSearch}
                    debounceTimeout={500}
                    placeholder="Search"
                    value={searchText}
                    onChange={handleUserSearch}
                />
            </div>

            <div className="d-flex flex-column-reverse flex-lg-row align-items-center align-items-lg-start">
                <div
                    className={
                        selectedUser
                            ? "d-flex flex-column detailed "
                            : "row d-flex justify-content-start w-100 m-3 ml-md-4 "
                    }
                >
                    {Boolean(data.length) &&
                        data.map((obj, index) => {
                            return (
                                <ProfileCard
                                    isSelected={Boolean(
                                        obj?.User?.UserId === selectedUser?.User?.UserId
                                    )}
                                    key={index}
                                    onClick={() => handleUserSelect(obj)}
                                    detail={false}
                                    data={obj}
                                />
                            );
                        })}
                </div>
                {selectedUser && (
                    <UserSummary
                        currentUser={selectedUser}
                        handleClose={() => setSelectedUser(null)}
                    />
                )}
            </div>
            {!fetching && !data.length && (
                <div className="d-flex flex-row justify-content-center">
                    <h5>Currently No Data Available</h5>
                </div>
            )}
            {fetching && (
                <div className="d-flex flex-row justify-content-center loader-padding">
                    <p className="mr-2">Loading</p>
                    <Spinner size={"40"} color={"primary"} />
                </div>
            )}
        </div>
    );
};

const mapDispatchToProps = {
    getUsersListing
};

const mapStateToProps = (state) => {
    const { users } = state;

    return {
        data: users.list,
        fetching: users.fetching,
        totalUsers: users.totalUsers
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserListing);
