import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import "./Notification.scss";

import ToggleBtn from "../../components/ToggleBtn/ToggleBtn";
import { getNotificationSettings, updateNotificationSetting } from "../../store/actions";
import { UserTypes } from "../../constants";

const SETTING_IDS = {
    USER_ASSIGNED: 1,
    INDIVIDUAL_CHAT: 4,
    CONVERSATION_ENDED: 6,
    GROUP_CHATS: 5,
    MATCH_ACCEPTED: 7,
    MATCH_REJECTED: 8,
    WAITING_FOR_MATCHES: 9
};

const SETTINGS_VALUE = {
    [SETTING_IDS.USER_ASSIGNED]: 1,
    [SETTING_IDS.INDIVIDUAL_CHAT]: 4,
    [SETTING_IDS.CONVERSATION_ENDED]: 6,
    [SETTING_IDS.GROUP_CHATS]: 5,
    [SETTING_IDS.MATCH_ACCEPTED]: 7,
    [SETTING_IDS.MATCH_REJECTED]: 8,
    [SETTING_IDS.WAITING_FOR_MATCHES]: 9
};

const Notification = (props) => {
    const { getNotificationSettings, updateNotificationSetting } = props;

    const [settings, setSettings] = useState({});

    useEffect(() => {
        getNotificationSettings({ RoleId: UserTypes.MATCH_MAKER })
            .then((res) => {
                formatSettings(res);
            })
            .catch((err) => {});
    }, []);

    const formatSettings = (array = []) => {
        let obj = {};

        if (Boolean(array.length)) {
            array.forEach((item) => {
                if (!!SETTINGS_VALUE[item.SettingId]) {
                    obj[item.Name] = { ...item };
                }
            });
        }

        setSettings(obj);
    };

    const handleSettingUpdate = (setting) => {
        let updatedSetting = { ...setting, Value: !Boolean(setting.Value) };

        let payload = {
            RoleId: UserTypes.MATCH_MAKER,
            Settings: [updatedSetting]
        };

        updateNotificationSetting(payload)
            .then((res) => {
                formatSettings(res);
            })
            .catch((err) => {});
    };

    return (
        <>
            {/* <ToggleBtn checked={false} /> */}
            <div className="notification__wrapper fancy-scroll">
                <div className="d-flex  flex-column flex-md-row align-items-baseline notification__info">
                    <h2>User</h2>
                    <div className="d-flex  notification__info-toggle align-items-center justify-content-start justify-content-md-around">
                        <div className="notification__info-content">
                            <p>User Assigned</p>
                        </div>
                        <div className="notification__info-btn">
                            <ToggleBtn
                                checked={Boolean(settings["User Assigned"]?.Value)}
                                value={settings["User Assigned"]}
                                onChange={handleSettingUpdate}
                            />
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-column flex-md-row align-items-baseline notification__info">
                    <h2>Messages </h2>
                    <div className="d-flex notification__info-toggle align-items-center justify-content-start justify-content-md-around">
                        <div className="notification__info-content">
                            <p>new Individual chat </p>
                            <p>new Group Chat</p>
                            <p>Conversation Ended</p>
                        </div>
                        <div className="notification__info-btn">
                            <ToggleBtn
                                checked={Boolean(settings["Individual Chats"]?.Value)}
                                value={settings["Individual Chats"]}
                                onChange={handleSettingUpdate}
                            />
                            <ToggleBtn
                                checked={Boolean(settings["Group Chats"]?.Value)}
                                value={settings["Group Chats"]}
                                onChange={handleSettingUpdate}
                            />
                            <ToggleBtn
                                checked={Boolean(settings["Conversation Ended"]?.Value)}
                                value={settings["Conversation Ended"]}
                                onChange={handleSettingUpdate}
                            />
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-column flex-md-row align-items-baseline notification__info">
                    <h2>Matches </h2>
                    <div className="d-flex notification__info-toggle align-items-center justify-content-start justify-content-md-around">
                        <div className="notification__info-content">
                            <p>Waiting for matches</p>
                            <p>matches Accepted</p>
                            <p>Matches Rejected</p>
                        </div>
                        <div className="notification__info-btn">
                            <ToggleBtn
                                checked={Boolean(settings["Waiting For Matches"]?.Value)}
                                value={settings["Waiting For Matches"]}
                                onChange={handleSettingUpdate}
                            />
                            <ToggleBtn
                                checked={Boolean(settings["Match Accepted"]?.Value)}
                                value={settings["Match Accepted"]}
                                onChange={handleSettingUpdate}
                            />
                            <ToggleBtn
                                checked={Boolean(settings["Match Rejected"]?.Value)}
                                value={settings["Match Rejected"]}
                                onChange={handleSettingUpdate}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapDispatchToProps = {
    getNotificationSettings,
    updateNotificationSetting
};

export default connect(null, mapDispatchToProps)(Notification);
