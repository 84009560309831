import React, { useState, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";
import $ from "jquery";
import { connect, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";

import "./DefaultLayout.scss";
import routes from "../../routes";
import Sidebar from "../../components/Sidebar/Sidebar";
//import NotificationTray from "../../components/NotificationTray/NotificationTray";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";
import { Spinner } from "../../components/Loader";
import { getMe } from "../../store/actions";
import { Page500 } from "../Utility";
import { Socket } from "../../utils/socketUtils";
import { logo, bell } from "../../assets/images/index";
import Notifications from "../Notifications/Notifications";
import Tour from "../../components/Tour/Tour";
import Bellicon from "../../components/SVGICON/bell-icon"
const width = window.innerWidth;

const DefaultLayout = (props) => {
    const { isAuthenticated, tokenVerified, isLoading } = props;
    const [open, setOpen] = useState(false);
    const [tour, setTour] = useState(false);
    const [mobileview, setMobileview] = useState(width < 1100);
    const dispatch = useDispatch();

    useEffect(() => {
        if (isAuthenticated) {
            // add socket connectivity here.
            Socket.init();
            if (!tokenVerified) {
                dispatch(getMe());
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        $(window).resize(() => {
            const width = window.innerWidth;
            const mobileview = width < 1100;
            setMobileview(mobileview);
        });
    }, []);

    useEffect(() => {
        if (!localStorage.getItem("tour")) {
            localStorage.setItem("tour", "true");

            setTour(true);
        }
    }, []);

    const closeHandler = () => setOpen(false);
    const openHandler = () => setOpen(!open);

    if (!isAuthenticated) {
        return <Redirect to={getRoute(APP_ROUTES.LOGIN)} />;
    }

    return tokenVerified ? (
        <React.Fragment>
            <Sidebar />

            {tour && <Tour onTourCancel={() => setTour(false)} />}

            <div className="main-wrapper">
                {mobileview ? null : (
                    <div className="d-flex justify-content-between align-items-start main-header">
                        <img className="logo" className="logo" src={logo} alt="logo" />
                        <OutsideClickHandler
                            onOutsideClick={() => {
                                closeHandler();
                            }}
                        >
                            {/* <img onClick={openHandler} src={bell} alt="notification" /> */}
                            <Bellicon onClick={openHandler} className="pointer" />
                            {open ? <Notifications /> : null}
                        </OutsideClickHandler>
                    </div>
                )}

                <Switch>
                    {routes.map((route, index) => {
                        return (
                            <Route
                                key={index}
                                path={route.path}
                                name={route.name}
                                exact={route.exact}
                                render={(props) => <route.component {...props} />}
                            />
                        );
                    })}
                    <Redirect to="/dashboard/user-listing" />
                </Switch>
            </div>
        </React.Fragment>
    ) : isLoading ? (
        <Spinner classes="vh-100" showVerifyText={true} />
    ) : (
        <Page500 />
    );
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        isLoading: state.root.isLoading,
        tokenVerified: state.root.tokenVerified
    };
};

export default connect(mapStateToProps)(DefaultLayout);
