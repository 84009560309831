import { injectBaseConstantMethods } from "./BaseConstants";

const AppealReasons = {
    WRONGFUL_SUSPENSION: 1,
    FALSE_CLAIM: 2,
    WRONGFUL_REMOVAL: 3
};

const displayTextKeys = {
    [AppealReasons.WRONGFUL_SUSPENSION]: "Wrongful Suspension",
    [AppealReasons.FALSE_CLAIM]: "False Claim",
    [AppealReasons.WRONGFUL_REMOVAL]: "Wrongful Removal"
};

const labelClasses = {
    [AppealReasons.WRONGFUL_SUSPENSION]: "",
    [AppealReasons.FALSE_CLAIM]: "",
    [AppealReasons.WRONGFUL_REMOVAL]: ""
};

export default injectBaseConstantMethods(AppealReasons, displayTextKeys, labelClasses);
