import React, { Component } from "react";
import "./UserProfile.scss";
import PageTitle from "../../components/PageTitle/PageTitle";
import Accordian from "../../components/Accordian/Accordian";

import Button from "../../components/Button/Button";
import referal from "../../assets/images/referal.png";
import chats from "../../assets/images/chats.png";
import verify1 from "../../assets/images/verify1.png";
import matchIcon from "../../assets/images/matches.png";

const UserProfile = (props) => {
    return (
        <>
            <PageTitle
                link="/dashboard/previous-chats"
                title="Back to Previous Chats"
                icon={true}
            />
            <div className="d-flex flex-column flex-lg-row justify-content-between user-profile__wrapper">
                <div className="user-profile__info">
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <img src={verify1} alt="profile pic" />
                        <h3 className="pt-2">Zain</h3>
                        <div className="d-flex  justify-content-center align-items-baseline pt-3">
                            <div className="d-flex flex-column align-items-center">
                                <div className="d-flex ">
                                    <p>23 &nbsp;</p>
                                    <p>British</p>
                                </div>
                                <div className="d-flex ">
                                    <p>Glasgow &nbsp;</p>
                                    <p>Scotland</p>
                                </div>
                            </div>
                            <Button className="ml-5" title="Message" />
                        </div>
                        <div className="d-flex user-profile__stats justify-content-between pt-4">
                            <div className="d-flex flex-column align-items-center mb-2">
                                <img src={matchIcon} alt="matches" />
                                <p className="pt-2">3 Current Matches</p>
                            </div>
                            <div className="d-flex flex-column align-items-center mb-2">
                                <img src={referal} alt="matches" />
                                <p className="pt-2">3 Referrals Needed </p>
                            </div>
                            <div className="d-flex flex-column align-items-center mb-2">
                                <img src={chats} alt="matches" />
                                <p className="pt-2">2 Active Chats </p>
                            </div>
                        </div>
                        <div className="user-profile__about pt-4">
                            <h4>About</h4>
                            <p className="text-left">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                eiusmod tempor incididunt ut labore et dolore magna aliqua. Arcu dui
                                vivamus arcu felis bibendum ut tristiquest. Lorem ipsum dolor sit
                                amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                                ut labore et dolore magna aliqua. Arcu dui vivamus arcu felis
                                bibendum ut tristiquest.{" "}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="user-profile__accordian mt-5 mt-md-0">
                    <Accordian />
                </div>
            </div>
        </>
    );
};
export default UserProfile;
