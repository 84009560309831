import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { AvField } from "availity-reactstrap-validation";
import AvForm from "availity-reactstrap-validation/lib/AvForm";

import "./ContactUs.scss";

import SuccessModal from "../../components/SuccessModal/SuccessModal";
import Button from "../../components/Button/Button";
import { getRoute, APP_ROUTES } from "../../helpers/routeHelpers";
import { contactUs } from "../../store/actions";

const ContactUs = (props) => {
    const { contactUs } = props;

    const history = useHistory();
    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const canSubmit = () => {
        return email?.trim().length && name.trim().length && message.trim().length;
    };

    const handleSubmit = () => {
        if (!canSubmit()) {
            return;
        }

        let payload = {
            Message: message,
            Email: email,
            Name: name
        };

        setLoading(true);

        contactUs(payload)
            .then((res) => {
                setModalOpen(true);
            })
            .catch((err) => {})
            .finally(() => {
                setLoading(false);
            });
    };

    const handleNavigate = () => {
        history.push(getRoute(APP_ROUTES.USER_LISTING));
    };

    return (
        <>
            <div className="contact__wrapper fancy-scroll ">
                <h2 className="pb-4 ">How can we assist you?</h2>
                <AvForm className="text-center" onValidSubmit={handleSubmit}>
                    <AvField
                        name="Name"
                        type="text"
                        value={name}
                        placeholder="Name"
                        onChange={(event) => setName(event.target.value)}
                        validate={{
                            required: {
                                value: true,
                                errorMessage: "Name is required."
                            },
                            pattern: {
                                value: "/^[a-zA-Z ]+$/",
                                errorMessage:
                                    "Please enter a valid name, e.g John Smith (Min 3 and Max 55 characters allowed. Special characters are not allowed"
                            },
                            maxLength: {
                                value: 55,
                                errorMessage:
                                    "Please enter a valid name, e.g John Smith (Min 3 and Max 55 characters allowed. Special characters are not allowed"
                            },
                            minLength: {
                                value: 3,
                                errorMessage:
                                    "Please enter a valid name, e.g John Smith (Min 3 and Max 55 characters allowed. Special characters are not allowed"
                            }
                        }}
                        required
                        className="contact_form"
                    />
                    <AvField
                        name="email"
                        type="email"
                        value={email}
                        placeholder="Email"
                        errorMessage="Please enter a valid email"
                        onChange={(event) => setEmail(event.target.value)}
                        validate={{
                            required: {
                                value: true,
                                errorMessage: "Email is required."
                            },
                            maxLength: {
                                value: 128,
                                errorMessage: "Max 128 characters are allowed"
                            }
                        }}
                        required
                        className="contact_form"
                    />
                    <AvField
                        name="message"
                        type="textarea"
                        placeholder="Message..."
                        required
                        value={message}
                        validate={false}
                        overflow={true}
                        onChange={(event) => setMessage(event.target.value)}
                        validate={{
                            required: {
                                value: true,
                                errorMessage: "Message is required."
                            },
                            minLength: {
                                value: 1,
                                errorMessage: "Please enter a message"
                            }
                        }}
                        maxlength="300"
                        required
                        className="contact_form text-area"
                    />
                    <Button title="Send" type="submit" isLoading={loading} onClick={handleSubmit} />
                    <SuccessModal
                        btnAction={handleNavigate}
                        open={modalOpen}
                        description="Your message has been submitted. We apologize for the inconvenience. "
                        name="OK"
                        onCloseModal={() => setModalOpen(false)}
                    />
                </AvForm>
            </div>
        </>
    );
};

const mapDispatchToProps = {
    contactUs
};

export default connect(null, mapDispatchToProps)(ContactUs);
