import React from "react";
import { useHistory } from "react-router-dom";

import referal from "../../assets/images/referal.png";
import chats from "../../assets/images/chats.png";

import "./DetailsCard.scss";

import matchIcon from "../../assets/images/matches.png";
import Accordian from "./DetailAccordian";
import { Button } from "../../components/Button";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getMediaThumbPath } from "../../utils/mediaUtils";
import imageUpload from "../../assets/images/imageupload.png";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";
import { toaster } from "../../utils/toasterUtil";
import MatchIcon from "../../components/SVGICON/match-icon";
import ReferalIcon from "../../components/SVGICON/referal-icon";
import CurrentChat from "../../components/SVGICON/current-chat";
import { UserStatus } from "../../constants";

const UserDetailCard = (props) => {
    const { data, openConfirmModal = () => {}, onBackPress = () => {}, match } = props;
    const history = useHistory();

    const handleMessage = () => {
        if (data?.MatchmakerChatId) {
            history.push(
                getRoute(APP_ROUTES.ACTIVE_CHAT, { id: data?.MatchmakerChatId, tabname: "single" })
            );
        } else {
            toaster({ title: "No Chat Exists", type: "danger" });
        }
    };

    const chatAvailable = () => {
        if (
            data.Status === UserStatus.ID_VERIFICATION_PENDING ||
            data.Status === UserStatus.SELECTING_PREFERENCES ||
            data.Status === UserStatus.UNVERIFIED ||
            data.Status === UserStatus.COMPLETING_PROFILE
        ) {
            return false;
        }

        return true;
    };

    return (
        <>
            <div className="user-deatil__wrapper fancy-scroll">
                <div className="user-deatil__info ml-3 mt-3">
                    {match && (
                        <FontAwesomeIcon
                            className="back-icon"
                            icon={faAngleLeft}
                            onClick={onBackPress}
                        />
                    )}

                    <div
                        className={
                            match
                                ? "d-flex flex-column flex-md-row align-items-center justify-content-center"
                                : "d-flex flex-column flex-md-row align-items-center justify-content-between"
                        }
                    >
                        <div className="user-deatil__info-img">
                            <img
                                className={`profile-avatar`}
                                src={getMediaThumbPath(data?.Media) || imageUpload}
                                alt="profile"
                            />
                        </div>
                        <div
                            className={
                                match
                                    ? "d-flex flex-column flex-md-row align-items-center user-deatil__info-detail pt-3 pt-md-0 border-0"
                                    : "d-flex align-items-center user-deatil__info-detail py-3 py-md-0"
                            }
                        >
                            <div className={match && "pl-3"}>
                                <h3>{data?.Name || "-"}</h3>
                                {match && (
                                    <p className="match-per pt-1">{data?.MatchPercentage}% Match</p>
                                )}

                                <div className="d-flex justify-content-center justify-content-md-start py-2 ">
                                    <p>{data?.Age || "-"}, &nbsp;</p>
                                    <p>{data?.Nationality}</p>
                                </div>
                                {match && (
                                    <>
                                        <div className="d-flex">
                                            <p>
                                                {data?.PersonalDetails?.Location?.City || "-"} ,{" "}
                                                {data?.PersonalDetails?.Location?.Country || "-"}
                                            </p>
                                        </div>
                                    </>
                                )}
                                {!match && chatAvailable() && (
                                    <Button title="Message" onClick={handleMessage} />
                                )}
                            </div>
                            {match && (
                                <Button
                                    onClick={openConfirmModal}
                                    className={"mb-2 ml-2"}
                                    title="Send Match"
                                />
                            )}
                        </div>

                        {!match && (
                            <div className="d-flex flex-row flex-md-column user-deatil__info-stats">
                                <div className="d-flex flex-column flex-md-row align-items-center spacer">
                                    <MatchIcon className="match__icon" />
                                    <p className="pl-0 pl-md-3">
                                        {data?.TotalMatches} Current Matches
                                    </p>
                                </div>
                                <div className="d-flex flex-column flex-md-row align-items-center mb-2 px-3 px-md-0">
                                    <ReferalIcon className="match__icon" />
                                    <p className="pl-0 pl-md-3">
                                        {data?.ReferralsNeeded} Referrals Needed{" "}
                                    </p>
                                </div>
                                <div className="d-flex flex-column flex-md-row align-items-center mb-2">
                                    <CurrentChat className="match__icon active-chat-icon" />
                                    <p className="pl-0 pl-md-3">
                                        {data?.TotalConnections} Active Chats{" "}
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="user-deatil__about mt-4">
                    <h2>About</h2>
                    <p>{data?.About}</p>
                </div>
                <div className="user-deatil__accordian">{data && <Accordian user={data} />}</div>
            </div>
        </>
    );
};
export default UserDetailCard;
