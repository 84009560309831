export default {
    MATCH_ASSIGNED_TO_USER: 12,
    USER_PREFERENCE_COMPLETED: 13,
    REFERRAL_READY: 14,
    NEW_MESSAGE_TO_MATCH_MAKER_INDIVIDUAL: 15,
    CHAT_ENDED: 16,
    MATCH_ACCEPTED: 17,
    MATCH_REJECTED: 18,
    WAITING_FOR_MATCHES: 19,
    PHONE_NUMBER_IS_CHANGED: 20,
    DISPLAY_PICTURE_CHANGED: 21,
    NAME_CHANGED: 22,
    PASSWORD_CHANGED: 23,
    EXCHANGE_NUMBER_REQUEST: 24,
    USER_UPDATED_PROFILE: 35,
    NEW_USER_JOINS_APP: 36,
    NEW_MESSAGE_TO_MATCH_MAKER_GROUP: 31,
    PREFERENCES_COMPLETED: 32,
    RECEIVE_REFERRALS: 33
};
