import { injectBaseConstantMethods } from "./BaseConstants";

export const ReportCauses = {
    BREAKING_PRIVACY_POLICY: 1,
    BULLYING: 2,
    DISCRIMINATION: 3,
    HARASSMENT: 4,
    IMPERSONATING_TO_BE_SOMEONE: 5,
    INAPPROPRIATE_LANGUAGE: 6,
    NO_POLYGAMY: 7,
    SEXUAL_CONTENT: 8,
    VIOLENCE: 9
};

const displayTextKeys = {
    [ReportCauses.BREAKING_PRIVACY_POLICY]: "Breaking Privacy Policy",
    [ReportCauses.BULLYING]: "Bullying",
    [ReportCauses.DISCRIMINATION]: "Discrimination (Racism/ Xenophobia )",
    [ReportCauses.HARASSMENT]: "Harressment",
    [ReportCauses.IMPERSONATING_TO_BE_SOMEONE]: "Impersonating",
    [ReportCauses.INAPPROPRIATE_LANGUAGE]: "Inappropriate Language",
    [ReportCauses.NO_POLYGAMY]: "Polygamy",
    [ReportCauses.SEXUAL_CONTENT]: "Sexual Content",
    [ReportCauses.VIOLENCE]: "Violence"
};

const labelClasses = {
    [ReportCauses.BREAKING_PRIVACY_POLICY]: "",
    [ReportCauses.BULLYING]: "",
    [ReportCauses.DISCRIMINATION]: "",
    [ReportCauses.HARASSMENT]: "",
    [ReportCauses.IMPERSONATING_TO_BE_SOMEONE]: "",
    [ReportCauses.INAPPROPRIATE_LANGUAGE]: "",
    [ReportCauses.NO_POLYGAMY]: "",
    [ReportCauses.SEXUAL_CONTENT]: "",
    [ReportCauses.VIOLENCE]: ""
};

export default injectBaseConstantMethods(ReportCauses, displayTextKeys, labelClasses);
