import React, { Component } from "react";
const NewUser = (props) => {
    return (
        <svg
            width="32"
            height="28"
            viewBox="0 0 32 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={props.className}
        >
            <path
                d="M21.8627 26.9999V24.4444C21.8627 23.0888 21.3132 21.7888 20.3351 20.8303C19.357 19.8717 18.0303 19.3333 16.6471 19.3333H6.21569C4.8324 19.3333 3.50577 19.8717 2.52764 20.8303C1.54951 21.7888 1 23.0888 1 24.4444V26.9999"
                stroke="#E45858"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M11.4303 14.2222C14.3108 14.2222 16.646 11.9339 16.646 9.11111C16.646 6.28832 14.3108 4 11.4303 4C8.54974 4 6.2146 6.28832 6.2146 9.11111C6.2146 11.9339 8.54974 14.2222 11.4303 14.2222Z"
                stroke="#E45858"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M25.9321 20.3621L28.9999 23.3685"
                stroke="#E45858"
                stroke-width="2"
                stroke-linecap="round"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M22.4118 20.9474C24.686 20.9474 26.5295 19.1408 26.5295 16.9123C26.5295 14.6838 24.686 12.8772 22.4118 12.8772C20.1377 12.8772 18.2942 14.6838 18.2942 16.9123C18.2942 19.1408 20.1377 20.9474 22.4118 20.9474Z"
                stroke="#E45858"
                stroke-width="2"
            />
            <circle cx="26.5" cy="5.5" r="5.5" fill="#E45858" />
        </svg>
    );
};
export default NewUser;
