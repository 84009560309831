import {
    CHAT_LIST_REQUEST,
    CHAT_LIST_SUCCESS,
    CHAT_LIST_FAILED,
    UPDATE_CHATLIST_DATA,
    MESSAGE_UNREAD_COUNT,
    ADD_NEW_MESSAGE,
    CLEAR_CHAT_INITIAL_FETCH,
    MESSAGE_LIST_REQUEST,
    MESSAGE_LIST_SUCCESS,
    MESSAGE_LIST_FAILED,
    CLEAR_CURRENT_CHAT,
    SET_CURRENT_CHAT,
    UPDATE_MESSAGE_ID
} from "../actions";

import Config from "../../Config";

const initialState = {
    chatList: {
        group: {
            fetching: false,
            page: 1,
            initialFetch: false,
            dataAvailable: true,
            searchText: "",
            data: [],
            unReadCount: 0
        },
        single: {
            fetching: false,
            page: 1,
            initialFetch: false,
            dataAvailable: true,
            searchText: "",
            data: [],
            unReadCount: 0
        }
    },
    currentChat: {
        fetching: false,
        dataAvailable: true,
        id: null,
        messages: []
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case CHAT_LIST_REQUEST:
            return {
                ...state,
                chatList: {
                    ...state.chatList,
                    [action.tabname]: {
                        ...state.chatList[action.tabname],
                        fetching: true,
                        data: (() => {
                            if (action.page === 1) {
                                return [];
                            }
                            return state.chatList[action.tabname].data;
                        })()
                    }
                }
            };
        case CHAT_LIST_SUCCESS:
            return {
                ...state,
                chatList: {
                    ...state.chatList,
                    [action.tabname]: {
                        ...state.chatList[action.tabname],
                        fetching: false,
                        initialFetch: true,
                        dataAvailable: action.payload.length === Number(Config.LIMIT),
                        data: [...state.chatList[action.tabname].data, ...action.payload]
                    }
                }
            };
        case CHAT_LIST_FAILED:
            return {
                ...state,
                chatList: {
                    ...state.chatList,
                    [action.tabname]: {
                        ...state.chatList[action.tabname],
                        fetching: false
                    }
                }
            };
        case UPDATE_CHATLIST_DATA:
            return {
                ...state,
                chatList: {
                    ...state.chatList,
                    [action.tabname]: {
                        ...state.chatList[action.tabname],
                        [action.key]: action.value
                    }
                }
            };
        case MESSAGE_UNREAD_COUNT:
            return {
                ...state,
                chatList: {
                    ...state.chatList,
                    single: {
                        ...state.chatList.single,
                        unReadCount: action?.payload?.unReadSingleCount || 0
                    },
                    group: {
                        ...state.chatList.group,
                        unReadCount: action?.payload?.unReadGroupCount || 0
                    }
                }
            };
        case ADD_NEW_MESSAGE:
            return {
                ...state,
                currentChat: {
                    ...state.currentChat,
                    messages: [...state.currentChat.messages, action.payload]
                }
            };
        case MESSAGE_LIST_REQUEST:
            return {
                ...state,
                currentChat: {
                    ...state.currentChat,
                    fetching: true,
                    messages: (() => {
                        if (!action.page || action.page === 1) {
                            return [];
                        }
                        return state.currentChat.messages;
                    })()
                }
            };
        case MESSAGE_LIST_SUCCESS:
            return {
                ...state,
                currentChat: {
                    ...state.currentChat,
                    messages: [...action.payload, ...state.currentChat.messages],
                    totalMessages: action.totalMessages,
                    fetching: false,
                    dataAvailable: action.payload.length === Number(Config.LIMIT)
                }
            };
        case MESSAGE_LIST_FAILED:
            return {
                ...state,
                currentChat: {
                    ...state.currentChat,
                    fetching: false
                }
            };
        case CLEAR_CHAT_INITIAL_FETCH:
            return {
                ...state,
                chatList: initialState.chatList
            };
        case CLEAR_CURRENT_CHAT:
            return {
                ...state,
                currentChat: {
                    ...initialState.currentChat
                }
            };
        case SET_CURRENT_CHAT:
            return {
                ...state,
                currentChat: {
                    ...state.currentChat,
                    id: action.chatId
                }
            };
        case UPDATE_MESSAGE_ID:
            return {
                ...state,
                currentChat: {
                    ...state.currentChat,
                    messages: state.currentChat.messages.map((msg) => {
                        if (msg.Id === action.messageUUID) {
                            return {
                                ...msg,
                                Id: action.messageId
                            };
                        }
                        return msg;
                    })
                }
            };
        default:
            return state;
    }
};
