import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import PageTitle from "../../components/PageTitle/PageTitle";
import "./ActiveChat.scss";
import $ from "jquery";
import ChatUserList from "./ChatUserList";
import { clearChatInitialFetch, getChatUnReadCount } from "../../store/actions";
import ChatMessageSection from "./ChatMessageSection";
import ChatInfo from "../../components/ChatInfo/ChatInfo";

const width = window.innerWidth;

const ActiveChat = (props) => {
    const [mobileview, setMobileview] = useState(width < 800);
    const [detail, setDetail] = useState(false);
    const location = useLocation();

    const detailHandler = () => setDetail(!detail);
    const dispatch = useDispatch();

    useEffect(() => {
        $(window).resize(() => {
            const width = window.innerWidth;
            const mobileview = width < 800;
            setMobileview(mobileview);
        });
        return () => {
            dispatch(clearChatInitialFetch());
        };
    }, []);

    useEffect(() => {
        dispatch(getChatUnReadCount());
    }, []);

    const isPreviousChatRoute = () => {
        return location.pathname.includes("previous-chats");
    };

    return (
        <>
            <PageTitle title={isPreviousChatRoute() ? "Previous Chats" : "Current Chats"} />
            <div
                className={
                    !detail
                        ? "active-chat__wrapper mobile-wrapper  d-flex"
                        : "active-chat__wrapper mobile-wrapper-detail fancy-scroll d-flex"
                }
            >
                <Switch>
                    <Route path="/dashboard/chat/:tabname/:id?">
                        {(props) => (
                            <ChatUserList
                                mobileview={mobileview}
                                detailHandler={detailHandler}
                                {...props}
                            />
                        )}
                    </Route>
                    <Route path="/dashboard/previous-chats/:tabname/:id?">
                        {(props) => (
                            <ChatUserList
                                mobileview={mobileview}
                                detailHandler={detailHandler}
                                {...props}
                            />
                        )}
                    </Route>
                    <Redirect to="/dashboard/chat/group" />
                </Switch>

                <div className="active-chat__message">
                    <Switch>
                        <Route path="/dashboard/chat/:tabname/:id">
                            {(props) => (
                                <ChatMessageSection
                                    mobileview={mobileview}
                                    detailHandler={detailHandler}
                                    {...props}
                                />
                            )}
                        </Route>
                        <Route path="/dashboard/previous-chats/:tabname/:id">
                            {(props) => (
                                <ChatMessageSection
                                    mobileview={mobileview}
                                    detailHandler={detailHandler}
                                    {...props}
                                />
                            )}
                        </Route>
                        <Route>
                            <div className="d-flex justify-content-center align-items-center h-100">
                                <ChatInfo info="No chats to show" />
                            </div>
                        </Route>
                    </Switch>
                </div>
            </div>
        </>
    );
};
export default ActiveChat;
