import React, { useState, useEffect } from "react";
import "./Sidebar.scss";
import { Link, withRouter } from "react-router-dom";
import { NavLink, useLocation } from "react-router-dom";
import NavBlock from "../NavBlock/NavBlock";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBars,
    faUser,
    faUsers,
    faComment,
    faExclamation,
    faExclamationTriangle,
    faChartLine,
    faCommentSlash,
    faCog,
    faSignOutAlt,
    faAngleRight
} from "@fortawesome/free-solid-svg-icons";
import $ from "jquery";
import LogoutModal from "../LogoutModal/LogoutModal";
import { logo, bell } from "../../assets/images/index";
//import NotificationTray from "../NotificationTray/NotificationTray";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";
import { SettingRouteTypes } from "../../constants";
import Notifications from "../../screens/Notifications/Notifications";
import OutsideClickHandler from "react-outside-click-handler";
import ChatIcon from "../SVGICON/chat-icon";
import UserIcon from "../SVGICON/user-icon";
import VerifyIcon from "../SVGICON/verify-icon";
import ReportIcon from "../SVGICON/report-icon";
import PreviousIcon from "../SVGICON/previous-icon";
import SettingIcon from "../SVGICON/setting-icon";
import LogoutIcon from "../SVGICON/logout-icon";
import Bellicon from "../SVGICON/bell-icon";
import NewUser from "../SVGICON/new-user";
import { Socket, SocketTypes } from "../../utils/socketUtils";

const width = window.innerWidth;

const Sidebar = (props) => {
    const [newUser, setNewUser] = useState(null);
    const location = useLocation();
    const [open, setOpen] = useState(false);
    const openHandler = () => setOpen(!open);
    const [mobileview, setMobileview] = useState(width < 1100);
    const [toggle, setToggle] = useState(true);
    const [logout, setLogout] = useState(false);
    const [setting, setSetting] = useState(false);
    const [settingTab, setSettingTab] = useState(false);
    const openSettingHandler = () => setSetting(!setting);
    const openLogoutHandler = () => setLogout(true);
    const closeLogoutHandler = () => setLogout(false);
    const closesettingTab = () => {
        setSetting(false);
        setToggle(!toggle);
    };
    const closeHandler = () => setOpen(false);

    const istoggle = () => {
        setToggle(!toggle);
        setSetting(false);
    };

    const handleSeen = () => {
        istoggle();
        setNewUser(false);
    };

    const toggleClose = () => {
        setToggle(true);
    };

    useEffect(() => {
        function handleNewUserJoined(data) {
            if (location.pathname.includes("verification")) {
                return;
            }
            setNewUser(true);
        }
        Socket.onNewUserJoined(handleNewUserJoined);

        return () => {
            Socket.remove(SocketTypes.NEW_USER_ID_VERIFICATION, handleNewUserJoined);
        };
    }, [location]);

    useEffect(() => {
        $(window).resize(() => {
            const width = window.innerWidth;
            const mobileview = width < 1100;
            setMobileview(mobileview);
            setSetting(false);
        });
        $(".sidebar").css({ display: "block" });
    }, []);

    const handleLogout = (evt) => {
        evt.preventDefault();
        openLogoutHandler();
    };

    return (
        <div className="position-relative">
            {setting ? (
                <div className="setting-sub-element">
                    <div className="f-flex flex-column">
                        <div
                            onClick={mobileview ? closesettingTab : null}
                            className="d-flex align-items-center justify-content-between setting-sub-element__item"
                        >
                            <Link to="/dashboard/settings/user-account">Account</Link>
                            <FontAwesomeIcon icon={faAngleRight} />
                        </div>
                        <div
                            onClick={mobileview ? closesettingTab : null}
                            className="d-flex align-items-center justify-content-between setting-sub-element__item"
                        >
                            <Link to="/dashboard/settings/notification">Notifications</Link>
                            <FontAwesomeIcon icon={faAngleRight} />
                        </div>
                        <div
                            onClick={mobileview ? closesettingTab : null}
                            className="d-flex align-items-center justify-content-between setting-sub-element__item"
                        >
                            <Link to="/dashboard/settings/faq">FAQs</Link>
                            <FontAwesomeIcon icon={faAngleRight} />
                        </div>
                        <div
                            onClick={mobileview ? closesettingTab : null}
                            className="d-flex align-items-center justify-content-between setting-sub-element__item"
                        >
                            <Link to="/dashboard/settings/about-us">About Us</Link>
                            <FontAwesomeIcon icon={faAngleRight} />
                        </div>
                        <div
                            onClick={mobileview ? closesettingTab : null}
                            className="d-flex align-items-center justify-content-between setting-sub-element__item"
                        >
                            <Link to="/dashboard/settings/contact-us">Contact Us</Link>
                            <FontAwesomeIcon icon={faAngleRight} />
                        </div>
                        <div
                            onClick={mobileview ? closesettingTab : null}
                            className="d-flex align-items-center justify-content-between setting-sub-element__item"
                        >
                            <Link to="/dashboard/settings/terms-and-condition">T&Cs</Link>
                            <FontAwesomeIcon icon={faAngleRight} />
                        </div>
                    </div>
                </div>
            ) : null}

            <>
                <OutsideClickHandler
                    onOutsideClick={() => {
                        closeHandler();
                    }}
                >
                    {mobileview ? (
                        <header className="mobile-header d-flex justify-content-between align-items-center">
                            <button onClick={istoggle} className="toggle-btn">
                                <FontAwesomeIcon icon={faBars} />
                            </button>
                            <img className="header-logo" src={logo} alt="logo" />
                            <Bellicon className="mr-3" onClick={openHandler} />

                            {open ? <Notifications /> : null}
                        </header>
                    ) : null}
                </OutsideClickHandler>
            </>
            {!toggle ? <div onClick={istoggle} className="sidebar-backdrop"></div> : null}
            <div className={toggle ? "sidebaropen " : "sidebaropen active "}>
                <div className="navigation">
                    <div className="navigation__1">
                        <NavBlock
                            onClick={istoggle}
                            Link={getRoute(APP_ROUTES.USER_LISTING)}
                            className="sidebar-config-user"
                        >
                            <UserIcon />
                        </NavBlock>
                        <NavBlock
                            onClick={handleSeen}
                            Link={getRoute(APP_ROUTES.VERIFICATION)}
                            className="sidebar-config-verify sidebar-config-verify-dot"
                        >
                            {newUser ? <NewUser /> : <VerifyIcon />}
                        </NavBlock>

                        <NavBlock
                            onClick={istoggle}
                            Link={`/dashboard/chat`}
                            className="sidebar-config-chat"
                        >
                            <ChatIcon />
                        </NavBlock>
                        <NavBlock
                            onClick={istoggle}
                            Link={getRoute(APP_ROUTES.REPORT_LISTING)}
                            className="sidebar-config-report"
                        >
                            <ReportIcon />
                        </NavBlock>

                        <NavBlock
                            onClick={istoggle}
                            Link={`/dashboard/previous-chats/group`}
                            className="sidebar-config-chat-arrow"
                        >
                            <PreviousIcon />
                        </NavBlock>
                    </div>
                    <div className="navigation__2">
                        <button
                            onClick={openSettingHandler}
                            className={
                                setting
                                    ? "setting-btn activate active sidebar-config-settings"
                                    : "setting-btn sidebar-config-settings "
                            }
                        >
                            <SettingIcon />
                        </button>

                        <NavBlock onClick={handleLogout} className="sidebar-config-logout">
                            <LogoutIcon />
                        </NavBlock>
                        <LogoutModal open={logout} onModalClose={closeLogoutHandler} />
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Sidebar;
