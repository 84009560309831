import React from "react";

import "./Placeholder.scss";

const Placeholder = ({ message = "" }) => {
    return (
        <div className="placeholder-container">
            <p>{message}</p>
        </div>
    );
};
export default Placeholder;
