import { injectBaseConstantMethods } from "./BaseConstants";

const Relocation = {
    LOCALLY: 1,
    NATIONALLY: 2,
    INTERNATIONALY: 3
};

const displayTextKeys = {
    [Relocation.LOCALLY]: "Locally",
    [Relocation.NATIONALLY]: "Nationally",
    [Relocation.INTERNATIONALY]: "Internationaly"
};

const labelClass = {
    [Relocation.LOCALLY]: "",
    [Relocation.NATIONALLY]: "",
    [Relocation.INTERNATIONALY]: ""
};

export default injectBaseConstantMethods(Relocation, displayTextKeys, labelClass);
