import moment from "moment";

export const dateTimeFormat = {
    timeFormat: "LT",
    dateWithTimeFormat: "MMM  Do, h:mm A",
    dateWithYearTimeFormat: "MMM  Do YYYY, h:mm A"
};

export const getNearestTimeStampStr = (date) => {
    let isDaySame = moment().isSame(date, "day");
    let isYearSame = moment().isSame(date, "year");
    if (isDaySame) {
        return moment(date).format(dateTimeFormat.timeFormat);
    } else if (!isDaySame && isYearSame) {
        return moment(date).format(dateTimeFormat.dateWithTimeFormat);
    } else return moment(date).format(dateTimeFormat.dateWithYearTimeFormat);
};
