import React, { useEffect, useState, useLayoutEffect } from "react";
import { faLock, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, connect } from "react-redux";

import "./Signup.scss";

import InputField from "../../components/InputField/InputField";
import { Button } from "../../components/Button";
import { Placeholder } from "../../components/Placeholder";
import { logo } from "../../assets/images/index";
import { verifySignupToken, setupAccount } from "../../store/actions";
import { UserStatus } from "../../constants";
import { Redirect } from "react-router";
import { APP_ROUTES } from "../../helpers/routeHelpers";

const Signup = (props) => {
    const { location, isAuthenticated, user } = props;
    const dispatch = useDispatch();

    const [bodyData, setBodyData] = useState({ password: "", confirmPassword: "" });
    const [isVerified, setIsVerified] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [fetching, setFetching] = useState(false);

    const params = new URLSearchParams(location.search);
    const token = params.get("Token");

    useLayoutEffect(() => {
        async function handleVerifyToken() {
            try {
                setIsLoading(true);
                setIsVerified(false);
                await dispatch(verifySignupToken({ Token: token }));
                setIsVerified(true);
                setIsLoading(false);
            } catch (err) {
                setIsLoading(false);
            }
        }
        if (!isAuthenticated) {
            handleVerifyToken();
        }
    }, [dispatch]);

    const handleChange = (evt) => {
        setBodyData((state) => {
            return {
                ...state,
                [evt.target.name]: evt.target.value
            };
        });
    };

    const handleSignup = async () => {
        try {
            setFetching(true);
            await dispatch(setupAccount({ Password: bodyData.password }));
            setFetching(false);
        } catch (err) {
            setFetching(false);
        }
    };

    if (isAuthenticated && user?.Status === UserStatus.VERIFIED) {
        return <Redirect to={APP_ROUTES.DASHBOARD} />;
    } else if (isAuthenticated && user?.Status === UserStatus.COMPLETING_PROFILE) {
        return <Redirect to={APP_ROUTES.ACCOUNT_SETUP} />;
    }

    return isVerified ? (
        <div className="signup">
            <img className="auth-logo" src={logo} alt="logo" />
            <div className="signup__form">
                <form>
                    <InputField
                        type="email"
                        icon={faEnvelope}
                        placeholder="Email Address"
                        name="email"
                        value={user.Email}
                        onEnter={handleChange}
                        required={true}
                        disabled={true}
                    />
                    <InputField
                        type="password"
                        icon={faLock}
                        placeholder="Password"
                        name="password"
                        secure={true}
                        value={bodyData.password}
                        onEnter={handleChange}
                        required={true}
                    />
                    <InputField
                        type="password"
                        icon={faLock}
                        placeholder="Confirm Password"
                        name="confirmPassword"
                        secure={true}
                        value={bodyData.confirmPassword}
                        onEnter={handleChange}
                        required={true}
                    />
                    <Button
                        title="Submit"
                        onClick={handleSignup}
                        isLoading={fetching}
                        className="btn btn-primary mt-5"
                    />
                </form>
            </div>
        </div>
    ) : isLoading ? (
        <Placeholder message="Loading please wait..." />
    ) : (
        !isLoading && !isVerified && <Placeholder message="Session Expired" />
    );
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        user: state.auth.user
    };
};

export default connect(mapStateToProps)(Signup);
