import React, { useState } from "react";
import { connect } from "react-redux";
import { Modal } from "react-responsive-modal";
import { useLocation, useHistory } from "react-router-dom";

import "./ChatHeader.scss";
import "./ConfirmModal.scss";
import "react-responsive-modal/styles.css";

import chatsetting from "../../assets/images/chat-setting.png";
import ChatProfile from "../ChatProfile/ChatProfile";
import Button from "../../components/Button/Button";
import ReportAbuseModal from "./ReportAbuseModal";
import { ReasonModal } from "../ReasonModal";
import { CommentModal } from "../CommentModal";
import { getUserImagesPayload, getUserNames } from "../../utils/commonUtil";
import { UserTypes, UserStatus } from "../../constants";
import { toaster } from "../../utils/toasterUtil";
import { createReport } from "../../store/actions";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";

import ChatSettingsIcon from "../SVGICON/chat-settings-icon";

const ChatHeader = (props) => {
    const { data, currentChat, createReport } = props;

    const [chatInfoModal, setChatInfoModal] = useState(false);
    const [reportAbuseModal, setReportAbuseModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [reasonModal, setReasonModal] = useState(false);
    const [selectedReason, setSelectedReason] = useState("");
    const [commentModal, setCommentModal] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);
    const [exitedModal, setExitedModal] = useState(false);
    const [remarks, setRemarks] = useState("");
    const [usersModal, setUsersModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const history = useHistory();

    const renderUsers = () => {
        if (currentChat && Boolean(currentChat.Users.length)) {
            return currentChat.Users.filter((item) => item.Type === UserTypes.USER);
        }

        return [];
    };

    const isPrevChat = () => {
        return location.pathname.includes("previous-chats");
    };

    const isSingleChat = () => {
        return location.pathname.includes("single");
    };

    const handleReportAbuse = () => {
        setChatInfoModal(false);
        if (isSingleChat()) {
            let user = renderUsers()[0];
            setSelectedUser(user);
            if (
                user?.ChatParticipantStatus === UserStatus.DELETED ||
                user?.ChatParticipantStatus === UserStatus.ADMIN_DELETED
            ) {
                setChatInfoModal(false);
                setExitedModal(true);
                return;
            }
            setReasonModal(true);
        } else {
            setReportAbuseModal(true);
        }
    };

    const handleNextPress = () => {
        if (!selectedUser) {
            toaster({ title: "Please select a user", type: "danger" });
            return;
        }
        if (
            selectedUser?.ChatParticipantStatus === UserStatus.DELETED ||
            selectedUser?.ChatParticipantStatus === UserStatus.ADMIN_DELETED
        ) {
            setReportAbuseModal(false);
            setExitedModal(true);

            return;
        }
        setReportAbuseModal(false);
        setReasonModal(true);
    };

    const handleReasonSubmit = () => {
        if (!selectedReason) {
            toaster({ title: "Please select a reason", type: "danger" });
            return;
        }

        setReasonModal(false);
        setCommentModal(true);
    };

    const handleUserSelect = (data) => {
        setSelectedUser(data);
    };

    const handleRemarksSubmit = () => {
        if (!remarks.trim().length) {
            // toaster({ title: "Please enter remarks", type: "danger" });
            return;
        }
        let payload = {
            CauseId: selectedReason,
            ChatId: currentChat?.ChatId,
            ReportedId: selectedUser?.UserId,
            About: remarks
        };
        setLoading(true);

        createReport(payload)
            .then((res) => {
                setCommentModal(false);
                setConfirmModal(true);
                setRemarks("");
                setSelectedUser(null);
                setSelectedReason(null);
            })
            .catch((err) => {})
            .finally(() => {
                setLoading(false);
            });
    };

    const handleReasonModalClose = () => {
        setSelectedReason("");
        setReasonModal(false);
        setSelectedUser(null);
        if (isSingleChat()) {
            setChatInfoModal(true);
        } else {
            setReportAbuseModal(true);
        }
    };

    const handleReportAbuseModalClose = () => {
        setSelectedUser(null);
        setReportAbuseModal(false);
    };

    const handleCommentModalClose = () => {
        setRemarks("");
        setCommentModal(false);
        setReasonModal(true);
    };

    const handleUserClick = (data) => {
        setSelectedUser(data);
        if (
            data?.ChatParticipantStatus === UserStatus.DELETED ||
            data?.ChatParticipantStatus === UserStatus.ADMIN_DELETED
        ) {
            setChatInfoModal(false);
            setExitedModal(true);

            return;
        }
    };

    const handleMessageIndividually = () => {
        setChatInfoModal(false);
        setUsersModal(true);
    };

    const handleNavigateToUser = (data) => {
        setUsersModal(false);
        history.push(
            getRoute(APP_ROUTES.ACTIVE_CHAT, { id: data.MatchMakerChatId, tabname: "single" })
        );
    };

    return (
        <>
            <div className="d-flex justify-content-between align-items-center chat-header">
                <div className="d-flex align-items-center">
                    <div className="d-flex chat-header__images">
                        {getUserImagesPayload(currentChat.Users)?.map((user, index) => {
                            return (
                                <div key={index}>
                                    <img
                                        className="w-100 h-100"
                                        style={{
                                            borderRadius: 40
                                        }}
                                        src={user?.Media?.Path}
                                        key={index}
                                    />
                                </div>
                            );
                        })}
                    </div>
                    <div className="d-flex chat-header__content pl-2">
                        <h3>{getUserNames(currentChat.Users)}</h3>
                    </div>
                </div>

                <div className="chat-header__setting">
                    {/* <img
                        onClick={() => setChatInfoModal(true)}
                        src={chatsetting}
                        alt="setting"
                        className="mr-2"
                    /> */}
                    <ChatSettingsIcon className="pointer" onClick={() => setChatInfoModal(true)} />

                    <Modal
                        classNames={{ modal: "previousModal" }}
                        open={chatInfoModal}
                        onClose={() => setChatInfoModal(false)}
                        showCloseIcon={true}
                        center
                    >
                        <div className="d-flex flex-column align-items-center justify-content-center perivious__modal ">
                            <h4 className="pb-3">chat info</h4>
                            <div className="d-flex pt-4">
                                {renderUsers().map((item) => {
                                    return (
                                        <ChatProfile
                                            exit={true}
                                            picture={item.MediaPath}
                                            data={item}
                                            classes="pl-5"
                                            {...(isPrevChat() && { onUserClick: handleUserClick })}
                                        />
                                    );
                                })}
                            </div>
                            {props.active && (
                                <>
                                    {!isPrevChat() && (
                                        <div className="d-flex flex-column flex-md-row mt-4">
                                            {!isSingleChat() && (
                                                <Button
                                                    title="Message Individually"
                                                    onClick={handleMessageIndividually}
                                                />
                                            )}

                                            <Button
                                                className="ml-0 ml-md-4 mt-4 mt-md-0"
                                                title="Report Abuse"
                                                onClick={handleReportAbuse}
                                            />
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </Modal>

                    <Modal
                        classNames={{ modal: "previousModal" }}
                        open={usersModal}
                        onClose={() => setUsersModal(false)}
                        showCloseIcon={true}
                        center
                    >
                        <div className="d-flex flex-column align-items-center justify-content-center perivious__modal ">
                            <h4 className="pb-3">Select A User</h4>
                            <div className="d-flex pt-4">
                                {renderUsers().map((item) => {
                                    return (
                                        <ChatProfile
                                            exit={true}
                                            picture={item.MediaPath}
                                            data={item}
                                            fromReport={true}
                                            classes="pl-5"
                                            onUserClick={() => handleNavigateToUser(item)}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    </Modal>

                    <ReportAbuseModal
                        open={reportAbuseModal}
                        onModalClose={handleReportAbuseModalClose}
                        participants={renderUsers()}
                        onUserClick={(value) => handleUserSelect(value)}
                        onNextPress={handleNextPress}
                        selectedUser={selectedUser}
                    />
                    <ReasonModal
                        open={reasonModal}
                        onModalClose={handleReasonModalClose}
                        selectedReason={selectedReason}
                        onReasonSelect={(value) => setSelectedReason(value)}
                        onNextPress={handleReasonSubmit}
                    />

                    <CommentModal
                        open={commentModal}
                        title={
                            "Please provide us details about the incident to help us investigate"
                        }
                        onModalClose={handleCommentModalClose}
                        remarksValue={remarks}
                        setRemarksValue={setRemarks}
                        onNextPress={handleRemarksSubmit}
                        loading={loading}
                    />

                    <Modal
                        classNames={{ modal: "CustomModal" }}
                        open={confirmModal}
                        onClose={() => setConfirmModal(false)}
                        showCloseIcon={false}
                        center
                    >
                        <div className="custom-modal d-flex flex-column align-items-center justify-content-center h-100">
                            <p>
                                Your Report has been submitted! We are sorry for the inconvenience
                            </p>
                            <button className="custom-btn" onClick={() => setConfirmModal(false)}>
                                Ok
                            </button>
                        </div>
                    </Modal>

                    <Modal
                        classNames={{ modal: "unfoundmodal" }}
                        open={exitedModal}
                        onClose={() => setExitedModal(false)}
                        showCloseIcon={false}
                        center
                        closeOnOverlayClick={false}
                    >
                        <div className="d-flex flex-column justify-content-space-between align-items-center confirmsuspend h-50">
                            <p className="mt-4">{selectedUser?.Name} has exited the application</p>

                            <div className="d-flex mt-5">
                                <Button title="Ok" onClick={() => setExitedModal(false)} />
                            </div>
                        </div>
                    </Modal>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state, ownProps) => {
    const currentChat = state.entities.chats[ownProps.chatId];

    return {
        currentChat
    };
};

const mapDispatchToProps = {
    createReport
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatHeader);
