import React from "react";
// import { connect } from "react-redux";
import {
    CardTypeOne,
    CardTypeOneFlexItem,
    H4,
    Icon,
    CardRightImg,
    RightIconContainer,
    StepTwoCardContainer,
    NotificationBage
} from "./TooltipDesignMain.styles";
import CustomButton from "../../../Button/Button";

const TooltipDesign = ({
    title,
    description,
    isLeftIcon = true,
    leftIconPosition = "",
    RightImage,
    RightIcon,
    backProps,
    primaryProps,
    middleContentPosition = "",
    footerLayoutTwo = false,
    middleContentWidth,
    childrenCard,
    stepTwoAlignSelf,
    isNotificationCount = false,
    UnreadCount,
    isSkipButton = true,
    skipProps,
    mainImg,
    primaryImg,
    onCancel = () => {},
    detail
}) => {
    return (
        <CardTypeOne>
            {RightIcon && (
                <RightIconContainer className="d-flex justify-content-between">
                    <div>
                        <h2 className="main-title mb-4">{title}</h2>
                    </div>
                    {/* <div {...backProps}>
                        <Icon className={RightIcon} />
                    </div> */}
                </RightIconContainer>
            )}
            <div className="d-flex justify-content-between  ">
                {/* {isLeftIcon && (
                    <CardTypeOneFlexItem alignSelf={leftIconPosition} {...backProps}>
                        <Icon className="fas fa-chevron-left" />
                    </CardTypeOneFlexItem>
                )} */}
                <CardTypeOneFlexItem
                    width={middleContentWidth ? middleContentWidth : "80%"}
                    alignSelf={middleContentPosition}
                >
                    {!RightIcon && <h2 className="main-title  mb-4">{title}</h2>}

                    <H4 className="description">{description}</H4>
                </CardTypeOneFlexItem>
                {RightImage && (
                    <div className="text-right position-relative">
                        <CardRightImg src={RightImage} imgWidth="3.438rem" />
                    </div>
                )}
            </div>
            <CardTypeOneFlexItem
                className="d-flex flex-column skip-wrapper align-items-end"
                alignSelf={stepTwoAlignSelf}
            >
                {!footerLayoutTwo && (
                    <>
                        <CustomButton className=" mt-5 next-btn" {...primaryProps}>
                            Next
                        </CustomButton>

                        <div onClick={onCancel} className="pointer">
                            <p className="skip-tour " {...skipProps}>
                                <u>Skip the tour</u>
                            </p>
                        </div>
                    </>
                )}
            </CardTypeOneFlexItem>

            {detail && footerLayoutTwo && isSkipButton && (
                <div className="d-flex flex-column flex-md-row justify-content-between align-items-end pt-2 pt-md-5">
                    {primaryImg ? (
                        <div className="main-img d-flex align-items-center justify-content-center w-100">
                            <img className="tour-main-img" src={primaryImg} />
                        </div>
                    ) : null}
                    <div className="text-right ml-3">
                        <CustomButton className="mt-5 next-btn" {...primaryProps}>
                            Next
                        </CustomButton>
                        <div onClick={onCancel} className="pointer">
                            <p className="skip-tour " {...skipProps}>
                                <u>Skip the tour</u>
                            </p>
                        </div>
                    </div>
                </div>
            )}

        </CardTypeOne>
    );
};

// const mapStateToProps = (state) => {
//     return {
//         UnreadCount: state.notification.UnreadCount,
//     };
// };

export default TooltipDesign;

