import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { AvForm } from "availity-reactstrap-validation";

import { Button } from "../../components/Button";
import { logo } from "../../assets/images/index";
import { UserStatus, UserTypes } from "../../constants";
import { APP_ROUTES } from "../../helpers/routeHelpers";
import { TextInput } from "../../components/TextInput";
import { emailIcon, lockIcon } from "../../assets/images";
import { login } from "../../store/actions";

const Login = (props) => {
    const { isAuthenticated, user } = props;
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    const handleLoginSubmit = () => {
        setIsLoading(true);
        const bodyData = {
            Email: email,
            Password: password,
            Type: UserTypes.MATCH_MAKER
        };
        dispatch(login(bodyData))
            .then((res) => {})
            .catch((err) => {})
            .finally(() => {
                setIsLoading(false);
            });
    };

    if (isAuthenticated && user?.Status === UserStatus.UNVERIFIED) {
        return <Redirect to={APP_ROUTES.SIGNUP} />;
    } else if (isAuthenticated && user?.Status === UserStatus.COMPLETING_PROFILE) {
        return <Redirect to={APP_ROUTES.ACCOUNT_SETUP} />;
    }
    if (isAuthenticated) {
        return <Redirect to={APP_ROUTES.DASHBOARD} />;
    }

    return (
        <div className="signup">
            <img className="auth-logo" src={logo} alt="logo" />
            <div className="signup__form">
                <AvForm onValidSubmit={handleLoginSubmit}>
                    <TextInput
                        name="email"
                        placeholder="Email Address"
                        required={true}
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        inputIcon={emailIcon}
                        validate={{
                            required: { value: true, errorMessage: "Email is required." },
                            email: {
                                errorMessage: "Please enter a valid email."
                            }
                        }}
                    />
                    <TextInput
                        name="password"
                        placeholder="Password"
                        required={true}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        type="password"
                        inputIcon={lockIcon}
                        errorMessage="Password is Required."
                    />

                    <Button title="Submit" isLoading={isLoading} className="btn btn-primary mt-5" />
                </AvForm>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        user: state.auth.user
    };
};

export default connect(mapStateToProps)(Login);
