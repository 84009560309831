import React from "react";
import "./UserCard.scss";

const MatchCard = (props) => {
    const { data } = props;

    return (
        <div
            onClick={props.onClick ? props.onClick : null}
            className="match-card__wrapper d-flex align-items-center mx-3 mb-3"
        >
            <img src={data?.MediaPath} />
            <div className="">
                <p className="pl-2">{data?.Name}</p>
            </div>
        </div>
    );
};
export default MatchCard;
