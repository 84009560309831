import React, { useState, useEffect } from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
    AccordionItemState
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import "./Faq.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDotCircle, faCircle } from "@fortawesome/free-solid-svg-icons";
const Faq = (props) => {
    const [open, setOpen] = useState(false);
    const isopen = () => {
        setOpen(!open);
    };
    const expandHandler = ({ expanded }) => {
        if (expanded) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    };

    const data = [
        {
            id: 1,
            title: "What is my role as a Matchmaker? ",
            description: (
                <div>
                    <p className="pb-3">Your role will be: </p>
                    <p className="pl-3">
                        To ensure that the both users and the mahram have a positive and safe
                        experience using the app
                    </p>
                    <p className="pl-3">
                        To match up users using their referrals on the dashboard with suitable
                        match.
                    </p>
                    <p className="pl-3">
                        {" "}
                        Be able to view and check any previous chat between users.
                    </p>
                    <p className="pl-3">
                        To deal with any reporting issue and to issue an appropriate course of
                        action{" "}
                    </p>
                </div>
            )
        },
        {
            id: 2,
            title: "Is it important for a Matchmaker to be involved?",
            description:
                "Yes!  it is very important for yourself to be involved to avoid any inappropriate chat or keeping the chat within appropriate topic. Also, for the safeguard of any user.  "
        },
        {
            title:
                "Can I change the preferences of a user to  help with their search when needed? ",
            description:
                " Yes!  You do have the authority to do this but only in very rare situations should this happen as the user choice should be valued.  "
        },
        {
            title: "Am I allowed to comment in the chat too? ",
            description: "Yes! You will be allowed to chat with all the users and mahrams.   "
        },
        {
            title: "Will I be informed about all chats even if I missed it? ",
            description:
                "Yes!  The user can remove you from the chat however you will be able to view the current or previous chat."
        },
        {
            title: "Can I as matchmaker be removed from the chat? ",
            description:
                "Yes!  You will be notified and will have access to all the live and previous chats."
        },
        {
            title: "If I have missed any chats, can I see them another time? ",
            description: "Yes!You are able to see any missed chat that you were invited into. "
        },
        {
            title: "Will I see all types of notifications? ",
            description:
                "Yes! You will see all types of notifications e.g. to all live chats, missed or previous chats, reporting, messages from mahram or user directed to you alone and preferences up dates. "
        },
        {
            title:
                "If any inappropriate behaviour or any form of misconduct is reported, what can I do? ",
            description: (
                <div>
                    <p>You have the authority to issue a: </p>
                    <div className="pt-4">
                        <div className="d-flex align-items-center">
                            <FontAwesomeIcon className="faq-icon mr-2 ml-2" icon={faCircle} />
                            <p>Formal Written Warning </p>
                        </div>
                        <p>
                            This will be an initial warning for the user if after investigating the
                            incident you decide to take no further action after the reporting{" "}
                        </p>
                    </div>
                    <div className="pt-4">
                        <div className="d-flex align-items-center">
                            <FontAwesomeIcon className="faq-icon mr-2 ml-2" icon={faCircle} />
                            <p> Suspension </p>
                        </div>
                        <p>
                            If the user has been reported again or if an incident does require a
                            more severe punishment due to the level of misconduct after an
                            investigation then a 7 or 14 day suspension can be issued, in that they
                            will not be able access their account.{" "}
                        </p>
                    </div>
                    <div className="pt-4">
                        <div className="d-flex align-items-center">
                            <FontAwesomeIcon className="faq-icon mr-2 ml-2" icon={faCircle} />
                            <p>Removal from platform (account be deleted) </p>
                        </div>
                        <p>
                            This is the final and most severe reprimand a matchmaker can issue. This
                            would be repeated reporting of misconduct or extreme bad behaviour.{" "}
                            <br />
                            In every action a notification will be on the app and formal email will
                            be sent. The user in question does have the right to appeal against the
                            report.{" "}
                        </p>
                    </div>
                </div>
            )
        }
    ];

    return (
        <>
            <div className="faq-wrapper fancy-scroll">
                <Accordion allowZeroExpanded>
                    {data
                        ? data.map((obj, index) => (
                              <AccordionItem key={data.uuid}>
                                  <AccordionItemState>{expandHandler}</AccordionItemState>
                                  <AccordionItemHeading className={open ? "openitem" : "closeitem"}>
                                      <AccordionItemButton>
                                          <h2>{obj.title}</h2>
                                      </AccordionItemButton>
                                  </AccordionItemHeading>
                                  <AccordionItemPanel>
                                      <p>{obj.description}</p>
                                  </AccordionItemPanel>
                              </AccordionItem>
                          ))
                        : null}
                </Accordion>
            </div>
        </>
    );
};
export default Faq;
