import React from "react";

const Button = (props) => {
    const { onClick = undefined, className = "", title, isLoading = false } = props;
    return (
        <button disabled={isLoading} onClick={onClick} className={`${className} btn btn-primary`}>
            {isLoading ? (
                <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                ></span>
            ) : (
                title
            )}
        </button>
    );
};
export default Button;
