import { injectBaseConstantMethods } from "./BaseConstants";

export const ID_VERIFICATION_TYPES = {
    DRIVING_LICENSE: 1,
    PASSPORT: 2,
    ID_CARD: 3
};

const displayTextKeys = {
    [ID_VERIFICATION_TYPES.DRIVING_LICENSE]: "Driving License",
    [ID_VERIFICATION_TYPES.PASSPORT]: "Passport",
    [ID_VERIFICATION_TYPES.ID_CARD]: "Id Card"
};

const labelClasses = {
    [ID_VERIFICATION_TYPES.DRIVING_LICENSE]: "",
    [ID_VERIFICATION_TYPES.PASSPORT]: "",
    [ID_VERIFICATION_TYPES.ID_CARD]: ""
};

export default injectBaseConstantMethods(ID_VERIFICATION_TYPES, displayTextKeys, labelClasses);
