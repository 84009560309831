import { NotificationStatus } from "../../constants";

import {
    NOTIFICATIONS_LISTING_REQUEST,
    NOTIFICATIONS_LISTING_SUCCESS,
    NOTIFICATIONS_LISTING_FAILURE,
    NOTIFICATIONS_STATUS_UPDATE
} from "../actions";

const initialState = {
    fetching: false,
    totalNotifications: 0,
    unreadCount: 0,
    list: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case NOTIFICATIONS_LISTING_REQUEST:
            return {
                ...state,
                fetching: true,
                list: (() => {
                    if (!action.Page || action.Page === 1) {
                        return [];
                    }
                    return state.list;
                })()
            };

        case NOTIFICATIONS_LISTING_SUCCESS:
            return {
                ...state,
                fetching: false,
                totalNotifications: action.totalNotifications,
                list: [...state.list, ...action.payload],
                unreadCount: action.unreadCount
            };

        case NOTIFICATIONS_STATUS_UPDATE:
            return {
                ...state,
                fetching: false,
                unreadCount: action.unreadCount,
                list: state.list.map((item) => {
                    if (action.readIds.includes(item.Id)) {
                        return {
                            ...item,
                            ReadStatus: NotificationStatus.READ
                        };
                    } else return item;
                })
            };

        case NOTIFICATIONS_LISTING_FAILURE:
            return {
                ...state,
                list: [],
                totalNotifications: 0,
                fetching: false
            };

        default:
            return state;
    }
};
