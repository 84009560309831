import React, { Component } from "react";
import "./AboutUs.scss";
import Love from "../../assets/images/Love.png";
import Universe1 from "../../assets/images/Universe1.png";
import Plane11 from "../../assets/images/Plane11.png";
const AboutUs = (props) => {
    return (
        <>
            <div className="about__wrapper">
                <div className=" d-flex flex-column flex-md-row align-items-center justify-content-between about__welcome">
                    <div>
                        <h2>Welcome to Halal Connections!</h2>
                        <p className="pt-4">
                            We are a mobile phone application designed to bring Muslims together who
                            intend to find a suitable partner to marry. We pair users together based
                            on their preferences by our own experienced Matchmaker. Halal
                            Connections™ has been designed for people who are serious about marriage
                            and want to meet their future partner within a private online platform
                            on which users can interact with confidence, trust and a peace of mind.{" "}
                        </p>
                    </div>
                    <img src={Love} alt="logo" />
                </div>
                <div className=" d-flex flex-column-reverse flex-md-row align-items-center justify-content-between pt-5 mt-0 mt-md-5 about__vission">
                    <img src={Universe1} alt="logo" />
                    <div>
                        <h2>Our Vision</h2>
                        <p className="pt-4">
                            Halal Connections™ is committed to providing real connections for real
                            people in a digital, private space. There is a gap in the Muslim
                            community for a service of this kind and we want to make it accessible
                            for people to build a meaningful relationship with the help of Halal
                            Connections™ App.{" "}
                        </p>
                    </div>
                </div>
                <div className=" d-flex  flex-column flex-md-row align-items-center justify-content-between pt-5 mt-0 mt-md-5 about__welcome">
                    <div>
                        <h2>Executive Summary </h2>
                        <p className="pt-4">
                            Halal Connections™ is planned to become the premier match-making
                            platform within the Muslim community in Scotland and for the Muslim all
                            over the world. We are not a dating like many others. We will enable
                            adults to build connections in a safe environment in the presence of a
                            guardian/mahram (optional) as they embark on this exciting journey in
                            their life. This is not only intended for people who are getting married
                            for the first time but also for those who may be separated, divorced or
                            widowed and intend to marry again. The app has been developed for both
                            iOS and Android phones and a dashboard for the matchmaker is available
                            on desktop computers.
                        </p>
                    </div>
                    <img className="mt-5" src={Plane11} alt="logo" />
                </div>
            </div>
        </>
    );
};
export default AboutUs;
