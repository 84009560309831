import { fetchUtil } from "../../utils/fetchUtil";
import { appendQueryParams } from "../../utils/urlUtil";
import { getChatById } from "./chatActions";
import Config from "../../Config";

export const REPORT_LISTING_REQUEST = "REPORT_LISTING_REQUEST";
export const REPORT_LISTING_SUCCESS = "REPORT_LISTING_SUCCESS";
export const REPORT_LISTING_FAILURE = "REPORT_LISTING_FAILURE";

export const getReportListing = (params = {}) => (dispatch, getState) => {
    let token = getState().auth.user.Token;

    dispatch({
        type: REPORT_LISTING_REQUEST,
        Page: params.Page
    });

    return fetchUtil({
        url: appendQueryParams("/report/list", { Limit: Config.LIMIT, ...params }),
        token
    })
        .then((res) => {
            dispatch({
                type: REPORT_LISTING_SUCCESS,
                payload: res.Data.Reports,
                totalReports: res.Data.Count
            });

            return Promise.resolve(res);
        })
        .catch((err) => {
            dispatch({
                type: REPORT_LISTING_FAILURE
            });
            return Promise.reject(err);
        });
};

export const getReportDetails = (reportId) => (dispatch, getState) => {
    const token = getState().auth.user.Token;

    return fetchUtil({
        url: `/report/details/${reportId}`,
        token
    })
        .then(async (res) => {
            await dispatch(getChatById(res.Data.ChatId));

            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const handleAppealAction = (payload) => (dispatch, getState) => {
    const token = getState().auth.user.Token;

    return fetchUtil({
        url: `/report/appeal/action`,
        method: "POST",
        token,
        body: JSON.stringify(payload)
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const handleReportAction = (payload) => (dispatch, getState) => {
    const token = getState().auth.user.Token;

    return fetchUtil({
        url: `/report/action`,
        method: "POST",
        token,
        body: JSON.stringify(payload)
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const createReport = (payload) => (dispatch, getState) => {
    const token = getState().auth.user.Token;

    return fetchUtil({
        url: `/report/create`,
        method: "POST",
        token,
        body: JSON.stringify(payload)
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};
