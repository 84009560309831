import { injectBaseConstantMethods } from "./BaseConstants";

export const ReportTypes = {
    APPEAL_REQUESTED: 1,
    APPEAL_RESOLVED: 2,
    PENDING_REVIEW: 3,
    RESOLVED: 4,
    UNDER_APPEAL: 5,
    UNDER_REVIEW: 6
};

const displayTextKeys = {
    [ReportTypes.APPEAL_REQUESTED]: "Appeal Requested",
    [ReportTypes.APPEAL_RESOLVED]: "Appeal Resolved",
    [ReportTypes.UNDER_REVIEW]: "Under Review",
    [ReportTypes.RESOLVED]: "Resolved",
    [ReportTypes.UNDER_APPEAL]: "Appeal Under Review",
    [ReportTypes.PENDING_REVIEW]: "Pending Review"
};

const labelClasses = {
    [ReportTypes.APPEAL_REQUESTED]: "yellow",
    [ReportTypes.APPEAL_RESOLVED]: "lightgreen",
    [ReportTypes.UNDER_REVIEW]: "blue",
    [ReportTypes.RESOLVED]: "lightgreen",
    [ReportTypes.UNDER_APPEAL]: "yellow",
    [ReportTypes.UNDER_REVIEW]: "yellow"
};

export default injectBaseConstantMethods(ReportTypes, displayTextKeys, labelClasses);
