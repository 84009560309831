import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import "./InputField.scss";

const InputField = (props) => {
    const {
        onEnter,
        value,
        icon,
        placeholder,
        name,
        error,
        required,
        disabled = false,
        classes
    } = props;

    const [isReveal, setIsReveal] = useState(false);

    return (
        <>
            <div
                className={
                    (props.required && props.error && !value
                        ? "input-feild box-shadow border_red"
                        : "input-feild box-shadow  ") + classes
                }
            >
                <div className="icon-box">
                    <FontAwesomeIcon icon={icon} className="icon" />
                </div>
                <div className="input-text">
                    <input
                        placeholder={placeholder}
                        name={name}
                        type={isReveal ? "text" : props.type}
                        onChange={onEnter}
                        value={value}
                        disabled={disabled}
                    />
                </div>
                {/* {props.secure ? (
                    <button
                        className="eyeprop border-0 bg-transparent"
                        onClick={() => {
                            setIsReveal(!isReveal);
                        }}
                    >
                        <FontAwesomeIcon icon={faEye} className="icon" />
                    </button>
                ) : null} */}
            </div>
            {required && error && !value ? (
                <p className="text-danger mb-3">This field is required *</p>
            ) : null}
        </>
    );
};

export default InputField;
