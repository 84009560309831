import React, { Component } from "react";
import "./SearchBox.scss";

const SearchBox = (props) => {
    const { classes = "" } = props;

    return (
        <div className={"search-box " + classes}>
            <input type="search" placeholder="search" {...props} />
        </div>
    );
};
export default SearchBox;
