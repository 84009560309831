import moment from "moment";

export const dateTimeFormat = {
    appDateTimeFormat: "DD-MM-YYYY, HH:mm",
    appDateFormat: "DD-MM-YYYY",
    apiDateTimeFormat: "YYYY-MM-DD HH:mm:ss",
    apiDateFormat: "YYYY-MM-DD",
    gmtDateTimeFormat: "YYYY-MM-DD HH:mm",
    creationDateFormat: "DD MMMM, YYYY",
    apiTimeFormat: "HH:mm:ss",
    appTimeFormat: "HH:mm",
    commissionDateQuery: "YYYY-MM"
};

export const convertDateTime = ({ to, date, dateOnly = false, customFormat = null }) => {
    const format = customFormat
        ? customFormat
        : dateOnly
        ? dateTimeFormat.apiDateFormat
        : dateTimeFormat.apiDateTimeFormat;

    return moment(date).format(format);
};
