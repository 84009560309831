
import React, { Component } from 'react';
const VerifyIcon = (props) => {
    return (
        <svg className="icon-change" width="30" height="25" viewBox="0 0 30 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.8627 24.0007V21.4451C21.8627 20.0895 21.3132 18.7895 20.3351 17.831C19.357 16.8725 18.0303 16.334 16.6471 16.334H6.21569C4.8324 16.334 3.50577 16.8725 2.52764 17.831C1.54951 18.7895 1 20.0895 1 21.4451V24.0007" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M11.4305 11.2222C14.3111 11.2222 16.6462 8.9339 16.6462 6.11111C16.6462 3.28832 14.3111 1 11.4305 1C8.54999 1 6.21484 3.28832 6.21484 6.11111C6.21484 8.9339 8.54999 11.2222 11.4305 11.2222Z" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M25.9313 17.3623L28.9992 20.3687" stroke="#333333" stroke-width="2" stroke-linecap="round" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M22.4126 17.9471C24.6867 17.9471 26.5302 16.1406 26.5302 13.912C26.5302 11.6835 24.6867 9.87695 22.4126 9.87695C20.1385 9.87695 18.2949 11.6835 18.2949 13.912C18.2949 16.1406 20.1385 17.9471 22.4126 17.9471Z" stroke="#333333" stroke-width="2" />
        </svg>

    );
}
export default VerifyIcon