import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "react-responsive-modal";

import "react-responsive-modal/styles.css";
import "./LogoutModal.scss";
import { logout } from "../../store/actions";
import { Button } from "../../components/Button";

const LogoutModal = (props) => {
    const { open = false, onModalClose } = props;
    const [openLogout, setOpenLogout] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    const openModal = () => setOpenLogout(true);
    const closeModal = () => setOpenLogout(false);

    const handleConfirm = () => {
        setIsLoading(true);
        dispatch(logout())
            .then((res) => {})
            .catch((err) => {
                setIsLoading(false);
            });
    };

    return (
        <>
            {/* <button onClick={openModal}>logout</button> */}
            {/* <div className="nav-block">
                <FontAwesomeIcon className="logout-icon sidebar-config-logout" onClick={openModal} icon={faSignOutAlt} />

            </div> */}
            <Modal
                classNames={{ modal: "logoutModal" }}
                open={open}
                onClose={onModalClose}
                showCloseIcon={false}
                center
            >
                <div className="d-flex flex-column align-items-center justify-content-center logout__modal h-100">
                    <h4 className="pb-3">Are you sure you want to log out?</h4>
                    <div className="d-flex justify-content-around w-75">
                        <button className="logout-btn" onClick={handleConfirm} disabled={isLoading}>
                            {isLoading ? (
                                <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                            ) : (
                                "Yes"
                            )}
                        </button>
                        <button onClick={onModalClose} className="logout-btn">
                            No
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
};
export default LogoutModal;
