import { injectBaseConstantMethods } from "./BaseConstants";

const LivingSituation = {
    WITH_FAMILY: 1,
    ALONE: 2,
    SHARED_ACCOMODATION: 3
};

const displayTextKeys = {
    [LivingSituation.WITH_FAMILY]: "With Family",
    [LivingSituation.ALONE]: "Alone",
    [LivingSituation.SHARED_ACCOMODATION]: "Shared Accomodation"
};

const labelClass = {
    [LivingSituation.WITH_FAMILY]: "",
    [LivingSituation.ALONE]: "",
    [LivingSituation.SHARED_ACCOMODATION]: ""
};

export default injectBaseConstantMethods(LivingSituation, displayTextKeys, labelClass);
