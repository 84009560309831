import styled, { css } from "styled-components";
// import themeScss from '../../../../assets/scss/theme.scss';
// import CustomCard from "../../../Card/CustomCard";

export const TooltipBody = styled.div`
    min-width: 18.125;
    max-width: 40.5rem !important;
    position: relative;
    padding: 0.375rem;
    background: #ffffffeb;
    backdrop-filter: blur(1.347rem);
    border-radius: 15px;
    z-index: 4000;
    ${(props) =>
        props.maxWidth &&
        css`
            max-width: ${props.maxWidth};
            width: ${props.maxWidth};
        `}
    ${(props) =>
        props.cardBlur &&
        css`
            backdrop-filter: blur(${props.cardBlur});
        `}

@media(max-width:980px) {
        max-width: 80%;
    }

    @media (max-width: 480px) {
        max-width: 100%;
        width: 36rem;
    }
`;

export const TooltipContent = styled.div`
    color: #000;
    padding: 2.25rem;
    ${(props) =>
        props.cardPadding &&
        css`
            padding: ${props.cardPadding};
        `}
    overflow-y: auto;
    max-height: 637px;

    &::-webkit-scrollbar {
        width: 0.5rem;
        background-color: red;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: red;
    }
    &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: red;
    }
`;

export const CardLeftImg = styled.img`
    ${(props) =>
        props.imgWidth &&
        css`
            max-width: ${props.imgWidth};
        `}
`;
export const H4 = styled.h4`
    line-height: 2rem;
    @media (max-width);
`;

export const CardTypeOne = styled.div``;
export const CardTypeOneFlexItem = styled.div`
    ${(props) =>
        props.width &&
        css`
            width: ${props.width};
        `}
    ${(props) =>
        props.alignSelf &&
        css`
            align-self: ${props.alignSelf};
        `}
`;
export const Icon = styled.i`
    color: yellow;
    font-size: 1.5rem;
`;
export const CardRightImg = styled.img`
    margin-top: -4px;
    ${(props) =>
        props.imgWidth &&
        css`
            max-width: ${props.imgWidth};
            width: ${props.imgWidth};
        `}
`;

export const RightIconContainer = styled.div`
    padding-right: 0.938rem;
`;
export const CardFooter = styled.div`
    ${(props) =>
        props.center &&
        css`
            text-align: center;
        `}
`;

export const StepTwoCardContainer = styled.div`
    width: 54.625rem;

    @media (max-width: 1024px) {
        width: 65% !important;
    }
`;

export const CardContainer = styled.div`
    background-color: #fff0;
`;

export const NotificationBage = styled.span`
    top: -2px;
    right: 0px;
`;
