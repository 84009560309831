
import React, { Component } from 'react';
const ChatGroup = (props) => {
    return (
        <svg onClick={props.onClick} className={props.className} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.4173 22.75V20.5833C18.4173 19.4341 17.9608 18.3319 17.1481 17.5192C16.3355 16.7065 15.2333 16.25 14.084 16.25H5.41732C4.26805 16.25 3.16585 16.7065 2.35319 17.5192C1.54053 18.3319 1.08398 19.4341 1.08398 20.5833V22.75" stroke="#6246EA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M9.74935 11.9167C12.1426 11.9167 14.0827 9.97657 14.0827 7.58333C14.0827 5.1901 12.1426 3.25 9.74935 3.25C7.35611 3.25 5.41602 5.1901 5.41602 7.58333C5.41602 9.97657 7.35611 11.9167 9.74935 11.9167Z" stroke="#6246EA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M24.916 22.7498V20.5831C24.9153 19.623 24.5957 18.6903 24.0075 17.9315C23.4193 17.1726 22.5957 16.6307 21.666 16.3906" stroke="#6246EA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M17.334 3.39062C18.2661 3.62928 19.0923 4.17138 19.6823 4.93146C20.2722 5.69154 20.5925 6.62636 20.5925 7.58854C20.5925 8.55073 20.2722 9.48555 19.6823 10.2456C19.0923 11.0057 18.2661 11.5478 17.334 11.7865" stroke="#6246EA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>


    );
}
export default ChatGroup