import React from "react";
import CustomButton from "../../../Button/Button";
import {
    CardTypeOne,
    CardTypeOneFlexItem,
    H4,
    CardFooter,
    CardLeftImg
} from "./TooltipDesignMain.styles";

const TooltipDesignSecond = ({
    title,
    titleClass = "",
    description,
    isImg,
    primaryProps,
    isSkipButton = false,
    isNextButton = true,
    isCloseButton = false,
    imgWidth,
    onCancel = () => {},
    skipProps
}) => {
    return (
        <CardTypeOne>
            <div className="d-flex justify-content-between">
                <CardTypeOneFlexItem className="w-75">
                    <h2 className={`${titleClass}`}>{title}</h2>
                    <H4 className="description">{description}</H4>
                </CardTypeOneFlexItem>
                <img className="main-wrapper-img" src={isImg} />
            </div>
            <CardFooter center>
                {isNextButton && (
                    <CustomButton
                        className="start-btn "
                        {...primaryProps}
                        title=" Start"
                    ></CustomButton>
                )}
                {isCloseButton && (
                    <CustomButton
                        className="Done-btn mt-3"
                        {...skipProps}
                        onClick={(e) => {
                            skipProps.onClick(e);
                        }}
                        title="Done"
                    ></CustomButton>
                )}
                {isSkipButton && (
                    <p
                        className="skip-tour color-secondary body-text-18 mb-0"
                        {...skipProps}
                        onClick={onCancel}
                    >
                        <u>Skip the tour</u>
                    </p>
                )}
            </CardFooter>
        </CardTypeOne>
    );
};

export default TooltipDesignSecond;
