import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Spinner } from "reactstrap";

import "./ReportListing.scss";

import PageTitle from "../../components/PageTitle/PageTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { getReportListing } from "../../store/actions";
import ReportItem from "./ReportItem";

const SORT_COLUMNS = {
    Date: "Date",
    Status: "Status",
    Cause: "CauseId",
    ReportedBy: "FullName",
    ReportedUser: "ReportedUserFullName"
};

const ReportListing = (props) => {
    const { getReportListing, data, fetching, totalReports } = props;

    const [page, setPage] = useState(1);
    // const [currentColumn, setCurrentColumn] = useState(SORT_COLUMNS.Date);
    const [currentColumn, setCurrentColumn] = useState({
        name: SORT_COLUMNS.Date,
        direction: false
    });

    useEffect(() => {
        getReportListing({
            Page: page,
            Column: currentColumn.name,
            Direction: currentColumn.direction ? "ASC" : "DESC"
        })
            .then((res) => {
                handleLoadMore(res.Data.Reports, res.Data.Count);
            })
            .catch((err) => {});

        //es-lint-disable-next-line react-hooks/exhaustive-deps
    }, [page, currentColumn, getReportListing]);

    useEffect(() => {
        function scrollListner(e) {
            const bottom = window.innerHeight + window.scrollY >= document.body.offsetHeight - 50;
            if (bottom && data.length < totalReports && !fetching) {
                setPage(page + 1);
            }
        }
        window.addEventListener("scroll", scrollListner);
        return () => {
            window.removeEventListener("scroll", scrollListner);
        };
    }, [data]);

    useEffect(() => {
        function handleWindowResize() {
            handleLoadMore();
        }
        window.addEventListener("resize", handleWindowResize);
        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, [data]);

    const handleLoadMore = (newData = [], total = totalReports) => {
        const isScrollable = document.body.scrollHeight > window.innerHeight;

        if (!isScrollable && !fetching) {
            if ([...data, ...newData].length < total) {
                setPage(page + 1);
            }
        }
    };

    const handleSortOrder = (column) => {
        // if (column === currentColumn) {
        //     setCurrentColumn(SORT_COLUMNS.Date);
        // } else {
        //     setCurrentColumn(column);
        // }

        setCurrentColumn({ name: column.name, direction: column.direction ? false : true });

        setPage(1);
    };

    return (
        <>
            <PageTitle title="Reports" />
            <div className="report-table fancy-scroll">
                <div className="reported__info d-flex align-items-center">
                    <div
                        className="reported__date d-flex justify-content-center align-items-center pointer"
                        onClick={() =>
                            handleSortOrder({
                                name: SORT_COLUMNS.Date,
                                direction: currentColumn.direction
                            })
                        }
                    >
                        <p>Date</p>
                        <FontAwesomeIcon
                            className="ml-3 reported-icon"
                            icon={
                                currentColumn.name === SORT_COLUMNS.Date && currentColumn.direction
                                    ? faAngleUp
                                    : faAngleDown
                            }
                        />
                    </div>
                    <div
                        className="reported__user d-flex justify-content-center align-items-center pointer"
                        // onClick={() => handleSortOrder(SORT_COLUMNS.ReportedUser)}
                        onClick={() =>
                            handleSortOrder({
                                name: SORT_COLUMNS.ReportedUser,
                                direction: currentColumn.direction
                            })
                        }
                    >
                        <p>Reported User</p>
                        <FontAwesomeIcon
                            className="ml-3 reported-icon"
                            icon={
                                currentColumn.name === SORT_COLUMNS.ReportedUser &&
                                currentColumn.direction
                                    ? faAngleUp
                                    : faAngleDown
                            }
                        />
                    </div>
                    <div
                        className="reported__cause d-flex justify-content-center align-items-center pointer"
                        // onClick={() => handleSortOrder(SORT_COLUMNS.Cause)}
                        onClick={() =>
                            handleSortOrder({
                                name: SORT_COLUMNS.Cause,
                                direction: currentColumn.direction
                            })
                        }
                    >
                        <p>Cause</p>
                        <FontAwesomeIcon
                            className="ml-3 reported-icon"
                            icon={
                                currentColumn.name === SORT_COLUMNS.Cause && currentColumn.direction
                                    ? faAngleUp
                                    : faAngleDown
                            }
                        />
                    </div>
                    <div
                        className="reported__by d-flex justify-content-center align-items-center pointer"
                        // onClick={() => handleSortOrder(SORT_COLUMNS.ReportedBy)}
                        onClick={() =>
                            handleSortOrder({
                                name: SORT_COLUMNS.ReportedBy,
                                direction: currentColumn.direction
                            })
                        }
                    >
                        <p>Reported by</p>
                        <FontAwesomeIcon
                            className="ml-3 reported-icon"
                            icon={
                                currentColumn.name === SORT_COLUMNS.ReportedBy &&
                                currentColumn.direction
                                    ? faAngleUp
                                    : faAngleDown
                            }
                        />
                    </div>
                    <div
                        className="reported__status d-flex justify-content-center align-items-center pointer"
                        // onClick={() => handleSortOrder(SORT_COLUMNS.Status)}
                        onClick={() =>
                            handleSortOrder({
                                name: SORT_COLUMNS.Status,
                                direction: currentColumn.direction
                            })
                        }
                    >
                        <p>Status </p>
                        <FontAwesomeIcon
                            className="ml-3 reported-icon"
                            icon={
                                currentColumn.name === SORT_COLUMNS.Status &&
                                currentColumn.direction
                                    ? faAngleUp
                                    : faAngleDown
                            }
                        />
                    </div>
                </div>
                <div className="report__listing">
                    {Boolean(data.length)
                        ? data.map((obj, index) => <ReportItem data={obj} key={index} />)
                        : !data.length &&
                          !fetching && (
                              <div className="d-flex flex-row justify-content-center no-pending-center-wrap">
                                  <p className="mr-2">No Data Available</p>{" "}
                              </div>
                          )}
                </div>

                {fetching && (
                    <div className="d-flex flex-row justify-content-center loader-padding">
                        <p className="mr-2">Loading</p>

                        <Spinner size={"40"} color={"primary"} />
                    </div>
                )}
            </div>
        </>
    );
};

const mapDispatchToProps = {
    getReportListing
};

const mapStateToProps = (state) => {
    const { reports } = state;

    return {
        data: reports.list,
        fetching: reports.fetching,
        totalReports: reports.totalReports
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportListing);
